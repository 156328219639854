/* eslint-disable react/jsx-props-no-spreading */
import Icon from 'yoda-core-components/lib/components/Icon';
import PillSelected from 'yoda-core-components/lib/assets/svg/PillSelected.svg';
import React from 'react';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { func, oneOf, shape, string } from 'prop-types';
import * as styles from './Pill.css';

const cx = classNames.bind(styles);

const Pill = ({
    onChange,
    onClick,
    pillConfig: { color = '', defaultChecked = false, id = '', imageUrl = '', value = '' } = {},
    pillLabel,
    pillType,
}) => {
    const buttonPill = pillType === 'button';
    const colorPill = pillType === 'color';
    const imagePill = pillType === 'image';

    const onChangeHandler = (event) => {
        const { target: { value: targetValue = '' } = {} } = event;
        const eventData = {
            value: targetValue,
            event,
        };

        onChange(eventData);
    };
    const buttonPillLabelStyles = buttonPill && pillLabel && 'my-0';
    const buttonNotPillLabelStyles = !(buttonPill && pillLabel) && 'my-4';
    const colorPillCheckedLabelStyles =
        !colorPill && defaultChecked && dt(['border-2', 'border-black', 'border-solid']);
    const notColorPillLabelStyles =
        !colorPill && dt(['bg-gray-100', 'mx-0', 'px-4', 'py-2', 'rounded-full']);
    const colorPillLabelStyles =
        colorPill && dt(['absolute', 'bg-transparent', 'm-0', 'pb-0', 'pt-6', 'px-0']);
    const colorPillStylesChecked =
        colorPill && defaultChecked && dt(['border-2', 'border-black', 'border-solid']);
    const colorPillStyles = colorPill && dt(['mb-3', 'rounded-full']);
    const pillClassesConfig = {
        containerClasses: `{$dt([
            'mx-2',
            'relative',
            'self-center',
        ])} ${cx(buttonPillLabelStyles, buttonNotPillLabelStyles)}`,
        labelClasses: `${dt(['capitalize', 'cursor-pointer', 'whitespace-nowrap'])} ${cx(
            'pill-label',
            colorPillCheckedLabelStyles,
            notColorPillLabelStyles,
            colorPillLabelStyles
        )}`,
        pillClasses: cx({ 'color-pill': colorPill }, colorPillStyles, colorPillStylesChecked),
        selectedIconClasses: `${dt(['absolute', 'cursor-pointer', 'pointer-events-none'])} ${cx(
            'selected-icon-wrapper'
        )}`,
    };

    return pillLabel ? (
        <div className={pillClassesConfig.containerClasses}>
            <div className={pillClassesConfig.pillClasses} style={{ backgroundColor: color }}>
                {buttonPill ? (
                    <button
                        className={pillClassesConfig.labelClasses}
                        data-automation-id="pill-label-more"
                        onClick={onClick}
                        type="button"
                    >
                        {pillLabel}
                    </button>
                ) : (
                    <>
                        <label
                            className={pillClassesConfig.labelClasses}
                            data-automation-id="pill-label"
                            htmlFor={id}
                        >
                            <input
                                {...{
                                    'data-automation-id': 'pill-input',
                                    className: dt(['hidden']),
                                    defaultChecked,
                                    id,
                                    onChange: onChangeHandler,
                                    type: 'checkbox',
                                    value,
                                }}
                            />
                            {imagePill && (
                                <img
                                    alt={pillLabel}
                                    className={`${dt(['mr-2', 'w-5', 'h-5'])} ${cx('facet-image')}`}
                                    src={`/mobile/images/${imageUrl}`}
                                />
                            )}
                            {pillLabel}
                        </label>
                        {defaultChecked && (
                            <Icon className={pillClassesConfig.selectedIconClasses}>
                                <PillSelected />
                            </Icon>
                        )}
                    </>
                )}
            </div>
        </div>
    ) : null;
};

Pill.propTypes = {
    pillConfig: shape({
        onChange: func,
        onClick: func,
        pillLabel: string,
        pillType: string,
    }),
    onChange: func,
    onClick: func,
    pillLabel: string,
    pillType: oneOf(['button', 'checkbox', 'checkboxImage', 'color', 'image']),
};

Pill.defaultProps = {
    onChange: null,
    onClick: null,
    pillConfig: {},
    pillLabel: '',
    pillType: '',
};

export default Pill;
