import { object, string, symbol } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import ScrollRenderer from 'yoda-site-components/lib/components/Renderers/ScrollRenderer';
import {
    selectDeviceName,
    selectPreferences,
} from 'yoda-site-components/lib/selectors/ContextSelector';
import { defaultPreferences } from './Constant';

const ScrollRendererWrapper = ({ children, offset, height, loader }) => {
    const deviceName = useSelector(selectDeviceName);
    const preferences = useSelector(selectPreferences);
    const configOffset = get(preferences, 'renderingStrategy.gallery.offset', offset);
    const configHeight = get(preferences, 'renderingStrategy.gallery.height', height);
    return React.Children.map(children, (child) =>
        child ? (
            <ScrollRenderer
                offset={configOffset[deviceName]}
                height={configHeight[deviceName]}
                loader={loader}
            >
                {React.cloneElement(child)}
            </ScrollRenderer>
        ) : null
    );
};

ScrollRendererWrapper.defaultProps = {
    offset: defaultPreferences.renderingStrategy.gallery.offset,
    height: defaultPreferences.renderingStrategy.gallery.height,
    children: null,
    deviceName: 'mobile',
    loader: null,
};

ScrollRendererWrapper.propTypes = {
    offset: object,
    height: object,
    children: object,
    deviceName: string,
    loader: symbol,
};

export default React.memo(ScrollRendererWrapper);
