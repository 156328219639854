import { SET_FAILOVER_CANONICAL_INFO } from '../actionTypes/GalleryActionTypes';

const failOverLayout = (
    state = {
        isUpdateCanonicalUrl: false,
    },
    { payload = {}, type = '' }
) => {
    switch (type) {
        case SET_FAILOVER_CANONICAL_INFO:
            return { ...state, ...payload };
        default:
            return state;
    }
};

failOverLayout.reducerName = 'failOverLayoutReducer';

export default failOverLayout;
