import { createSelector } from 'reselect';
import get from 'lodash/get';
import {
    selectMessagesTexts,
    selectFeatureFlags,
} from 'yoda-site-components/lib/selectors/ContextSelector';
import { selectH1Tag } from './fromState';
import { getIrisFallBackStatus } from './IrisFailure';
import { transformBreadCrumbsInfoIris } from '../utils/irisDataProcesserFunction';
import { getBreadCrumbFromIrisData } from './BreadCrumbs';

export const defaultBreadCrumbs = [];

export const selectBrPreviewMessage = createSelector([selectMessagesTexts], (messages) =>
    get(messages, 'bloomReachPreviewMessage', '')
);

export const selectEnableBrPreviewGallerySkeleton = createSelector(
    [selectFeatureFlags],
    (featureFlags) => get(featureFlags, 'enableBrPreviewGallerySkeleton', false)
);

export const getH1TagBrPreview = createSelector(
    [getIrisFallBackStatus, selectH1Tag],
    (irisFallBackStatus, irisH1Tag) => {
        if (irisFallBackStatus) {
            return '';
        }
        return irisH1Tag;
    }
);

export const getBreadcrumbsTagBrPreview = createSelector(
    [getIrisFallBackStatus, getBreadCrumbFromIrisData],
    (irisFallBackStatus, irisBreadCrumbInfo = defaultBreadCrumbs) => {
        if (irisFallBackStatus) {
            return defaultBreadCrumbs;
        }
        return transformBreadCrumbsInfoIris(irisBreadCrumbInfo);
    }
);
