import React, { Component } from 'react';
import { array, bool, func, object, oneOfType, shape, string } from 'prop-types';
import className from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import AnalyticsAction from 'yoda-site-components/lib/actions/AnalyticsAction';
import Button from 'yoda-core-components/lib/components/Button/Button';
import Icon from 'yoda-core-components/lib/components/Icon';
import YdtSearch from 'yoda-core-components/lib/assets/svg/searchIconNew.svg';
import Close from 'yoda-core-components/lib/assets/svg/closeIconNew.svg';
import Input from 'yoda-core-components/lib/components/Input/Input';
import { validateZipCode } from 'yoda-core-components/lib/helpers/Utils/Utils';
import MessageBox from 'yoda-core-components/lib/components/MessageBox';
import isEqual from 'lodash/isEqual';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import {
    bopisGalleryChangeZip,
    fetchGalleryStores,
    setBOPISErrorMessage,
} from '../../actions/BOPISActions';
import { triggerStoreSearchEvent } from '../../actions/AnalyticsActions';
import { BOPISErrors } from '../../common/constants';
import * as styles from './FindAStore.css';

const cx = className.bind(styles);
/* istanbul ignore next */
class FindAStore extends Component {
    static propTypes = {
        actions: oneOfType([func, object]).isRequired,
        locationService: oneOfType([object, string]).isRequired,
        preferences: oneOfType([array, object]).isRequired,
        selectedStoresFilter: oneOfType([array, object]).isRequired,
        sortedStoresList: oneOfType([array, object]).isRequired,
        storeParameters: shape({ zipCode: string }).isRequired,
        inSlider: bool,
        error: string,
    };

    static defaultProps = {
        inSlider: false,
        error: string,
    };

    constructor(props) {
        super(props);
        this.state = {
            fetchBOPISStores: false,
            userInput: false,
            zipCode: '',
        };
    }

    componentDidMount() {
        const {
            storeParameters: { zipCode: defaultZipCode },
        } = this.props;

        if (defaultZipCode) this.setState({ zipCode: defaultZipCode });
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(prevProps) {
        const { selectedStoresFilter, sortedStoresList } = this.props;

        if (
            !isEqual(selectedStoresFilter, prevProps.selectedStoresFilter) ||
            !isEqual(sortedStoresList, prevProps.sortedStoresList)
        ) {
            this.setState({ fetchBOPISStores: true });
        }
    }

    onEnterhandler = (value) => {
        // KeyboardEvent.keyCode is in the process of deprecation
        /* istanbul ignore else */
        if ((value.code && value.code === 'Enter') || (value.keyCode && value.keyCode === 13)) {
            this.getStoresByZipCode();
        }
    };

    onZipCodeChange = (value) => {
        this.setState({ zipCode: value, userInput: true });
    };

    onClearSearch = () => {
        this.setState({ zipCode: '', userInput: true });
        const { actions } = this.props;
        actions.setBOPISErrorMessage({
            errorMessage: '',
            params: {
                zipCode: '',
            },
        });
    };

    getStoresByZipCode = () => {
        const {
            actions,
            preferences,
            selectedStoresFilter,
            sortedStoresList,
            storeParameters: { zipCode: defaultZipCode },
        } = this.props;
        let { zipCode } = this.state;
        const { fetchBOPISStores } = this.state;
        zipCode = zipCode || defaultZipCode;
        const validZipCode = validateZipCode(zipCode);

        actions.setBOPISErrorMessage({
            errorMessage: validZipCode ? '' : BOPISErrors.invalidZipCodeMes,
            params: {
                zipCode,
            },
        });

        if (zipCode) {
            if (validZipCode) {
                const bopisPreferences = _get(preferences, 'bopis', {});
                const bopisDefaultRadius = _get(bopisPreferences, 'radius', 75);
                const noResultsMsg = _get(bopisPreferences, 'noResults', BOPISErrors.noResults);
                const hasPrevResults =
                    sortedStoresList.some(
                        (store) => parseInt(store.distance, 10) > bopisDefaultRadius
                    ) && !selectedStoresFilter.length;
                if (zipCode !== defaultZipCode || fetchBOPISStores) {
                    actions.bopisGalleryChangeZip({});
                    actions.fetchGalleryStores({
                        zipCode,
                        miles: bopisDefaultRadius,
                        formError: noResultsMsg,
                        useActualLocation: true,
                    });
                } else if (hasPrevResults) {
                    actions.setBOPISErrorMessage({
                        errorMessage: noResultsMsg,
                        params: {
                            zipCode,
                        },
                    });
                }
                actions.triggerStoreSearchEvent(zipCode);
            } else {
                actions.triggerFormError([
                    {
                        errorDescription: BOPISErrors.invalidZipCodeMes,
                    },
                ]);
            }
        }
    };

    renderDefaultSearch = () => {
        const {
            inSlider,
            locationService: {
                inputZipCode: locationServiceInputZipCode,
                zipcode: locationServiceZipcode,
            },
            error,
            errorMessage,
            hideCreateErrorBox,
            isValidZipcode,
            isMobileView,
        } = this.props;
        const { userInput, zipCode } = this.state;
        const findStoreInputStyles = dt([
            'bg-transparent',
            'border',
            'border-solid',
            'font-open-sans',
            'h-10',
            'px-4',
            'py-0',
            'rounded-full',
            'text-black',
            'w-full',
            'pr-9',
            'smOnly:py-2',
        ]);

        const findStoreNotDisplay = dt(['border-gray-15']);
        const findAStoreInput = cx(findStoreInputStyles, findStoreNotDisplay, error);

        const findStoreLabelClasses = `${dt([
            'capitalize',
            'font-family-open-sans-bold',
            'pb-2',
            !inSlider && 'pb-0',
            'relative',
            'text-center',
            'text-black',
            'text-medium',
            'font-semibold',
            'text-lg',
            'sm:z-[2]',
            !isValidZipcode && 'smOnly:mb-4',
        ])} ${cx('find-store-label')}`;

        const searchBoxClasses = dt([
            'bg-transparent',
            'flex',
            'font-family-open-sans',
            'pb-1',
            'relative',
            'text-center',
            'text-small',
            inSlider && 'smOnly:px-6',
        ]);

        const searchStoreBtnClasses = `${dt([
            'absolute',
            'font-family-open-sans',
            'top-1',
            'right-4',
            '!bg-transparent',
            'shadow-none',
            'sm:!min-w-[auto]',
            inSlider ? 'smOnly:right-[38px]' : 'smOnly:right-4',
        ])} ${cx('search-store-button')}`;

        const getInputDefaultValue = () => {
            if (userInput) return zipCode;

            return locationServiceInputZipCode || locationServiceZipcode;
        };

        return (
            <>
                {!inSlider && <div className={findStoreLabelClasses}>Find Stores</div>}
                {isMobileView && isValidZipcode && (
                    <MessageBox
                        className={dt([
                            'font-sans',
                            'font-normal',
                            'my-4',
                            'normal-case',
                            'smOnly:p-3',
                            inSlider && 'smOnly:mx-6',
                            inSlider ? 'smOnly:px-3' : 'smOnly:px-2',
                        ])}
                        message={errorMessage}
                        messageType="error"
                        showMessage={!!errorMessage}
                        showCloseButton
                        closeHandler={hideCreateErrorBox}
                    />
                )}
                <div className={searchBoxClasses}>
                    <Input
                        ariaRequired
                        automationId="find-store-zip"
                        dataCsMask
                        defaultValue={getInputDefaultValue()}
                        name="find store"
                        onChange={this.onZipCodeChange}
                        onKeyUp={this.onEnterhandler}
                        placeholder="Zip or City, State"
                        required
                        theme={findAStoreInput}
                        type="text"
                        value={this.state.zipCode}
                    />
                    {error ? (
                        <Button
                            automationId="search-by-zipcode"
                            className={searchStoreBtnClasses}
                            onClick={this.onClearSearch}
                            size="Sm"
                        >
                            <Icon className={dt(['text-black'])} height="24" width="24">
                                <Close />
                            </Icon>
                        </Button>
                    ) : (
                        <Button
                            automationId="search-by-zipcode"
                            className={searchStoreBtnClasses}
                            onClick={this.getStoresByZipCode}
                            size="Sm"
                        >
                            <Icon className={dt(['text-black'])} height="24" width="24">
                                <YdtSearch />
                            </Icon>
                        </Button>
                    )}
                </div>
            </>
        );
    };

    render() {
        return this.renderDefaultSearch();
    }
}

const mapStateToProps = ({
    bopisInfo,
    locationServiceReducer: locationService = {},
    preferences = {},
}) => ({
    selectedStoresFilter: bopisInfo.selectedStores,
    sortedStoresList: bopisInfo.sortedStoresList,
    storeParameters: bopisInfo.params,
    locationService,
    preferences,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            bopisGalleryChangeZip,
            fetchGalleryStores,
            setBOPISErrorMessage,
            triggerFormError: AnalyticsAction.triggerFormError,
            triggerStoreSearchEvent,
        },
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindAStore);
