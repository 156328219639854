/* eslint-disable react/jsx-props-no-spreading */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { bool, func, number, objectOf, shape, string } from 'prop-types';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { isTabletWidth } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import ConnectedFilterPanel from '../FilterPanel/FilterPanel';
import Footer from './components/Footer';
import Header from './components/Header';
import { toggleSlider } from '../../actions/SliderActions';
import * as styles from './GallerySlider.css';

const cx = classNames.bind(styles);

export const GallerySlider = () => {
    const {
        isGalleryPage = false,
        countOfSearchResults = 0,
        lastGalleryAction = { url: '' },
        pagination: { pagination: { totalProductsCount = 0 } = {} } = {},
        sliderStatus = false,
    } = useSelector((store) => store);

    const [displaySlider, setDisplaySlider] = useState(sliderStatus);
    const dispatch = useDispatch();

    /* istanbul ignore next */
    useEffect(() => {
        const enableScrollLock = (enabled = false) => {
            document.body.classList[enabled ? 'add' : 'remove'](
                'h-full',
                'm-0',
                'overflow-hidden',
                'p-0',
                'relative',
                'w-full'
            );
        };

        enableScrollLock(displaySlider);
        setDisplaySlider(sliderStatus);
    }, [displaySlider, sliderStatus]);

    const NoResults = () => (
        <div className={dt(['pt-6', 'text-center'])}>
            <div className={dt(['font-open-sans-bold', 'mb-4', 'text-large'])}>Uh oh!</div>
            <div className={dt(['text-gray-70', 'text-medium'])}>
                Your filters produced 0 results.
            </div>
        </div>
    );

    const hasFilterContent = !!(countOfSearchResults > 0) || isGalleryPage;
    const notDisplayHide = !displaySlider && dt(['hidden']);
    /* istanbul ignore next */
    const gallerySliderConfig = {
        filterWrapper: {
            className: `${dt([
                'bg-white',
                'fixed',
                'h-full',
                'overflow-y-auto',
                'pt-12',
                'text-small',
                'w-full',
            ])} ${cx('filter-wrapper', {
                active: displaySlider,
                inactive: !displaySlider,
                tablet: isTabletWidth(),
            })}`,
            'data-automation-id': 'slider-data',
        },
        footer: {
            className: cx('footer', {
                active: displaySlider,
                inactive: !displaySlider,
                tablet: isTabletWidth(),
            }),
            hasFilterContent,
            toggleSlider: () => dispatch(toggleSlider()),
            totalProductsCount,
            url: lastGalleryAction.url,
        },
        header: {
            className: cx('header', {
                active: displaySlider,
                inactive: !displaySlider,
                tablet: isTabletWidth(),
            }),
            hasFilterContent,
            toggleSlider: () => dispatch(toggleSlider()),
            url: lastGalleryAction.url,
        },
        overlay: {
            className: `${dt(['bg-overlay-60', 'fixed', 'h-full', 'w-full'])} ${cx('overlay')}`,
            onClick: () => dispatch(toggleSlider()),
            tabIndex: -1,
        },
        wrapper: {
            className: `${dt(['fixed'])} ${cx('wrapper', notDisplayHide)}`,
            'data-component-name': 'GallerySlider',
        },
    };

    return (__SERVER__ && sliderStatus === true) || !__SERVER__ ? (
        <div {...gallerySliderConfig.wrapper}>
            <button {...gallerySliderConfig.overlay} type="button" />
            <Header {...gallerySliderConfig.header} />
            <div {...gallerySliderConfig.filterWrapper}>
                {hasFilterContent ? <ConnectedFilterPanel inSlider /> : <NoResults />}
            </div>
            <Footer {...gallerySliderConfig.footer} />
        </div>
    ) : null;
};

GallerySlider.propTypes = {
    actions: objectOf(func),
    countOfSearchResults: number,
    isGalleryPage: bool,
    lastAction: shape({ url: string }),
    sliderStatus: bool,
    totalProductsCount: number,
}.isRequired;

export default GallerySlider;
