import {
    GET_GALLERY_DETAILS_BY_ID_SUCCESS,
    GET_STORE_NULL_RESULT_PAGE,
} from '../actionTypes/GalleryActionTypes';

export default function filterReducer(state = {}, action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS: {
            const newState = {};
            if (action.galleryDetails && action.galleryDetails.nullResultsMessage) {
                newState.nullResultsMessage = action.galleryDetails.nullResultsMessage;
            }

            if (action.galleryDetails && action.galleryDetails.storeNullResultsMessage) {
                newState.storeNullResultsMessage = action.galleryDetails.storeNullResultsMessage;
            }

            if (action.galleryDetails && action.galleryDetails?.brPrvIgnore) {
                newState.brPreviewMessage = action?.brPreviewMessage || '';
            }
            return newState;
        }

        case GET_STORE_NULL_RESULT_PAGE: {
            const newState = {};
            newState.storeNullResultsMessage = action.noStores;
            return newState;
        }
        default:
            return state;
    }
}
