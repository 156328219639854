import { SPONSORED_DISPLAY_PAGE } from '../actionTypes/GalleryActionTypes';

export default function sponsoredDisplayPage(state = false, action) {
    // NOSONAR
    switch (action.type) {
        case SPONSORED_DISPLAY_PAGE:
            return action?.sponsoredDisplayPage;
        default:
            return state;
    }
}
