import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import { GET_FILTERS_SUCCESS } from '../actionTypes/GalleryActionTypes';
import { facetIds } from '../configurations/config';
import { getURLParameterBykey } from '../utils';

// istanbul ignore next
const composeFilters = (action) => {
    const allFilters = _get(action.galleryDetails, 'facets.facetList', []);
    const requestUrl = _get(action.galleryDetails, 'requestUrl');
    const isbloomReachAudience = _get(action, 'isbloomReachAudience');
    const enableBloomReachV3PLP = _get(action, 'isBloomReachV3Page');
    const displayFreeSameDayPickup = _get(action.galleryDetails, 'displayFreeSameDayPickup');
    const newState = {
        filterList: [],
        storeAvailabilityFilter: [],
    };
    if (allFilters.length) {
        const storeAvailabilityIndex = _findIndex(
            allFilters,
            (filterDetails) =>
                filterDetails.facetId === facetIds.storeAvailability ||
                filterDetails?.facetInternalName === 'store_availability'
        );

        // StoreAvailability filter should always be the first one in filter list.
        if ((isbloomReachAudience || enableBloomReachV3PLP) && displayFreeSameDayPickup) {
            // remove store avaiability from filter panel
            const storeAvailability =
                storeAvailabilityIndex >= 0 ? allFilters.splice(storeAvailabilityIndex, 1) : [];

            const facetValueList =
                storeAvailability[0]?.facetValueList.filter(
                    (facet) => facet?.name?.toLowerCase() === 'same day pickup'
                ) || {};
            const storeAvailabilityFilterValue = [
                {
                    facetName: 'Store Availability',
                    facetId: 833,
                    facetValueList: [
                        {
                            ...facetValueList[0],
                            name: 'same day pickup',
                            facetValId: 832,
                            facetNValue: 'n4',
                            filterValueName: 'bopisFilter',
                            facetValue: 'same+day+pickup',
                            solrFacetName: 'store_availability',
                        },
                    ],
                    facetType: 'checkbox',
                    facetInternalName: 'Store_Availability',
                },
            ];
            newState.storeAvailabilityFilter = storeAvailabilityFilterValue;
        } else if (storeAvailabilityIndex >= 0) {
            const storeAvailability = allFilters.splice(storeAvailabilityIndex, 1);
            const { facetValueList } = storeAvailability[0];
            storeAvailability[0].facetValueList =
                facetValueList &&
                facetValueList.map((facetValue) => {
                    if (
                        facetValue.facetValId === facetIds.sameDayPickup &&
                        !getURLParameterBykey('storeIds', requestUrl) &&
                        facetValue.selected
                    ) {
                        /* eslint no-param-reassign: ["error", { "props": false }] */
                        facetValue.selected = false;
                        facetValue.selectDefaultStore = true;
                    }
                    return facetValue;
                });
            newState.storeAvailabilityFilter = storeAvailability;
        }
        newState.filterList = allFilters;
    }
    return newState;
};

// istanbul ignore next
const defaultState = {
    filterList: [],
    storeAvailabilityFilter: [],
};
export default function filterReducer(state = defaultState, action) {
    // NOSONAR
    switch (action.type) {
        case GET_FILTERS_SUCCESS: {
            return composeFilters(action);
        }

        default:
            return state;
    }
}
