/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from 'yoda-app-shell/lib/components/NotFound/NotFound';
import Gallery from './containers/Pages/Iris/IrisWrapper';

/* istanbul ignore next */
export default function App() {
    // eslint-disable-next-line no-console
    console.info('🌠️ Render App');
    return (
        <>
            <Switch>
                <Route path="/g/*" render={(props) => <Gallery {...props} pageType="Gallery" />} />
                <Route
                    path="*/cat.jump"
                    render={(props) => <Gallery {...props} pageType="Gallery" catJump />}
                />
                <Route path="/s*" render={(props) => <Gallery {...props} pageType="Search" />} />

                {/* when none of the above match, <NoMatch> will be rendered */}
                <Route component={NotFound} />
            </Switch>
        </>
    );
}
