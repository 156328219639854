import { RERENDER_COMPONENT } from 'yoda-site-components/lib/actionTypes/GalleryRerenderActionTypes';

export default function rerenderReducer(state = false, { type, payload }) {
    switch (type) {
        case RERENDER_COMPONENT:
            /* eslint-disable-next-line no-param-reassign */
            state = payload;
            return state;
        default:
            return state;
    }
}

rerenderReducer.reducerName = 'rerender';
