/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _isEmpty from 'lodash/isEmpty';
import { selectFeatureFlags } from 'yoda-site-components/lib/selectors/ContextSelector';
import { constructSEODefaultCanonicalUrl } from 'yoda-site-components/lib/helpers/Utils/seoHelper';
import pageIndex from 'yoda-site-components/lib/helpers/Utils/PageIndexHelper';
import ConnectedSeoSocial from '../SeoSocial/SeoSocial';
import { isAbsoluteUrl } from '../../utils';
import { getSeoTag } from '../../selectors/SeoTags';
import { getRequest } from '../../selectors/ContextSelector';

export class SeoMetaData extends PureComponent {
    static defaultProps = {
        excludeQueryParams: [],
        featureFlags: {},
        includeQueryParams: [],
        location: {},
        pagination: {},
        preferences: {},
        renderAMPTags: false,
        request: {},
        seoTags: {},
    };

    static propTypes = {
        excludeQueryParams: PropTypes.array,
        featureFlags: PropTypes.oneOfType([PropTypes.object]),
        includeQueryParams: PropTypes.array,
        location: PropTypes.oneOfType([PropTypes.object]),
        pagination: PropTypes.object,
        preferences: PropTypes.oneOfType([PropTypes.object]),
        renderAMPTags: PropTypes.bool,
        request: PropTypes.oneOfType([PropTypes.object]),
        seoTags: PropTypes.oneOfType([PropTypes.object]),
    };

    renderSeoTags() {
        if (!_isEmpty(this.props.seoTags)) {
            const seoRobotsTag = {
                name: 'robots',
            };
            const {
                renderAMPTags,
                preferences: {
                    seoConfig: { excludeQueryParams = [], includeQueryParams = [] } = {},
                },
                featureFlags: { enableCanonicalFallback = true, enableIrisManualIndexing = true },
                location,
            } = this.props;
            const configProtocol = this.props.featureFlags.HTTPS_FOR_SEO_ENABLED
                ? 'https://'
                : 'http://';
            /* istanbul ignore next */
            const hostName = 'www.jcpenney.com';
            const metaTags = this.props.seoTags;
            let canonicalUrl = metaTags.canonicalURL;
            if (enableCanonicalFallback && _isEmpty(canonicalUrl) && !_isEmpty(location)) {
                const urlParams = constructSEODefaultCanonicalUrl(
                    location?.search,
                    excludeQueryParams,
                    includeQueryParams,
                    false
                );
                canonicalUrl =
                    !_isEmpty(urlParams) && `https://${hostName}${location?.pathname}${urlParams}`;
            }
            let ampUrl;
            let prevUrl = renderAMPTags ? metaTags.ampPrevUrl : metaTags.prevUrl;
            let nextUrl = renderAMPTags ? metaTags.ampNextUrl : metaTags.nextUrl;

            if (!isAbsoluteUrl(metaTags.canonicalURL)) {
                canonicalUrl =
                    metaTags.canonicalURL &&
                    `${configProtocol}www.jcpenney.com${metaTags.canonicalURL}`;
            }

            if (metaTags.ampURL) {
                ampUrl = isAbsoluteUrl(metaTags.ampURL)
                    ? metaTags.ampURL
                    : `https://www.jcpenney.com${metaTags.ampURL}`;
            }

            if (prevUrl) {
                prevUrl = isAbsoluteUrl(prevUrl)
                    ? prevUrl
                    : `${configProtocol}${hostName}${prevUrl}`;
            }

            if (nextUrl) {
                nextUrl = isAbsoluteUrl(nextUrl)
                    ? nextUrl
                    : `${configProtocol}${hostName}${nextUrl}`;
            }

            if (enableIrisManualIndexing) {
                seoRobotsTag.content = pageIndex(metaTags);
            } else {
                // Todo: metaTags.noIndexNoFollowString and metaTags.noIndexNoFollowRequired should be removed once we move to IRIS.
                // Currently we are providing support for both IRIS and SOLR content API.
                const noIndexNoFollowString = metaTags.noIndexNoFollowString || metaTags.robots;
                const noIndexNoFollowRequired =
                    metaTags.noIndexNoFollowRequired || metaTags.noIndexNoFollow;
                if (noIndexNoFollowString) {
                    seoRobotsTag.content = noIndexNoFollowString.toLowerCase();
                } else if (noIndexNoFollowRequired) {
                    seoRobotsTag.content = 'noindex, nofollow';
                }
            }

            return (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{metaTags.seoMetaTitle}</title>
                    {metaTags.seoMetaDescription ? (
                        <meta name="description" content={metaTags.seoMetaDescription} />
                    ) : null}
                    {metaTags.seoMetaKeyword ? (
                        <meta name="keywords" content={metaTags.seoMetaKeyword} />
                    ) : null}
                    {/* Link MetaData */}
                    <link rel="canonical" href={canonicalUrl} />
                    {ampUrl && <link rel="amphtml" href={ampUrl} />}
                    {seoRobotsTag.content && <meta {...seoRobotsTag} />}
                    {prevUrl && <link rel="prev" href={prevUrl} />}
                    {nextUrl && <link rel="next" href={nextUrl} />}
                </Helmet>
            );
        }
        return null;
    }

    render() {
        return (
            <>
                {/* SEO Tags */}
                {this.renderSeoTags()}
                <ConnectedSeoSocial />
            </>
        );
    }
}

const mapStateToProps = (store) => ({
    seoTags: getSeoTag(store),
    request: getRequest(store),
    featureFlags: selectFeatureFlags(store),
});

export default withRouter(connect(mapStateToProps)(SeoMetaData));
