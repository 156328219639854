import {
    GET_GALLERY_DETAILS_TIMEOUT_ERROR,
    CLOSE_GALLERY_DETAILS_TIMEOUT_ERROR,
} from '../actionTypes/GalleryActionTypes';

export default function noResultsReducer(state = false, action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_DETAILS_TIMEOUT_ERROR: {
            return true;
        }
        case CLOSE_GALLERY_DETAILS_TIMEOUT_ERROR: {
            return false;
        }
        default:
            return state;
    }
}
