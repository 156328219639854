import { GET_GALLERY_DETAILS_BY_ID_SUCCESS } from '../actionTypes/GalleryActionTypes';

export default function filterReducer(state = {}, action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS:
            return action.galleryDetails &&
                action.galleryDetails.facets &&
                action.galleryDetails.facets.showClearAll
                ? {
                      isVisible: action.galleryDetails.facets.showClearAll,
                      url: action.galleryDetails.facets.clearAll,
                  }
                : { isVisible: false };

        default:
            return state;
    }
}
