import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { func, number, object, objectOf, oneOfType, string } from 'prop-types';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { setFilterSelection } from 'yoda-site-components/lib/actions/SelectionFilterAction';
import Pill from '../Pill/Pill';
import { filterCategoryPlaceHolder } from '../../configurations/config';
import { getRandomId, updateUrl, updateQueryStringParameter } from '../../utils';
import { promotedFilterChange } from '../../actions/AnalyticsActions';
import { showSlider } from '../../actions/SliderActions';

export const PillWrapper = ({ actions, config, noOfFilterValuesPerPromoted, placeHolder }) => {
    const { filterPanel = '', promotedFilters = '' } = filterCategoryPlaceHolder;
    const isFilterPanel = placeHolder === filterPanel;
    const isPromotedFilter = placeHolder === promotedFilters;
    let isPillFilterSelected = !isFilterPanel && !isPromotedFilter;

    /* istanbul ignore next */
    const facetChanged = (event, facetConfig) => {
        isPillFilterSelected = isPillFilterSelected && !facetConfig.selected;
        actions.promotedFilterChange({
            value: isPillFilterSelected ? `${config.facetName.toLowerCase()} - pill` : '',
        });
        actions.setFilterSelection({ isBottomToTopFilterSelection: true });
        updateUrl(event.value, null, null, {
            action: facetConfig.selected ? 'remove' : 'add',
            name: config.facetName,
            value: facetConfig.name,
            taxonomyKey: config.facetInternalName ? config.facetInternalName.toLowerCase() : '',
        });
    };

    return (
        <div className={dt(['mb-2'])}>
            <h3 className={dt(['font-sans', 'font-bold', 'mb-2', 'text-sm'])}>
                {config.facetName}
            </h3>
            <div className={dt(['flex', 'overflow-auto', 'scrolling-touch'])}>
                {config.facetValueList.slice(0, noOfFilterValuesPerPromoted).map((facetConfig) => (
                    <Pill
                        key={getRandomId()}
                        onChange={
                            /* istanbul ignore next */ (event) => facetChanged(event, facetConfig)
                        }
                        pillConfig={{
                            color: config.facetType === 'color' ? facetConfig.name : null,
                            defaultChecked: facetConfig.selected,
                            id: getRandomId(),
                            imageUrl: config.facetType === 'image' ? facetConfig.imageUrl : null,
                            value: updateQueryStringParameter(
                                facetConfig.apiUrl,
                                'activeFacetId',
                                config.facetId
                            ),
                        }}
                        pillLabel={facetConfig.name}
                        pillType={config.facetType}
                    />
                ))}
                {config.facetValueList.length > noOfFilterValuesPerPromoted ? (
                    <Pill
                        key={getRandomId()}
                        onClick={actions.showSlider}
                        pillLabel="more"
                        pillType="button"
                    />
                ) : null}
            </div>
        </div>
    );
};

PillWrapper.propTypes = {
    actions: objectOf(func).isRequired,
    config: oneOfType([func, object]).isRequired,
    noOfFilterValuesPerPromoted: number.isRequired,
    placeHolder: string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ promotedFilterChange, showSlider, setFilterSelection }, dispatch),
});

const mapStateToProps = ({
    context: { preferences: { noOfFilterValuesPerPromoted = 6 } = {} } = {},
}) => ({ noOfFilterValuesPerPromoted });

export default connect(mapStateToProps, mapDispatchToProps)(PillWrapper);
