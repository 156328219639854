import universal from 'react-universal-component';
import universalImport from 'babel-plugin-universal-import/universalImport';
import path from 'path';
import { timeout } from 'yoda-site-components/lib/lazy/lazyConstants';

export default universal(
    universalImport({
        chunkName: () => 'IrisSeoLazy',
        path: () => path.join(__dirname, './Seo'),
        resolve: () => require.resolveWeak('./Seo'),
        load: () =>
            Promise.all([import(/* webpackChunkName: "IrisSeoLazy" */ './Seo')]).then(
                (proms) => proms[0]
            ),
    }),
    {
        loading: () => null,
        error: () => null,
        timeout,
    }
);
