import { SHOW_LOADER_GALLERY, HIDE_LOADER_GALLERY } from '../actionTypes/LoaderActionTypes';

export default function loaderReducer(state = false, action) {
    // NOSONAR
    switch (action.type) {
        case SHOW_LOADER_GALLERY:
            return true;
        case HIDE_LOADER_GALLERY:
            return false;
        default:
            return state;
    }
}
