/* istanbul ignore file */
import extend from 'extend';
import analytics from 'yoda-core-components/lib/helpers/Analytics/Analytics';
import { triggerAddSaveItems } from 'yoda-core-components/lib/helpers/Utils/Utils';
import { ADD_SAVEDITEMS } from 'yoda-site-components/lib/actionTypes/SavedItemActionTypes';
import AnalyticsActionTypes from 'yoda-site-components/lib/actionTypes/AnalyticsActionTypes';
import { getFitFinderDigitalData } from 'yoda-site-components/lib/analytics/InteractiveVizNavHelper';
import { logwebvitals } from 'yoda-site-components/lib/middlewares/WebvitalsMiddleware';
import AnalyticsActionFilter from '../analytics/AnalyticsActionFilter';
import {
    BOPIS_DEFAULT_STORE_CHANGE,
    DYNAMIC_GALLERIES_ITEM_CLICK,
    DYNAMIC_GALLERIES_LOAD_SUCCESS,
    DYNAMIC_GALLERIES_MODULE_CLICK,
    DYNAMIC_VISNAV_CLICK,
    GET_GALLERY_DETAILS_BY_ID_ERROR,
    ON_PAGE_READY,
    PRODUCT_FILTERS_APPLIED,
    SEARCH_RESULT_CLICK,
    // eslint-disable-next-line import/named
    TRIGGER_STORE_SEARCH_EVENT,
} from '../actionTypes/AnalyticsActionTypes';
import { UPDATE_VIEW_TYPE } from '../actionTypes/ProductListActionTypes';
import { BOPIS_GALLERY_CHANGE_ZIP } from '../actionTypes/BOPISActionTypes';
import {
    feedPageLoad,
    getLastFilter,
    getURLParameterBykey,
    isFilterAnalytics,
    isPageLoad,
} from '../utils';

const analyticsActionFilter = new AnalyticsActionFilter();
/**
 * This function will used only for redux middleware only to track tagging based on action request
 */
const AnalyticsMiddleware = (store) => (next) => (action) => {
    // eslint-disable-line
    if (!__SERVER__) {
        let digitalData = null;
        let analyticsEventName = null;
        let searchrelevantAnalyticsEventName = null;

        /**
         * component can trigger an action only for tagging
         * action should contain analytics object as mandatory
         * @param analytics.type - {string}  - tagging key
         * @param analytics.JCPDL - {Object}  - required additional details for tagging event
         */
        try {
            switch (action.type) {
                case DYNAMIC_GALLERIES_ITEM_CLICK: {
                    digitalData = analyticsActionFilter.getDynamicGalleriesItemClick(
                        action.payload
                    );
                    analyticsEventName = 'dynamicGalleryDetail';
                    break;
                }

                case DYNAMIC_GALLERIES_LOAD_SUCCESS: {
                    digitalData = analyticsActionFilter.getDynamicGalleriesLoadSuccess(
                        action.payload
                    );
                    analyticsEventName = 'digitalDataReady';
                    break;
                }

                case DYNAMIC_GALLERIES_MODULE_CLICK: {
                    digitalData = analyticsActionFilter.getDynamicGalleriesModuleClick(
                        action.payload
                    );
                    analyticsEventName = 'dynamicGallery';
                    break;
                }

                case ON_PAGE_READY: {
                    const fitFinderDigitalData = getFitFinderDigitalData(store);
                    digitalData = analyticsActionFilter.getGalleryPageDL(store);
                    digitalData = extend(true, digitalData, fitFinderDigitalData || {});
                    digitalData = analyticsActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';
                    if (
                        getURLParameterBykey('searchType').match(/predictive/i) &&
                        getURLParameterBykey('brand')
                    ) {
                        searchrelevantAnalyticsEventName = 'event364';
                    }
                    // triggering webvitals on page load success
                    logwebvitals(store, digitalData);
                    break;
                }

                case GET_GALLERY_DETAILS_BY_ID_ERROR: {
                    digitalData = analyticsActionFilter.getGalleryPageDL(store);
                    digitalData = analyticsActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';
                    break;
                }
                case PRODUCT_FILTERS_APPLIED: {
                    let lastFilter = getLastFilter();
                    const state = store.getState();
                    const promotedFilter = state.promotedFilterSelected;
                    if (promotedFilter.value === 'interactivevisnav') {
                        lastFilter = {
                            action: 'add',
                            name: 'interactivevisnav',
                            value: promotedFilter.heading,
                        };
                    } else if (promotedFilter.value === 'suggestedfilter') {
                        lastFilter = {
                            action: 'add',
                            name: 'suggestedfilter',
                            value: promotedFilter.heading,
                        };
                    }
                    const selectedFilters = state.selectedFilters.length;
                    const { selectedStores } = state.bopisInfo;
                    if ((selectedFilters || lastFilter) && isFilterAnalytics()) {
                        digitalData = analyticsActionFilter.getProductFilters(
                            store,
                            lastFilter,
                            promotedFilter,
                            selectedStores,
                            isPageLoad(),
                            selectedFilters
                        );
                        analyticsEventName = 'productFiltersApplied';
                        feedPageLoad();
                    }
                    break;
                }

                case ADD_SAVEDITEMS: {
                    const triggerAnalytics = triggerAddSaveItems(store.getState());
                    if (triggerAnalytics) {
                        digitalData = analyticsActionFilter.saveProductData(action.payload);
                        analyticsEventName = 'saveProduct';
                    }
                    break;
                }

                case SEARCH_RESULT_CLICK: {
                    digitalData = action.payload;
                    analyticsEventName = 'searchResultClick';
                    break;
                }

                case DYNAMIC_VISNAV_CLICK: {
                    digitalData = action.payload;
                    analyticsEventName = 'dynamicVisNav';
                    break;
                }

                case BOPIS_GALLERY_CHANGE_ZIP: {
                    digitalData = action.payload;
                    analyticsEventName = 'bopisChangeZip';
                    break;
                }

                case UPDATE_VIEW_TYPE: {
                    if (action.payload.userAction) {
                        digitalData = analyticsActionFilter.getSwitchPageOrientationData(
                            action.payload.viewType
                        );
                        analyticsEventName = 'switchPageOrientation';
                    }
                    break;
                }

                case AnalyticsActionTypes.GALLERY_VIDEO_PLAYER_ANALYTICS: {
                    digitalData = analyticsActionFilter.getGalleryVideoData(action.payload);
                    analyticsEventName = 'videoLoaded';
                    break;
                }

                case TRIGGER_STORE_SEARCH_EVENT: {
                    const { searchText } = action;
                    digitalData = {
                        store: {
                            searchText,
                        },
                    };
                    analyticsEventName = 'storeSearch';
                    break;
                }

                case BOPIS_DEFAULT_STORE_CHANGE: {
                    digitalData = {};
                    analyticsEventName = 'bopisDefaultStoreChange';
                    break;
                }

                default:
                    break;
            }

            if (analyticsEventName || digitalData) {
                analytics.track(analyticsEventName, digitalData);
            }
            if (searchrelevantAnalyticsEventName) {
                analytics.track(searchrelevantAnalyticsEventName, digitalData);
            }
        } catch (error) {
            // @TODO - this should be added into LOGGER
        }
    }

    return next(action);
};

export default AnalyticsMiddleware;
