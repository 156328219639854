import { createSelector } from 'reselect';
import get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { selectHostName, selectIsSecure } from './fromState';

const defaults = {
    context: {},
};
export const selectContext = (state) => get(state, 'context', defaults.context);

export const getRequest = createSelector(
    [selectContext, selectHostName, selectIsSecure],
    (context, hostName, isSecure) => {
        if (!_isEmpty(context)) {
            return { hostName, isSecure };
        }
        return {};
    }
);
