/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { func, object, oneOfType, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import CheckBox from 'yoda-core-components/lib/components/CheckBox/CheckBox';
import { getLocaleString } from 'yoda-site-components/lib/components/Pricing/PricingHelper';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './Facet.css';
import { getRandomId, updateUrl, updateQueryStringParameter } from '../../utils';
import { getMultiSelectedFacetsList, encodedUrl } from '../../helpers/FacetHelper';
import FacetLabel from './components/FacetLabel/FacetLabel';
import { filterCategoryPlaceHolder } from '../../configurations/config';
import { promotedFilterChange } from '../../actions/AnalyticsActions';
import updateMultiSelectFacetInfo from '../../actions/MultiSelectFilterActions';
import { setFailoverCanonicalInfo } from '../../actions/GalleryAction';
import { showLoader } from '../../actions/LoaderActions';

const cx = classNames.bind(styles);
const Facet = ({
    config,
    facetConfig,
    labelClass,
    labelContainerClass,
    placeHolder,
    wrapperClass,
    updateBtnStatus,
}) => {
    const {
        context: {
            featureFlags: {
                enableMultiSelectFilters = false,
                enableBrandNameCasing = false,
                enableCanonicalFallback = true,
            } = {},
        } = {},
        multiSelectFilter: { multiSelectedFacetsList = {} } = {},
        filters: { filterList = [] } = {},
    } = useSelector((store) => store);

    const dispatch = useDispatch();
    const [multiFacetUrl, setMultiFacetUrl] = useState(null);
    /* istanbul ignore next */
    const getCheckBoxConfig = (
        { facetId },
        { apiUrl, selected, facetValue = '', solrFacetName = '' }
    ) => ({
        checked:
            enableMultiSelectFilters &&
            multiSelectedFacetsList &&
            Object.keys(multiSelectedFacetsList).length > 0
                ? multiSelectedFacetsList[solrFacetName] &&
                  multiSelectedFacetsList[solrFacetName].includes(facetValue)
                : selected,
        id: getRandomId(),
        value:
            enableMultiSelectFilters && multiFacetUrl
                ? updateQueryStringParameter(multiFacetUrl, 'activeFacetId', facetId)
                : updateQueryStringParameter(apiUrl, 'activeFacetId', facetId),
    });

    const getFacetContent = () => {
        const { solrFacetName = '' } = facetConfig;
        const facetProps = {
            facetLabel: {
                config,
                facetConfig,
                theme: cx('checkbox-label', {
                    'facet-label-first-letter':
                        !enableBrandNameCasing ||
                        (solrFacetName !== 'brand' && enableBrandNameCasing),
                }),
                updateBtnStatus,
            },
            span: {
                className: dt(['flex-grow', 'text-right']),
            },
        };

        return (
            <div className={dt(['flex', 'text-black', 'sm:text-base', 'lg:text-small', 'gap-x-1'])}>
                <FacetLabel {...facetProps.facetLabel} />
                <span {...facetProps.span}>{`(${getLocaleString(facetConfig.count)})`}</span>
            </div>
        );
    };
    /* istanbul ignore next */
    const facetChanged = ({ event: { target: { checked = false, value: url } } = {} }) => {
        const isChecked = checked;
        const isFilterPanel = placeHolder === filterCategoryPlaceHolder.filterPanel;
        const isPromotedFilterSelected = !isFilterPanel && !facetConfig.selected;
        dispatch(
            promotedFilterChange({
                value: isPromotedFilterSelected ? config.facetName.toLowerCase() : '',
            })
        );
        dispatch(showLoader());
        enableCanonicalFallback &&
            dispatch(
                setFailoverCanonicalInfo({
                    isUpdateCanonicalUrl: true,
                })
            );
        /* istanbul ignore if */
        if (enableMultiSelectFilters) {
            const selectedFacetsList = getMultiSelectedFacetsList(
                config,
                facetConfig,
                filterList,
                isChecked,
                multiSelectedFacetsList
            );
            const updatedMultiFacetUrl = encodedUrl(url, facetConfig, selectedFacetsList);

            dispatch(
                updateMultiSelectFacetInfo({
                    enableProductsLoader: true,
                    enableFacetLoader: false,
                    multiSelectedFacetsList: selectedFacetsList,
                    multiFacetUrl: updatedMultiFacetUrl,
                    scrollToTitle: false,
                })
            );

            setMultiFacetUrl(updatedMultiFacetUrl);
        } else {
            updateUrl(url, null, null, {
                name: config.facetName,
                value: facetConfig.name,
                action: facetConfig.selected ? 'remove' : 'add',
                taxonomyKey: config.facetInternalName ? config.facetInternalName.toLowerCase() : '',
            });
        }

        updateBtnStatus && updateBtnStatus(false);
    };
    const facetGrayOutStyles = facetConfig.greyOut && dt(['disabled', 'relative', 'text-gray-60']);
    const checkboxLabelConfig = {
        checkbox: {
            automationId: 'checkbox-label',
            config: getCheckBoxConfig(config, facetConfig),
            enableFastClick: true,
            label: getFacetContent(),
            labelClass: `${dt(['items-stretch', 'pl-8', 'w-full'])} ${cx(
                'facet-label',
                labelClass,
                facetGrayOutStyles
            )}`,
            labelContainerClass: `${dt(['w-full'])} ${cx(labelContainerClass)}`,
            onChange: (event) => {
                facetChanged(event);
            },
            theme: `${cx('checkbox-theme')} ${dt(['sm:block'])}`,
        },
        input: {
            className: 'facetNValue',
            type: 'hidden',
            value: facetConfig.facetNValue,
        },
        wrapper: {
            className: `${cx(wrapperClass)} ${dt([
                'mb-2',
                'smOnly:py-3',
                'smOnly:mb-0',
                'smOnly:min-h-[48px]',
            ])}`,
            key: getRandomId(),
        },
    };

    return (
        <div {...checkboxLabelConfig.wrapper}>
            <CheckBox {...checkboxLabelConfig.checkbox} />
            {!!facetConfig.facetNValue && <input {...checkboxLabelConfig.input} />}
        </div>
    );
};

Facet.defaultProps = {
    labelClass: '',
    labelContainerClass: '',
    onFacetChange: null,
    wrapperClass: '',
};

Facet.propTypes = {
    config: oneOfType([object, func]).isRequired,
    facetConfig: oneOfType([object, func]).isRequired,
    labelClass: string,
    labelContainerClass: string,
    onFacetChange: func,
    placeHolder: string.isRequired,
    wrapperClass: string,
};

export default Facet;
