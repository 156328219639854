const multiSelectFilter = (
    state = {
        enableFacetLoader: false,
        enableProductsLoader: false,
        multiSelectedFacetsList: {},
        multiFacetUrl: '',
        scrollToTitle: true,
    },
    { payload = {}, type = '' }
) => {
    switch (type) {
        case 'UPDATE_MULTI_SELECT_FACET_INFO':
            return { ...state, ...payload };
        default:
            return state;
    }
};

multiSelectFilter.reducerName = 'multiSelectFilterReducer';

export default multiSelectFilter;
