import { app } from '../config';

let logger = console; // Default goes to console

const logTag = (app && app.logTag) || 'GALLERY';

/* istanbul ignore next */
if (__SERVER__) {
    // This is to avoid app shell and other server-side libraries not required for browser
    const Logger = require('../../__runtime/Logger'); // eslint-disable-line global-require
    logger = Logger.default.getLogger();
}

/* istanbul ignore next */
export default class Application {
    static setLogger = (customLogger) => {
        logger = customLogger;
    };

    static getLogger = (tagName = '') =>
        (logger.child && logger.child({ tag: `${logTag}:${tagName}` })) || logger;
}
