import _get from 'lodash/get';
import { GET_GALLERY_DETAILS_BY_ID_SUCCESS } from '../actionTypes/GalleryActionTypes';
import { facetIds } from '../configurations/config';
import { getURLParameterBykey } from '../utils';

export default function selectedFiltersReducer(state = [], action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS: {
            // istanbul ignore next
            if (
                action.galleryDetails &&
                action.galleryDetails.facets &&
                action.galleryDetails.facets.orderedSelectedFacetList
            ) {
                const requestUrl = _get(action.galleryDetails, 'requestUrl');
                const { orderedSelectedFacetList } = action.galleryDetails.facets;
                // StoreAvailability filter should always be the first one in filter list.
                if (
                    orderedSelectedFacetList.length &&
                    orderedSelectedFacetList[0].facetId === facetIds.storeAvailability
                ) {
                    const storeAvailability = orderedSelectedFacetList[0];
                    const { facetValueList } = storeAvailability;
                    storeAvailability.facetValueList =
                        facetValueList &&
                        facetValueList.map((facetValue) => {
                            if (
                                facetValue.facetValId === facetIds.sameDayPickup &&
                                !getURLParameterBykey('storeIds', requestUrl) &&
                                facetValue.selected
                            ) {
                                /* eslint no-param-reassign: ["error", { "props": false }] */
                                facetValue.selected = false;
                                facetValue.selectDefaultStore = true;
                            }
                            return facetValue;
                        });
                }
                return orderedSelectedFacetList;
            }
            return [];
        }
        default:
            return state;
    }
}
