import universal from 'react-universal-component';
import universalImport from 'babel-plugin-universal-import/universalImport';
import path from 'path';
import { timeout } from 'yoda-site-components/lib/lazy/lazyConstants';

export default universal(
    universalImport({
        chunkName: () => 'VisualRelatedSearchLazy',
        path: () => path.join(__dirname, './VisualRelatedSearch'),
        resolve: () => require.resolveWeak('./VisualRelatedSearch'),
        load: () =>
            Promise.all([
                import(/* webpackChunkName: "VisualRelatedSearchLazy" */ './VisualRelatedSearch'),
            ]).then((proms) => proms[0]),
    }),
    {
        loading: () => null,
        error: () => null,
        timeout,
    }
);
