import _get from 'lodash/get';
import { GET_GALLERY_DETAILS_BY_ID_SUCCESS } from '../actionTypes/GalleryActionTypes';

export default function productsReducer(state = [], action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS: {
            if (_get(action.galleryDetails, 'organicZoneInfo.products')) {
                return action.galleryDetails.organicZoneInfo.products;
            }
            return [];
        }
        default:
            return state;
    }
}
