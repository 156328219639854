export default {
    status: 209,
    statusText: 'Bloomreach Preview Mock Data',
    data: {
        requestDateTime: 'Thu Mar 16 09:44:04 CDT 2023',
        channelName: 'Bloomreach-API',
        requestUrl:
            'http://services.dp-prod.jcpcloud2.net:8080/api/v1/search-service/s?productGridView=medium&searchTerm=tops&responseType=organic&dpAkamaiOverride=1&mode=1',
        contentType: 'XGNS',
        statusCode: 209,
        brPrvIgnore: true,
        numOfMarketingSlots: 0,
        facets: {
            orderedSelectedFacetList: [],
            facetList: [
                {
                    facetName: 'Apparel Type',
                    facetId: 5,
                    facetValueList: [
                        {
                            name: 'blouses',
                            selected: false,
                            count: 677,
                            apiUrl:
                                '/v1/search-service/s?searchTerm=tops&dpakamaioverride=1&item_type=blouses&productGridView=medium&mode=1',
                            value: 0,
                            facetNValue: '1z0pi6k',
                            facetValue: 'blouses',
                            solrFacetName: 'item_type',
                        },
                    ],
                    facetType: 'checkbox',
                    searchAvailable: false,
                    minimize: true,
                    facetValueCount: 156,
                    promotedFilter: true,
                    promotedRank: 2,
                    activeFacet: false,
                    facetInternalName: 'Item_Type',
                },
            ],
            regionalfacetMap: {},
            contentType: 'GuidedNavigation',
            showClearAll: false,
            noOfSelectedFacets: 0,
            galleryFacetReorderingIndicator: false,
        },
        suggestedFacets: {},
        type: 'XGNS',
        organicZoneInfo: {
            products: [
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
                {
                    name: 'Bloomreach mock product',
                },
            ],
            totalNumRecs: 9223,
            prevPageOffset: 0,
            nextPageOffset: 48,
            prevPageNum: 0,
            currentPage: 1,
            nextPageNum: 2,
            totalPages: 25,
            galleryMLRanking: false,
            brandPage: false,
            nextPage: true,
            inStockZero: false,
            prevPage: false,
        },
        enableRelatedSearch: false,
        retainMarketingZone: false,
        redirectProductPage: false,
        pageCanonicalURL: '/s?searchTerm=tops&dpakamaioverride=1',
        displayFreeSameDayPickup: true,
        visNavExist: false,
        showAutoscopeMsg: false,
        sortOptions: [
            {
                apiUrl:
                    '/v1/search-service/s?searchTerm=tops&productGridView=medium&dpakamaioverride=1&mode=1',
                name: 'best match',
                order: 1,
                selected: true,
            },
        ],
        relatedTerms: [],
    },
    headers: {},
};
