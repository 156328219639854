import _has from 'lodash/has';

/**
 *  to get path param for Iris aai calling.
 *  @param {object} location
 */

export const getIrisParamForGallery = (location) => {
    let loc = '';
    const ampString = '/amp';
    if (_has(location, 'pathname')) {
        let pathName = location.pathname;
        if (pathName.substring(0, ampString.length) === ampString) {
            pathName = pathName.replace('/amp', '');
        }
        loc = encodeURI(pathName) + (location.search || '');
    }
    return loc;
};

/**
 *  function for post proceesing after fatching irisData.
 */

export function galleryPostProcessorForIris() {
    this.getGalleryContentWithIris();
}

/**
 *  function which call iris api
 *  @param {object} loc
 *  @param {object} actions
 */

export const loadIrisGalleryContent = (loc, actions, initialLoad, featureFlags = {}) => {
    if (!featureFlags.disableIrisCall) {
        if (!__SERVER__ && initialLoad) {
            actions.setInitialIrisRequestMode();
        }
        const params = getIrisParamForGallery(loc);
        const aggregateAPI = true;
        const postProcessor = galleryPostProcessorForIris.bind({
            getGalleryContentWithIris: actions.getGalleryContentWithIris,
        });
        actions.getIrisTemplateData(
            params,
            aggregateAPI,
            postProcessor,
            undefined,
            undefined,
            undefined,
            false,
            false,
            initialLoad,
            false
        );
    }
};
