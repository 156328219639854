/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { bool, func, number, string } from 'prop-types';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'yoda-core-components/lib/components/Button';
import { getLocaleString } from 'yoda-site-components/lib/components/Pricing/PricingHelper';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import updateMultiSelectFacetInfo from '../../../actions/MultiSelectFilterActions';
import { updateUrl } from '../../../utils';
import * as styles from '../GallerySlider.css';

const cx = classNames.bind(styles);

const Footer = ({ className, hasFilterContent, toggleSlider, totalProductsCount, url }) => {
    const {
        context: { featureFlags: { enableMultiSelectFilters = false } = {} } = {},
        multiSelectFilter: { multiFacetUrl = '' } = {},
    } = useSelector((store) => store);
    const dispatch = useDispatch();
    const NoResults = () => (
        <>
            <Button
                size="Lg"
                automationId="facet-undo-filter"
                onClick={() => {
                    if (url) updateUrl(url);
                }}
            >
                Undo
            </Button>
            <div className={dt(['mb-1', 'mt-2', 'text-center', 'text-gray-70', 'text-xsmall'])}>
                0 Results
            </div>
        </>
    );
    /* istanbul ignore next */
    const footerConfig = {
        button: {
            className: dt(['sm:w-full']),
            automationId: 'facet-apply-filter',
            onClick: () => {
                if (enableMultiSelectFilters && multiFacetUrl) {
                    dispatch(
                        updateMultiSelectFacetInfo({
                            enableProductsLoader: true,
                            enableFacetLoader: false,
                            scrollToTitle: true,
                        })
                    );
                    multiFacetUrl && updateUrl(multiFacetUrl, null, null, null);
                }
                toggleSlider();
            },
        },
        wrapper: {
            className: `${dt([
                'bg-white',
                'fixed',
                'px-4',
                'py-2',
                'w-full',
                'smOnly:pt-2',
                'smOnly:pb-4',
            ])} ${cx(className)}`,
        },
    };

    return (
        <div {...footerConfig.wrapper}>
            {hasFilterContent ? (
                <Button {...footerConfig.button} size="Lg">
                    {!enableMultiSelectFilters
                        ? `See ${getLocaleString(totalProductsCount)} `
                        : `See `}
                    {`${totalProductsCount > 1 ? 'Results' : 'Result'}`}
                </Button>
            ) : (
                <NoResults />
            )}
        </div>
    );
};

Footer.defaultProps = {
    url: '',
};

Footer.propTypes = {
    className: string.isRequired,
    hasFilterContent: bool.isRequired,
    toggleSlider: func.isRequired,
    totalProductsCount: number.isRequired,
    url: string,
};

export default Footer;
