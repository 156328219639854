import { GET_GALLERY_DETAILS_BY_ID_SUCCESS } from '../actionTypes/GalleryActionTypes';
import { GET_PRODUCT_DETAILS_BY_ID_SUCCESS } from '../actionTypes/ProductCompareActionTypes';

export default function SeoTagsReducer(state = {}, action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS: {
            if (action.galleryDetails.searchTerm || action.galleryDetails.autoCorrectedMessage) {
                return {
                    ...state,
                    searchTerm: action.galleryDetails.searchTerm || '',
                    autoCorrectedMessage: action.galleryDetails.autoCorrectedMessage || '',
                };
            }
            return state;
        }
        // Consuming SEO data from content API - To support SEO on client side rendering
        /* istanbul ignore next */
        case GET_PRODUCT_DETAILS_BY_ID_SUCCESS: {
            if (action.payload.data && action.payload.data.length) {
                const firstProduct = action.payload.data[0];
                let name = '';
                for (let i = 0; i < action.payload.data.length; i += 1) {
                    const product = action.payload.data[i];
                    if (product.category && product.category.name) {
                        name = product.category.name;
                        break;
                    }
                }
                return {
                    seoMetaKeyword: firstProduct.name || '',
                    seoMetaTitle: `Compare Top ${name} at JCPenney!`,
                    seoMetaDescription: `Compare the top ${name} at JCPenney to find the best price and 
                    product for you.`,
                };
            }
            return state;
        }
        default:
            return state;
    }
}
