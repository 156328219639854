import {
    GET_GALLERY_DETAILS_BY_ID_REQUEST,
    INITIATE_PENDING_ACTIONS,
    JS_CLEAR_ALT_MESSAGE,
    SET_FAILOVER_CANONICAL_INFO,
} from '../actionTypes/GalleryActionTypes';

export const getGalleryDetailsById = (payload) => ({
    type: GET_GALLERY_DETAILS_BY_ID_REQUEST,
    payload,
});

export const clearAltMessage = () => ({
    type: JS_CLEAR_ALT_MESSAGE,
});

export const initiatePendingActions = (payload) => ({
    type: INITIATE_PENDING_ACTIONS,
    payload,
});

export const setFailoverCanonicalInfo = (payload) => ({
    type: SET_FAILOVER_CANONICAL_INFO,
    payload,
});
