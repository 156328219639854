import get from 'lodash/get';

export const selectIrisData = (state) => get(state, 'irisData');

export const selectH1Tag = (state) => get(state, 'irisData.seoMetaData.content.h1Tag');

export const selectGallery = (state) => get(state, 'commonData.gallery', false);

export const selectCountOfSearchResults = (state) => state.countOfSearchResults || 0;

export const selectStatusCheck = (state) => state.statusCheck;

export const selectAltMessage = (state) => get(state, 'altMessage', '');

export const selectSeoTag = (state) => get(state, 'seoTags');

export const selectSeoMetaDataFromIris = (state) => get(state, 'irisData.seoMetaData.content');

export const selectHostName = (state) => get(state, 'context.hostname', '');

export const selectIsSecure = (state) => get(state, 'context.isSecure', false);

export const selectIrisContentCallLoading = (state) =>
    get(state, 'irisContent.contentCallCompleted');

export const selectIrisContentCallStatus = (state) => get(state, 'irisContent.irisCallStatus');

export const selectBreadCrumDataFromSolar = (state) =>
    get(state, 'commonData.breadCrumDataFromSolar');

export const selectCategoryNameFromSolar = (state) => get(state, 'commonData.categoryName');

export const selectPricingConfig = (state) => get(state, 'context.preferences.fpac.gallery');

export const selectLastAction = (state) => get(state, 'lastGalleryAction', {});

export const selectDisablePrevNoResultsMessage = (state) =>
    get(state, 'context.featureFlags.disablePrevNoResultsMessage', false);

export const selectIrisContent = (state) => state.irisContent || {};

export const selectEnableTextTransform = (state) =>
    get(state, 'context.featureFlags.enableTextTransform', false);

export const selectHideBreadCrumbsWhenNoNavigation = (state) =>
    get(state, 'context.featureFlags.hideBreadCrumbsWhenNoNavigation', false);

export const selectIsTimeoutError = (state) => get(state, 'isTimeoutError');

export const selectIsSolarApihasError = (state) => get(state, 'commonData.isSolarApihasError');

export const iOSScrollTimeoutForBlankPage = (state) =>
    get(state, 'context.preferences.iOSScrollTimeoutForBlankPage', 100);
