import React, { Component } from 'react';
import { arrayOf, bool, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { selectFeatureFlags } from 'yoda-site-components/lib/selectors/ContextSelector';
import { isDesktopWidth } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import { getSeoTag } from '../../selectors/SeoTags';
import {
    getScene7ImageURL,
    getScene7desktopImageURL,
    isAbsoluteUrl,
    parseRequestUrl,
} from '../../utils';
import { getRequest } from '../../selectors/ContextSelector';

export class SeoSocial extends Component {
    static defaultProps = {
        featureFlags: { HTTPS_FOR_SEO_ENABLED: true },
        location: {
            pathname: '',
            search: '',
        },
        page: 'gallery',
        products: [],
        request: {
            hostname: '',
            isSecure: false,
        },
        seoTags: { canonicalURL: '' },
    };

    static propTypes = {
        featureFlags: shape({ HTTPS_FOR_SEO_ENABLED: bool }),
        location: shape({
            pathname: string,
            search: string,
        }),
        page: string,
        products: arrayOf(object),
        request: shape({
            hostname: string,
            isSecure: bool,
        }),
        seoTags: shape({
            canonicalURL: string,
        }),
    };

    constructor(props) {
        super(props);
        const { page } = this.props;
    }

    shouldComponentUpdate(newProps) {
        const { products } = this.props;

        return !_isEqual(products, newProps.products);
    }

    getOgImages = (requestData) => {
        const { products } = this.props;
        const host = requestData.protocol;
        const imgId = _get(products[0], 'imagesInfo.thumbnailImageId');
        const firstProductImage = isDesktopWidth()
            ? getScene7desktopImageURL(imgId)
            : getScene7ImageURL(imgId);

        return <meta property="og:image" content={`${host}${firstProductImage}`} />;
    };

    getCanonicalUrl = () => {
        const {
            featureFlags: { HTTPS_FOR_SEO_ENABLED },
            seoTags,
        } = this.props;
        const configProtocol = HTTPS_FOR_SEO_ENABLED ? 'https://' : 'http://';
        const canonicalUrl =
            seoTags.canonicalURL && isAbsoluteUrl(seoTags.canonicalURL)
                ? seoTags.canonicalURL
                : `${configProtocol}www.jcpenney.com${seoTags.canonicalURL}`;

        return <meta property="og:url" content={canonicalUrl} />;
    };

    render() {
        const { request, location, seoTags } = this.props;
        const requestData = parseRequestUrl(request, location);

        if (!_isEmpty(seoTags)) {
            return (
                <Helmet>
                    <meta charSet="utf-8" />
                    {seoTags.seoMetaKeyword && (
                        <meta name="keywords" content={seoTags.seoMetaKeyword} />
                    )}
                    {/* Twitter MetaData */}
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@jcpenney" />
                    {/* Facebook MetaData */}
                    {seoTags.seoMetaTitle && (
                        <meta property="og:title" content={seoTags.seoMetaTitle} />
                    )}
                    {seoTags.seoMetaDescription && (
                        <meta property="og:description" content={seoTags.seoMetaDescription} />
                    )}
                    <meta property="og:site_name" content="JCPenney" />
                    <meta property="og:type" content="website" />
                    {this.getOgImages(requestData)}
                    {this.getCanonicalUrl()}
                </Helmet>
            );
        }
        return null;
    }
}

const mapStateToProps = (store) => ({
    featureFlags: selectFeatureFlags(store),
    products: store.products,
    request: getRequest(store),
    seoTags: getSeoTag(store),
});

export default connect(mapStateToProps)(SeoSocial);
