import { GET_GALLERY_DETAILS_BY_ID_SUCCESS } from '../actionTypes/GalleryActionTypes';

export default function partialMatchInfoReducer(state = {}, action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS:
            return action.galleryDetails && action.galleryDetails.partialMatchInfo
                ? action.galleryDetails.partialMatchInfo
                : {};

        default:
            return state;
    }
}
