/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'prop-types';
import classNames from 'classnames/bind';
import Icon from 'yoda-core-components/lib/components/Icon';
import Close from 'yoda-core-components/lib/assets/svg/CloseIcon_2022.svg';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './FilterChips.css';
import { updateUrl, getRandomId, removeStoreIdsFromUrl } from '../../utils';
import FacetLabel from '../Facet/components/FacetLabel/FacetLabel';
import { getMultiSelectedFacetsList } from '../../helpers/FacetHelper';
import updateMultiSelectFacetInfo from '../../actions/MultiSelectFilterActions';
import { setFailoverCanonicalInfo } from '../../actions/GalleryAction';
import { showLoader } from '../../actions/LoaderActions';

const cx = classNames.bind(styles);

const FilterChips = ({ chipDescTheme, filter }) => {
    const {
        context: {
            featureFlags: {
                enableMultiSelectFilters = false,
                enableBrandNameCasing = false,
                enableCanonicalFallback = true,
            } = {},
        } = {},
        multiSelectFilter: { multiSelectedFacetsList = {} } = {},
        filters: { filterList = [] } = {},
    } = useSelector((store) => store);
    const dispatch = useDispatch();

    const { solrFacetName = '' } = filter;
    const capitalize =
        (!enableBrandNameCasing || (solrFacetName !== 'brand' && enableBrandNameCasing)) &&
        dt(['capitalize']);
    const handleClick = () => {
        let newUrl = filter.apiUrl;
        newUrl = removeStoreIdsFromUrl(newUrl, filter);
        enableCanonicalFallback &&
            dispatch(
                setFailoverCanonicalInfo({
                    isUpdateCanonicalUrl: true,
                })
            );
        dispatch(showLoader());
        /* istanbul ignore if */
        if (enableMultiSelectFilters) {
            dispatch(
                updateMultiSelectFacetInfo({
                    multiSelectedFacetsList: getMultiSelectedFacetsList(
                        {},
                        filter,
                        filterList,
                        false,
                        multiSelectedFacetsList
                    ),
                    enableFacetLoader: true,
                    enableProductsLoader: true,
                    multiFacetUrl: newUrl,
                    scrollToTitle: true,
                })
            );
        }
        updateUrl(newUrl, null, null, {
            action: 'remove',
            name: filter.facetName,
            value: filter.name,
            taxonomyKey: filter.facetInternalName ? filter.facetInternalName.toLowerCase() : '',
        });
    };

    return (
        <li
            className={dt([
                'bg-light-white',
                'border',
                'inline-flex',
                'rounded-[14px]',
                'w-auto',
                'cursor-pointer',
            ])}
            key={getRandomId()}
        >
            <button
                type="button"
                className={`${dt([
                    'flex',
                    'flex-row',
                    'items-center',
                    'gap-1',
                    'py-1',
                    'pr-2',
                    'pl-3',
                ])} ${cx('selectedFiltersContent')}`}
                data-automation-id={`filterActiveTag-${filter?.facetValue}`}
                onClick={handleClick}
            >
                <div
                    className={`${dt([
                        'inline-block',
                        'p-0',
                        'lg:text-small',
                        'sm:text-base',
                    ])} ${cx(chipDescTheme)} ${capitalize}`}
                >
                    <FacetLabel
                        config={filter}
                        facetConfig={filter}
                        theme={`${dt(['inline-block', 'h-5'])} ${cx('label', 'noImage')}`}
                    />
                </div>
                <div className={dt(['flex', 'items-center', 'm-0'])}>
                    <Icon automationId="footer-get-icon" height="20" viewBox="0 0 20 20" width="20">
                        <Close />
                    </Icon>
                </div>
            </button>
        </li>
    );
};

FilterChips.defaultProps = {
    chipDescTheme: null,
    filter: {},
};

FilterChips.propTypes = {
    chipDescTheme: string,
    filter: object,
};

export default FilterChips;
