import { createSelector } from 'reselect';
import get from 'lodash/get';
import { selectFeatureFlags } from 'yoda-site-components/lib/selectors/ContextSelector';

export const selectdisableProductCountGallery =  createSelector( // eslint-disable-line
    [selectFeatureFlags],
    (featureFlags) => get(featureFlags, 'disableProductCountGallery', false)
);

export const selectdisableProductCountSearch =  createSelector( // eslint-disable-line
    [selectFeatureFlags],
    (featureFlags) => get(featureFlags, 'disableProductCountSearch', false)
);
