import _get from 'lodash/get';
import { redirectToUrl, compareUrls } from '../utils';
import { catIdRedirect } from '../configurations/config';

const redirectRequired = (data = {}, requestUrl = '') => {
    if (data.pdPageURL) {
        redirectToUrl(data.pdPageURL, true);
        return true;
    }
    if (data.keywordRedirectUrl) {
        if (__SERVER__) {
            return { type: 'keywordredirect', url: data.keywordRedirectUrl };
        }
        redirectToUrl(data.keywordRedirectUrl, true);
        return true;
    }
    if (data.type === 'X2') {
        let departmentUrl;
        // V1 Code - _get(data, 'seoTitleTags.canonicalURL', '')
        // As per discussion with santosh reading redirect URL from breadcrumb block - This works for both V1 and V2
        // breadCrumbInfo - Picking first item from this array.
        const canonicalURL = _get(data, 'breadCrumbInfo[0].pageUrl', '');
        if (!compareUrls(requestUrl, canonicalURL)) {
            departmentUrl = canonicalURL;
        }
        redirectToUrl(departmentUrl, true, { pageType: 'X2H2' });
        return true;
    }
    if (requestUrl.match(catIdRedirect.pattern) && data.response) {
        if (__SERVER__)
            return { type: 'canonical', url: data.response, restrictedParams: ['id', 'deptId'] }; // server side 301 redirect
        redirectToUrl(data.response, true); // If server side call failed, normal redirect on client side
        return true;
    }

    return false;
};

export default redirectRequired;
