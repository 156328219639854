import * as IrisActionTypes from 'yoda-site-components/lib/actionTypes/IrisActionTypes';
import { SET_PAGE_CACHE } from 'yoda-app-shell/lib/actionTypes/CacheActionTypes';

/**
 * This function will used only for redux middleware only to track tagging based on action request
 */

/* istanbul ignore next */
const IrisMiddleware = store => next => action => { // eslint-disable-line
    if (__SERVER__) {
        switch (action.type) {
            case IrisActionTypes.IRIS_PAGE_SET_CACHE: {
                /* check the cacheControl and set the ttl value */
                const ttlValue = action.cacheControl ? action.cacheControl : 0;
                /**
                 * Dispatching Cache Action Here
                 */
                store.dispatch({
                    type: SET_PAGE_CACHE,
                    cache: { ttl: ttlValue },
                });
                break;
            }
            default:
                break;
        }
    }
    return next(action);
};

export default IrisMiddleware;
