import React from 'react';
import PropTypes from 'prop-types';
import ConnectedGalleryMarketingZone from '../GalleryMarketingZone/GalleryMarketingZone';
/* istanbul ignore next */
export const ProductMarketingTile = (props) => {
    const {
        deviceType,
        slotPosition,
        disableLoader,
        marketingSlot,
        irisComponents,
        irisPropsData,
    } = props;
    const marketingSlotData = marketingSlot || [];
    if (marketingSlotData && marketingSlotData[slotPosition]) {
        return (
            <ConnectedGalleryMarketingZone
                productMarketingSlot={marketingSlotData[slotPosition]}
                deviceType={deviceType}
                disableLoader={disableLoader}
                irisComponents={irisComponents}
                irisPropsData={irisPropsData}
            />
        );
    }
    return null;
};

ProductMarketingTile.propTypes = {
    deviceType: PropTypes.objectOf(PropTypes.bool).isRequired,
    slotPosition: PropTypes.number.isRequired,
    disableLoader: PropTypes.bool.isRequired,
    irisComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    irisPropsData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    marketingSlot: PropTypes.oneOfType([PropTypes.array]),
};

ProductMarketingTile.defaultProps = {
    irisComponents: {},
    irisPropsData: {},
    marketingSlot: [],
};

export default ProductMarketingTile;
