import { createSelector } from 'reselect';
import { selectFeatureFlags } from 'yoda-site-components/lib/selectors/ContextSelector';
import { fail } from '../containers/Pages/Iris/constants';
import { selectIrisContentCallStatus } from './fromState';

export const getIrisFallBackStatus = createSelector( // eslint-disable-line
    [selectIrisContentCallStatus, selectFeatureFlags],
    (irisCallStatus, featureFlags) => {
        if (irisCallStatus === fail || featureFlags.disableIrisCall) {
            return true;
        }
        return false;
    }
);
