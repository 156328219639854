import universal from 'react-universal-component';
import universalImport from 'babel-plugin-universal-import/universalImport';
import path from 'path';
import { timeout } from 'yoda-site-components/lib/lazy/lazyConstants';

export default universal(
    universalImport({
        chunkName: () => 'CertonaLazy',
        path: () => path.join(__dirname, './Certona'),
        resolve: () => require.resolveWeak('./Certona'),
        load: () =>
            Promise.all([import(/* webpackChunkName: "CertonaLazy" */ './Certona')]).then(
                (proms) => proms[0]
            ),
    }),
    {
        loading: () => null,
        error: () => null,
        timeout,
    }
);
