import React from 'react';
import defaultWrapperRenderer from 'yoda-site-components/lib/components/IrisPage/defaultWrapperRenderer';
import renderCurrentComponent from 'yoda-site-components/lib/components/IrisPage/renderCurrentComponent';
import generateComponentKeyString from 'yoda-site-components/lib/components/IrisPage/generateComponentKeyString';

import GalleryGridConnected from '../../components/GalleryGrid/GalleryGrid';

export default (
    zone,
    zoneComponents = [],
    irisCompleteComponentList,
    irisComponentsWithoutGrid,
    propsData
) => {
    let marketingTitle = [];
    let gridAds = {};
    const componentsToRender = [...zoneComponents];
    if (componentsToRender.length > 0) {
        for (let i = 0; i < componentsToRender.length; i += 1) {
            if (componentsToRender[i].componentType === 'gridcomponent') {
                marketingTitle = componentsToRender[i];
                componentsToRender.splice(i, 1);
                i -= 1;
            } else if (componentsToRender[i].componentType === 'ads') {
                gridAds = componentsToRender[i];
                componentsToRender.splice(i, 1);
                i -= 1;
            }
        }
    }

    return [
        <GalleryGridConnected
            gridAds={gridAds}
            irisComponents={irisCompleteComponentList}
            irisComponentsWithoutGrid={irisComponentsWithoutGrid}
            irisPropsData={propsData}
            key={generateComponentKeyString('gallerygridcomponent', 0)}
            marketingSlot={marketingTitle}
        />,
        ...componentsToRender.map((component, index) =>
            defaultWrapperRenderer(
                renderCurrentComponent(
                    component,
                    index,
                    irisCompleteComponentList,
                    irisComponentsWithoutGrid,
                    propsData
                ),
                generateComponentKeyString(component.componentType, index)
            )
        ),
    ];
};
