import React, { PureComponent } from 'react';
import { array, object, oneOfType, string } from 'prop-types';
import { withRouter } from 'react-router';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import { isDesktopWidth } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import renderCurrentComponent from 'yoda-site-components/lib/components/IrisPage/renderCurrentComponent';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './GalleryMarketingZone.css';

const cx = classNames.bind(styles);

/* istanbul ignore next */
export class GalleryMarketingZone extends PureComponent {
    static defaultProps = {
        irisComponents: {},
        irisComponentsWithoutGrid: {},
        irisPropsData: {},
        productMarketingSlot: {},
        zoneType: '',
    };

    static propTypes = {
        irisComponents: oneOfType([array, object]),
        irisComponentsWithoutGrid: oneOfType([array, object]),
        irisPropsData: oneOfType([array, object]),
        productMarketingSlot: oneOfType([array, object]),
        zoneType: string,
    };

    constructor(props) {
        super(props);
        this.contentSlot = { totalFailCount: 0, totalImageCount: 0 };
        this.onImageError = this.onImageError.bind(this);
        this.state = { imageFailed: false };
    }

    componentDidMount() {
        const {
            productMarketingSlot: { id },
        } = this.props;
        const storedScrollTo = LocalStorage.getData('galleryMarketingScrollTo');

        if (storedScrollTo === id && isDesktopWidth()) {
            try {
                this.marketingZoneRef &&
                    this.marketingZoneRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('ScrollToMarketingProduct Error', e);
            }
            LocalStorage.removeData('galleryMarketingScrollTo');
        }
    }

    onImageError = () => {
        const { zoneType } = this.props;
        /* istanbul ignore next */
        if (zoneType !== 'contentSlot' || !this.contentSlot.totalImageCount) {
            this.setState({
                imageFailed: true,
            });
        } else {
            this.contentSlot.totalFailCount += 1;
            if (this.contentSlot.totalImageCount === this.contentSlot.totalFailCount) {
                this.setState({
                    imageFailed: true,
                });
            }
        }
    };

    handleMarketingEvent = () => {
        const { productMarketingSlot } = this.props;
        LocalStorage.setData('galleryMarketingScrollTo', productMarketingSlot.id);
    };

    render() {
        let marketingZone = null;
        const {
            irisComponents,
            irisComponentsWithoutGrid,
            irisPropsData,
            productMarketingSlot,
            zoneType,
        } = this.props;
        const { imageFailed } = this.state;

        if (imageFailed) {
            // If image load has failed, remove the component from DOM
            return null;
        }

        if (!_isEmpty(productMarketingSlot)) {
            const marketingClasses = `${dt([
                'flex',
                'h-auto',
                'items-center',
                'justify-center',
                'max-w-full',
                'text-center',
                'sm:!w-[auto]',
            ])} ${cx('marketing-zone')}`;

            marketingZone = (
                <div
                    className={`${dt([
                        'mb-0',
                        'mt-0',
                        'mx-auto',
                        'w-full',
                    ])}  ${'ProductCard-productCardPane'}`}
                    data-whoami={`${zoneType}-coreZones`}
                    ref={(node) => {
                        this.marketingZoneRef = node;
                    }}
                >
                    <div
                        className={marketingClasses}
                        onClick={this.handleMarketingEvent}
                        onKeyPress={this.handleMarketingEvent}
                        role="button"
                        tabIndex={0}
                    >
                        {renderCurrentComponent(
                            productMarketingSlot,
                            0,
                            irisComponents,
                            irisComponentsWithoutGrid,
                            irisPropsData
                        )}
                    </div>
                </div>
            );
        }

        return marketingZone;
    }
}

export default withRouter(GalleryMarketingZone);
