import { updateQueryStringParameter, removeQueryStringParameter } from '../utils';

export const encodedUrl = (apiurl, { solrFacetName }, multiSelectedFacetsList) => {
    let selectedFacets = (multiSelectedFacetsList && multiSelectedFacetsList[solrFacetName]) || [];
    selectedFacets = [...selectedFacets].join().replace(/,/g, encodeURI('|'));
    if (selectedFacets && selectedFacets.length > 0)
        return updateQueryStringParameter(apiurl, solrFacetName, selectedFacets);
    return removeQueryStringParameter(apiurl, solrFacetName);
};

export const getMultiSelectedFacetsList = (
    { facetValueList = [] } = {},
    { facetValue = '', solrFacetName = '' } = {},
    filterList = {},
    isChecked = false,
    selectedFacets = {}
) => {
    const selectedFacetsArray = Object.keys(selectedFacets);
    const existingFacetsList = facetValueList.filter((item) => item.selected === true) || [];

    const getUpdatedFacets = () => {
        /**
         * getUpdatedList:
         * If solrFacetName is not in the selectedFacetsArray then check if existingFacetsList
         * contains anything. If so get updated list of facets, else use current facet list.
         * If solrFacetName is in selectedFacetsArray and facet isChecked return new facet list
         * containing newly selectedFacets else remove current facetValue from selectedFacets.
         */
        /* istanbul ignore next */
        const getUpdatedList = () => {
            const existingFacetValueList = existingFacetsList.map(
                ({ facetValue: facet = '' }) => facet
            );

            // If isChecked add to existingFacetList else remove from existingFacetList.
            return isChecked
                ? [...new Set([...existingFacetValueList, facetValue])]
                : existingFacetValueList.filter((item) => item !== facetValue);
        };

        if (!selectedFacetsArray.includes(solrFacetName))
            return existingFacetsList.length > 0
                ? getUpdatedList()
                : isChecked && facetValue.split(',');
        return isChecked
            ? [...new Set([...selectedFacets[solrFacetName], facetValue])]
            : selectedFacets &&
                  selectedFacets[solrFacetName] &&
                  selectedFacets[solrFacetName].filter((item) => item !== facetValue);
    };
    /* istanbul ignore next */
    const mapWithExistingFacets = () => {
        const selectedFilteredList =
            filterList &&
            filterList.length > 0 &&
            filterList.filter((x) => x.noOfSelectedFacetValues);
        const facetContainer = {};
        selectedFilteredList &&
            selectedFilteredList.forEach((facetDetails) => {
                const selectlistByKey = facetDetails.facetValueList.filter(
                    (item) => item.selected === true
                );
                if (selectlistByKey.length > 0) {
                    let valueList = selectlistByKey.map(({ facetValue: values = '' }) => values);
                    if (selectlistByKey[0].solrFacetName === solrFacetName) {
                        valueList = isChecked
                            ? [...new Set([...valueList, facetValue])]
                            : valueList.filter((item) => item !== facetValue);
                    }
                    if (valueList && valueList.length > 0) {
                        facetContainer[selectlistByKey[0].solrFacetName] = valueList;
                    }
                }
            });
        return facetContainer;
    };
    let mergeWithExistingSelectedFacets = mapWithExistingFacets();
    mergeWithExistingSelectedFacets =
        mergeWithExistingSelectedFacets && Object.keys(mergeWithExistingSelectedFacets).length > 0
            ? mergeWithExistingSelectedFacets
            : isChecked && { [solrFacetName]: facetValue.split(',') };

    const filteredMultiSelectedFacets =
        selectedFacetsArray.length === 0
            ? mergeWithExistingSelectedFacets
            : { [solrFacetName]: getUpdatedFacets() };
    const updatedlist = selectedFacets;
    /* istanbul ignore if */
    if (
        filteredMultiSelectedFacets &&
        filteredMultiSelectedFacets[solrFacetName] &&
        filteredMultiSelectedFacets[solrFacetName].length === 0
    ) {
        delete filteredMultiSelectedFacets[solrFacetName];

        if (updatedlist && updatedlist[solrFacetName]) updatedlist[solrFacetName] = '';
    }

    return facetValue
        ? {
              ...updatedlist,
              ...filteredMultiSelectedFacets,
          }
        : {};
};
