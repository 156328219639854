import { createSelector } from 'reselect';
import get from 'lodash/get';
import { selectIrisData } from './fromState';
import { irisDataProcessor } from '../utils/irisDataProcesserFunction';

const getIrisInteractiveVizNavInfo = (state, selfProps) => get(selfProps, 'irisData', undefined);

/*
this selector is to select value from pros irisData example: when irisData is passed to component directly
in case its not passing directly then irisData will procees from stored irisData which is in row and coloumn
format.

selectIrisData : will give irisData from state which is in row format . [row and column format]
*/

export const getInteractiveVizNavFromIrisDataState = createSelector(
    [selectIrisData, getIrisInteractiveVizNavInfo],
    (irisDataFromState, irisDataFromProps) => {
        if (irisDataFromProps) {
            return irisDataFromProps;
        }
        if (irisDataFromState) {
            return irisDataProcessor(irisDataFromState, 'interactivevisnav');
        }
        return null;
    }
);

export const getInteractiveVizNav = createSelector(
    [getInteractiveVizNavFromIrisDataState],
    (irisInteractiveVizNavInfo) => {
        return irisInteractiveVizNavInfo && irisInteractiveVizNavInfo.navigations
            ? { interactiveVizNav: irisInteractiveVizNavInfo.navigations }
            : { interactiveVizNav: [] };
    }
);
