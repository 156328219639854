import { all, takeLatest, put, call, select, fork } from 'redux-saga/effects';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { getLatLongUsingGeocode } from 'yoda-site-components/lib/sagas/FindStoresSaga';
import { getStoreById } from 'yoda-interfaces/lib/Order/StoreApi';
import Location from 'yoda-core-components/lib/helpers/Location/Location';
import { loadAkamaiStore } from 'yoda-site-components/lib/sagas/LocationServiceSaga';
import { fetchStores, getStoresByLatLong } from 'yoda-interfaces/lib/Common/LocationServiceApi';
import { updateUserStoreLocationInfo } from 'yoda-site-components/lib/actions/LocationServiceAction';
import { getGalleryStores } from 'yoda-interfaces/lib/Catalog/CategoriesApi';
import AnalyticsActionTypes from 'yoda-site-components/lib/actionTypes/AnalyticsActionTypes';
import isBloomReachAudience, {
    enableBloomReachPage,
} from 'yoda-site-components/lib/helpers/BloomReach/BloomReach';
import { selectContext } from '../selectors/ContextSelector';
import Application from '../helpers/Application';
import {
    getPageURL,
    getPageType,
    getURLParameterBykey,
    updateUrl,
    updateQueryStringParameter,
    removeQueryStringParameter,
    setStoreSeq,
} from '../utils';
import { BOPISErrors } from '../common/constants';
import { getAutoCorrectedMessage } from '../selectors/SeoTags';
import {
    FETCH_GALLERY_STORES,
    GALLERY_STORES_SUCCESS,
    FETCH_STORES_ERROR,
    PRE_POPULATE_GALLERY_STORES,
    DISPLAY_STORES_LOADER,
    HIDE_STORES_LOADER,
    PRE_POPULATE_STORES,
    GET_USER_GEO_LOCATION,
    DISABLE_SELECT_STORE,
    FETCH_STORES_ERROR_ZERO_PRODUCT,
    UPDATE_SELECTED_STORE_LOCATION_INFO,
    GET_USER_NATIVE_GEO_LOCATION,
} from '../actionTypes/BOPISActionTypes';
import REFRESH_CONTENT from '../actionTypes/RefreshContentActionTypes';

const logger = Application.getLogger('saga');

// const autoCorrectedMessage = state => getAutoCorrectedMessage(state);
const getGeoLocatedStore = (state) => state.locationServiceReducer;
const bopisInfoStore = (state) => state.bopisInfo;

/**
 * Update sorted(based on distance) list of stores to URL.
 */
function* updateSortedStoresToUrl({ urlStoreIds }) {
    const sortedList = yield select(bopisInfoStore);
    const { selectedStores } = sortedList;
    const sortedListIds = selectedStores && selectedStores.map((sortedStore) => sortedStore.id);
    const joinList = sortedListIds.join('-');
    if (sortedListIds && urlStoreIds !== joinList) {
        updateUrl(
            getPageURL(),
            {
                storeIds: joinList,
            },
            true
        );
        setStoreSeq(joinList);
    }
}
/**
 * If the stores are available then on selection of stores rearranging it to get the selected ones top in the list.
 */
function* reArrangeStores({ params, zipCode, isBRAudience }) {
    const bopisInfo = yield select(bopisInfoStore);
    const selectedStoreIds = getURLParameterBykey('storeIds');
    const storeIdsList = (selectedStoreIds && selectedStoreIds.split('-')) || [];
    const context = yield select(selectContext);
    const featureFlags = context.featureFlags || {};
    const enableBOPISZeroProduct = !isBRAudience
        ? _get(featureFlags, 'enableBOPISZeroProduct', false)
        : false;
    const solrZipcode = _get(bopisInfo, 'solrZipcode', '');

    const bopisInfoStores =
        bopisInfo.sortedStoresList &&
        (!isBRAudience
            ? bopisInfo.storesList.filter((x) => x.productCount > 0).length !== 0
            : bopisInfo.storesList);
    if (bopisInfoStores) {
        const stores = bopisInfo.sortedStoresList.map((store) => {
            const updatedStore = {
                ...store,
                selected: storeIdsList.indexOf(store.id) >= 0,
            };

            return updatedStore;
        });

        yield put({
            solrZipcode,
            enableBOPISZeroProduct,
            lastRequestedParams: params,
            params: {
                zipCode,
            },
            stores,
            isBRAudience,
            type: GALLERY_STORES_SUCCESS,
        });
    }
}

function removeStoreParams(url) {
    const ignoredParams = ['storeIds', 'store_availability'];
    let updatedUrl = '';

    ignoredParams.forEach((ignoredParam) => {
        updatedUrl = removeQueryStringParameter(url, ignoredParam);
    });

    return updatedUrl;
}

function validateZip(params) {
    const isZipCode = params.match(/^\+?(0|[1-9]\d*)$/);
    if (isZipCode && isZipCode.length) {
        return true;
    }
    return false;
}

function* getGalleryStoresByLocation({ radius, location, storeIds }, preferences) {
    const browsePreferences = _get(preferences, 'bloomReachSetup.browseAPI', {});
    const payload = storeIds
        ? {
              page: browsePreferences.page || 1,
              pageSize: browsePreferences.pageSize || 100,
              limit: browsePreferences.limit || 50,
              radius,
              location,
              storeIds,
          }
        : {
              page: browsePreferences.page || 1,
              pageSize: browsePreferences.pageSize || 100,
              limit: browsePreferences.limit || 50,
              radius,
              location,
          };
    const response = yield call(getStoresByLatLong, payload);
    let stores = response?.data?.stores?.map(({ id, name, distance, services }) => ({
        id,
        name,
        distance,
        curbsidePickupIndicator: services.some((item) => item === 'Curbside Pickup'),
        selected: storeIds.includes(id),
    }));
    stores = stores?.sort((a, b) => a.distance - b.distance) || [];
    const status = response?.status;
    const brResponse = {
        brData: status !== 500 ?? { zipcode: location },
        brStores: stores,
        brStatus: status,
    };
    return { brResponse };
}

function* getGalleryStoresSaga({ params, zipCode, refreshStores }) {
    let currUrlState = getPageURL();
    let invokeAPI = true;
    const autoCorrectWord = yield select(getAutoCorrectedMessage);
    const autoCorrectSearchTerm = autoCorrectWord.replace(/ /g, '+');
    const context = yield select(selectContext);
    const preferences = context.preferences || {};
    const featureFlags = context.featureFlags || {};
    const isNative = context.isNative || false;
    const requestUrl = _get(context, 'requestUrl', '');
    const enableBloomReachV3PLP = _get(featureFlags, 'enableBloomReachV3PLP', true);
    const enableBloomReachNative = _get(featureFlags, 'enableBloomReachNative', false);
    const plpBloomreachInclusionList = _get(preferences, 'plpBloomreachInclusionList', []);
    const enableBOPISZeroProduct = _get(featureFlags, 'enableBOPISZeroProduct', false);
    const enableBloomReach = _get(featureFlags, 'enableBloomReach', false);
    const bopisPreferences = _get(preferences, 'bopis', {});
    const bopisDefaultRadius = _get(bopisPreferences, 'radius', 75);
    const noAvailabilityMsg = _get(bopisPreferences, 'noAvailability', BOPISErrors.noAvailability);
    const noResultsMsg = _get(bopisPreferences, 'noResults', BOPISErrors.noResults);
    const storesResultsZeroProducts = _get(
        bopisPreferences,
        'storesResultsZeroProducts',
        BOPISErrors.storesResultsZeroProducts
    );
    // Get the UsrSeq value to invoke Endeca or Solr.
    currUrlState = autoCorrectSearchTerm
        ? updateQueryStringParameter(undefined, 'searchTerm', autoCorrectSearchTerm)
        : currUrlState;
    if (currUrlState.charAt(0) === '/') {
        currUrlState = currUrlState.slice(1);
    }
    const isNativeBloomReach = enableBloomReachNative ? false : isNative;
    const isBloomReachAudienceABTest =
        isBloomReachAudience(enableBloomReach, isNativeBloomReach) && getPageType() === 's';
    const isBloomReachV3Page = enableBloomReachPage(
        enableBloomReachV3PLP,
        requestUrl,
        plpBloomreachInclusionList
    );
    const isBRAudience = isBloomReachAudienceABTest || isBloomReachV3Page;

    if (refreshStores && params) {
        const curURL = removeStoreParams(currUrlState);
        const lastURL = removeStoreParams(params.currUrlState);

        if (curURL === lastURL) {
            invokeAPI = false;
            yield call(reArrangeStores, { params, zipCode, isBRAudience });
        }
    }
    if (currUrlState !== params.currUrlState && invokeAPI) {
        const updatedParams = {
            ...params,
            currUrlState,
            storeIds: getURLParameterBykey('storeIds', currUrlState),
        };
        const { location } = updatedParams;
        if (location) {
            let storesInfo = {};
            const isDisplayZeroProducts = !isBRAudience && enableBOPISZeroProduct;
            let storesInfoObj = {};
            let storesData = [];
            let storeStatus = '';
            if (isBRAudience) {
                const brResponseObj = yield call(
                    getGalleryStoresByLocation,
                    updatedParams,
                    preferences
                );
                const { brData = {}, brStores = [], brStatus = ' ' } =
                    !_isEmpty(brResponseObj) && brResponseObj.brResponse;
                storesInfoObj = brData;
                storesData = brStores;
                storeStatus = brStatus;
            } else {
                storesInfo = yield call(getGalleryStores, updatedParams);
                storesInfoObj = storesInfo?.data;
                storesData = storesInfo?.data?.stores;
                storeStatus = storesInfo?.status;
            }

            if (storesInfoObj) {
                if (storesData?.length > 0) {
                    const solrZipcode = _get(storesInfoObj, 'zipcode', zipCode);
                    if (
                        !isBRAudience &&
                        storesData.filter((x) => x.productCount > 0).length === 0
                    ) {
                        if (isDisplayZeroProducts) {
                            yield put({
                                type: FETCH_STORES_ERROR_ZERO_PRODUCT,
                                errorMessage: storesResultsZeroProducts,
                                params: {
                                    zipCode,
                                },
                                stores: storesData,
                                lastRequestedParams: updatedParams,
                                enableBOPISZeroProduct: isDisplayZeroProducts,
                            });
                        } else {
                            yield put({
                                type: FETCH_STORES_ERROR,
                                errorMessage: noAvailabilityMsg,
                                params: {
                                    zipCode,
                                },
                                reset: true,
                            });
                        }

                        const errorDetails = [{ errorDescription: noAvailabilityMsg }];
                        yield put({ type: AnalyticsActionTypes.FORM_ERROR, errorDetails });
                    } else {
                        yield put({
                            type: GALLERY_STORES_SUCCESS,
                            stores: storesData,
                            params: {
                                zipCode,
                            },
                            lastRequestedParams: updatedParams,
                            isBRAudience,
                            enableBOPISZeroProduct: isDisplayZeroProducts,
                            solrZipcode,
                        });
                        yield call(updateSortedStoresToUrl, {
                            urlStoreIds: updatedParams.storeIds,
                        });
                        let hasAllStoresOutside75MilesRadius = false;
                        if (!isBRAudience) {
                            hasAllStoresOutside75MilesRadius = storesData
                                .filter((store) => store.productCount > 0)
                                .every(
                                    (store) =>
                                        parseInt(store.distance, 10) > bopisDefaultRadius &&
                                        store.selected
                                );
                        } else {
                            hasAllStoresOutside75MilesRadius = storesData.every(
                                (store) =>
                                    parseInt(store.distance, 10) > bopisDefaultRadius &&
                                    store.selected
                            );
                        }
                        if (hasAllStoresOutside75MilesRadius) {
                            const errorDetails = [{ errorDescription: noResultsMsg }];
                            yield put({ type: AnalyticsActionTypes.FORM_ERROR, errorDetails });
                        }
                    }
                } else {
                    yield put({
                        type: FETCH_STORES_ERROR,
                        errorMessage: noResultsMsg,
                        params: {
                            zipCode,
                        },
                        reset: true,
                    });
                    const errorDetails = [{ errorDescription: noResultsMsg }];
                    yield put({ type: AnalyticsActionTypes.FORM_ERROR, errorDetails });
                }
            } else if (storeStatus === 500) {
                /* disabling select store option when api fails to fetch any details */
                yield put({
                    type: DISABLE_SELECT_STORE,
                    disableSelectStore: true,
                });
            }
        } else {
            yield put({
                type: FETCH_STORES_ERROR,
                errorMessage: noResultsMsg,
                params: {
                    zipCode,
                },
                reset: true,
            });
            const errorDetails = [{ errorDescription: noResultsMsg }];
            yield put({ type: AnalyticsActionTypes.FORM_ERROR, errorDetails });
        }
    }
    yield put({ type: HIDE_STORES_LOADER });
}

function* fetchGalleryStores(action) {
    try {
        const { zipCode } = action.payload;
        yield all([put({ type: DISPLAY_STORES_LOADER }), put({ type: PRE_POPULATE_STORES })]);
        const context = yield select(selectContext);
        const preferences = context.preferences || {};
        // get radius value from preferences
        const bopisPreferences = _get(preferences, 'bopis', {});
        const bopisDefaultRadius = _get(bopisPreferences, 'radius', 75);
        const featureFlags = context.featureFlags || {};
        const enableExternalGoogle = _get(featureFlags, 'enableExternalGoogle', true);
        const enableExternalGoogleOnlyZip = _get(
            featureFlags,
            'enableExternalGoogleOnlyZip',
            false
        );

        if (enableExternalGoogle && (!enableExternalGoogleOnlyZip || validateZip(zipCode))) {
            const zipCodeValue = zipCode; // To fix expected property shortend eslint error
            const params = {
                radius: bopisDefaultRadius,
                location: zipCodeValue,
            };
            yield call(getGalleryStoresSaga, { params, zipCode });
        } else {
            const latLong = yield call(getLatLongUsingGeocode, zipCode);
            const { lat: userLatitude, lng: userLongitude } = latLong || {};
            const { lat: latitude, lng: longitude } = latLong || {};
            const latLongEnabled = latitude && longitude;
            const userLocationEnabled = userLatitude && userLongitude;
            if (latLongEnabled || userLocationEnabled) {
                const params = {
                    radius: bopisDefaultRadius,
                    userLatitude,
                    userLongitude,
                    longitude,
                    latitude,
                };
                yield call(getGalleryStoresSaga, { params, zipCode });
            } else {
                yield put({
                    type: FETCH_STORES_ERROR,
                    errorMessage: BOPISErrors.somethingWentWrong,
                });
            }
        }
    } catch (error) {
        logger.error({
            errorMessage: 'Gallery stores call failed',
            exception: error,
        });
        yield put({
            type: FETCH_STORES_ERROR,
            errorMessage: error,
        });
    }
}

function* getUserPreferredStore(
    {
        city = '',
        distance = 0,
        id,
        name = '',
        phone = '',
        services,
        state = '',
        street = '',
        timings,
        timingsOverrideMessage = '',
        zip,
    },
    solrZipcode = ''
) {
    const context = yield select(selectContext);
    const preferences = context.preferences || {};
    const curbsidePickupService = _get(preferences, 'curbsidePickupInfo.curbsidePickupService', '');
    const curbsidePickupIndicator = services.some(
        (item) => item.toString().toLowerCase() === curbsidePickupService.toString().toLowerCase()
    );
    return {
        city,
        curbsidePickupIndicator,
        distance,
        id,
        inputZipCode: solrZipcode,
        name,
        phone,
        state,
        street,
        timings,
        timingsOverrideMessage,
        zip,
        services,
    };
}

function* prePopulateStores({ payload }) {
    try {
        yield put({ type: PRE_POPULATE_STORES });
        let keyStore = null;
        if (typeof payload === 'object') {
            keyStore = payload;
        } else {
            // If Store details are not available then using store ID to get store details.
            yield put({ type: DISPLAY_STORES_LOADER });

            const { data: { stores: [store] = [] } = {} } = yield call(getStoreById, {
                storeId: payload,
            });

            keyStore = store;
            if (store) {
                const userPreferredStore = yield call(getUserPreferredStore, store);
                yield put(updateUserStoreLocationInfo(userPreferredStore));
            }
        }

        if (keyStore && keyStore.zip) {
            let { zip } = keyStore;
            const locationStoreInfo = yield select((state) => state.locationServiceReducer);
            if (!_isEmpty(locationStoreInfo?.inputZipCode)) {
                zip = locationStoreInfo.inputZipCode;
            }
            yield fork(fetchGalleryStores, {
                payload: {
                    zipCode: zip,
                },
            });
        }
    } catch (error) {
        yield put({
            type: FETCH_STORES_ERROR,
            errorMessage: BOPISErrors.prePopulateError,
        });
    }
}

export function* refreshStoreInventory() {
    try {
        const { lastRequestedParams, params } = yield select((state) => state.bopisInfo);
        if (lastRequestedParams && !__SERVER__) {
            yield put({
                type: DISPLAY_STORES_LOADER,
            });
            yield call(getGalleryStoresSaga, {
                params: lastRequestedParams,
                zipCode: params.zipCode,
                refreshStores: true,
            });
        }
    } catch (error) {
        logger.error({
            errorMessage: 'Gallery stores call failed',
            exception: error,
        });
        yield put({
            type: FETCH_STORES_ERROR,
            errorMessage: error,
        });
    }
}

export function* getUserGeoLocation() {
    try {
        yield put({ type: DISPLAY_STORES_LOADER });
        const userLocationCords = yield call(Location.getLatLongAsPromise, {
            useActualLocation: true,
        });
        if (userLocationCords.lat && userLocationCords.lng) {
            yield call(loadAkamaiStore, `${userLocationCords.lat},${userLocationCords.lng}`);
            const storeDetails = yield select(getGeoLocatedStore);
            if (storeDetails && storeDetails.storeId) {
                // Refreshing the content with the geo located store ID.
                yield put({
                    type: REFRESH_CONTENT,
                    refreshContent: true,
                });
                updateUrl(getPageURL());
            }
        }
        yield put({ type: HIDE_STORES_LOADER });
    } catch (error) {
        logger.error({
            errorMessage: 'Select store action failed - Unable to get user location',
            exception: error,
        });
        yield put({
            type: FETCH_STORES_ERROR,
            errorMessage: error,
        });
    }
}

export function* getNativeAppUserGeoLocation(updatePayload) {
    try {
        yield put({ type: DISPLAY_STORES_LOADER });
        const userLocationCords = updatePayload.payload;
        if (userLocationCords.lat && userLocationCords.lng) {
            yield call(loadAkamaiStore, `${userLocationCords.lat},${userLocationCords.lng}`);
            const storeDetails = yield select(getGeoLocatedStore);
            if (storeDetails && storeDetails.storeId) {
                // Refreshing the content with the geo located store ID.
                yield put({
                    type: REFRESH_CONTENT,
                    refreshContent: true,
                });
                updateUrl(getPageURL());
            }
        }
        yield put({ type: HIDE_STORES_LOADER });
    } catch (error) {
        logger.error({
            errorMessage: 'Select store action failed - Unable to get user location',
            exception: error,
        });
        yield put({
            type: FETCH_STORES_ERROR,
            errorMessage: error,
        });
    }
}

export function* updateSelectedStoreLocationIfo(updatePayload) {
    try {
        const { payload: { selectedStoreId = '', solrZipcode = '' } = {} } = updatePayload;
        if (selectedStoreId) {
            const response = yield call(fetchStores, selectedStoreId);

            if (response.status === 200) {
                const { data: { stores = [] } = {} } = response;
                if (stores.length) {
                    const userPreferredStore = yield call(
                        getUserPreferredStore,
                        stores[0],
                        solrZipcode
                    );
                    yield put(updateUserStoreLocationInfo(userPreferredStore));
                }
            }
        }
    } catch (error) {
        logger.error({
            errorMessage: 'updatedPLPSelectedStoreIfo failed',
            exception: error,
        });
        yield put({
            type: FETCH_STORES_ERROR,
            errorMessage: error,
        });
    }
}
const watchGalleryStoreRequest = function* watchGalleryStoreRequest() {
    yield takeLatest(FETCH_GALLERY_STORES, fetchGalleryStores);
};
watchGalleryStoreRequest.sagaName = 'watchGalleryStoreRequest';

const watchPrepopulateStores = function* watchPrepopulateStores() {
    yield takeLatest(PRE_POPULATE_GALLERY_STORES, prePopulateStores);
};
watchPrepopulateStores.sagaName = 'watchPrepopulateStores';

const watchGetUserGeoLocation = function* watchGetUserGeoLocation() {
    yield takeLatest(GET_USER_GEO_LOCATION, getUserGeoLocation);
};
watchGetUserGeoLocation.sagaName = 'watchGetUserGeoLocation';

const watchGetUserNativeGeoLocation = function* watchGetUserNativeGeoLocation() {
    yield takeLatest(GET_USER_NATIVE_GEO_LOCATION, getNativeAppUserGeoLocation);
};
watchGetUserNativeGeoLocation.sagaName = 'watchGetUserNativeGeoLocation';

const watchUpdateSelectedStoreLocationInfo = function* watchUpdateSelectedStoreLocationInfo() {
    yield takeLatest(UPDATE_SELECTED_STORE_LOCATION_INFO, updateSelectedStoreLocationIfo);
};
watchUpdateSelectedStoreLocationInfo.sagaName = 'watchUpdateSelectedStoreLocationInfo';

export {
    watchGalleryStoreRequest,
    watchPrepopulateStores,
    watchGetUserGeoLocation,
    watchUpdateSelectedStoreLocationInfo,
    watchGetUserNativeGeoLocation,
};
