import defaultWrapperRenderer from 'yoda-site-components/lib/components/IrisPage/defaultWrapperRenderer';
import renderCurrentComponent from 'yoda-site-components/lib/components/IrisPage/renderCurrentComponent';

export default (
    zone,
    zoneComponents,
    irisCompleteComponentList,
    irisComponentsWithoutGrid,
    propsData
) => {
    const componentsToRender = [...zoneComponents];
    const galleryTitleComponent = {
        componentType: 'gallerytitle',
    };

    const breadcrumbsIndex = zoneComponents.findIndex(
        (zoneComponent) => zoneComponent.componentType === 'breadcrumbs'
    );
    if (breadcrumbsIndex !== -1) {
        componentsToRender.splice(breadcrumbsIndex + 1, 0, galleryTitleComponent); // paste gallery title right after breadcrumbs
    } else {
        componentsToRender.unshift(galleryTitleComponent); // paste as a first element
    }

    return componentsToRender.map((component, index) => {
        // when API is returning BreadCrums without content
        if (component.componentType === 'breadcrumbs' && !component.content) {
            return null;
        }
        return defaultWrapperRenderer(
            renderCurrentComponent(
                component,
                index,
                irisCompleteComponentList,
                irisComponentsWithoutGrid,
                propsData
            )
        );
    });
};
