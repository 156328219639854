import React from 'react';
import FilterChips from '../components/FilterChips/FilterChips';
import { facetIds } from '../configurations/config';
import { getURLParameterBykey, getRandomId } from '.';

export const getStoreActiveTags = (facetValue, selectedStoresFilter, requestUrl) => {
    const selectedFacets = [];
    const storeIds = getURLParameterBykey('storeIds', requestUrl);
    // Logic to add store active tags when same day pickup is selected.
    if (facetValue.facetValId === facetIds.sameDayPickup && storeIds) {
        const selectedStores = storeIds.split('-');

        selectedStoresFilter.forEach((currentStore) => {
            const { id } = currentStore;
            if (selectedStores.includes(id)) {
                const storeFilter = {
                    ...currentStore,
                    facetName: 'Pickup',
                    facetType: 'checkbox',
                    storeTag: true,
                };
                selectedFacets.push(
                    <FilterChips filter={storeFilter} key={id} facetConfig={facetValue} />
                );
            }
        });
    }
    return selectedFacets;
};

export const getSelectedFacets = (category, selectedFilters) => {
    const selectedFacets = [];
    const selectedFacetList =
        selectedFilters &&
        selectedFilters.find((currentFilter) => currentFilter.facetName === category.facetName);
    if (category.facetValueList && selectedFacetList) {
        selectedFacetList.facetValueList.map((filter) => {
            let ele = '';
            if (filter.selected === true) {
                const selectedFilter = filter;
                selectedFilter.facetType = category.facetType;
                selectedFilter.facetName = category.facetName;
                ele = selectedFacets.push(<FilterChips filter={filter} key={getRandomId()} />);
            }
            return ele;
        });
    }
    return selectedFacets;
};
