import { GET_GALLERY_DETAILS_BY_ID_SUCCESS } from '../actionTypes/GalleryActionTypes';

export default function countOfSearchResultsReducer(state = 0, action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS:
            return action.galleryDetails &&
                action.galleryDetails.organicZoneInfo &&
                action.galleryDetails.organicZoneInfo.totalNumRecs &&
                action.galleryDetails.organicZoneInfo.products &&
                action.galleryDetails.organicZoneInfo.products.length > 0 // this is added in BR few cases solr is giving empty products but totalrec greater than zero
                ? action.galleryDetails.organicZoneInfo.totalNumRecs
                : 0;

        default:
            return state;
    }
}
