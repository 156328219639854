import { fork, all } from 'redux-saga/effects';
import siteComponentSagas from 'yoda-site-components/lib/sagas/index';
import watchIrisRequest from 'yoda-site-components/lib/sagas/IrisSaga';
import watchcartItemUpdateSaga from 'yoda-checkout-components/lib/sagas/CartItemUpdateSaga';
import watchCouponListSaga from 'yoda-site-components/lib/sagas/CouponListSaga';
import watchGalleryDetailsRequest, { watchInitiatePendingActionsRequest } from './GallerySaga';
import watchGalleryEcoRebatesSaga from './EcoRebatesSaga';
/* import watchGalleryCriteoRequest, {
    watchCriteoBeacon,
    watchCriteoMergeProductsSage,
} from './CriteoSaga'; */
import {
    watchGalleryStoreRequest,
    watchPrepopulateStores,
    watchGetUserGeoLocation,
    watchUpdateSelectedStoreLocationInfo,
    watchGetUserNativeGeoLocation,
} from './BOPISSaga';

const siteComponentSagaList = Object.values(siteComponentSagas.injectedSagas);

const gallerySagas = [
    /**
     * added for get the details from search API
     * yoda-microsite-gallery/src/containers/Pages/Iris/IrisWrapper.jsx component
     * yoda-microsite-gallery/src/containers/Pages/Gallery/GalleryWrapper.jsx component
     * yoda-microsite-gallery/src/containers/Pages/Search/SearchWrapper.jsx component
     */
    watchGalleryDetailsRequest,
    /**
     * added for get the details from search API
     * yoda-microsite-gallery/src/containers/Pages/Iris/IrisWrapper.jsx component
     * yoda-microsite-gallery/src/containers/Pages/Gallery/GalleryWrapper.jsx component
     * yoda-microsite-gallery/src/containers/Pages/Search/SearchWrapper.jsx component
     */
    watchInitiatePendingActionsRequest,
    /**
     * added for get eco rebates Details
     * src/sagas/GallerySaga.js saga
     */
    watchGalleryEcoRebatesSaga,
    /**
     * added for get the data for criteo products
     * yoda-microsite-gallery/src/components/CriteoZone/CriteoZone.jsx component
     */
    // watchGalleryCriteoRequest,
    /**
     * added for beacons analytics for criteo products on image click and image view
     * yoda-microsite-gallery/src/components/GalleryProductCard/GalleryProductCard.jsx component
     */
    // watchCriteoBeacon,
    /**
     * added for merge criteoProducts and organic products
     * yoda-microsite-gallery/src/sagas/CriteoSaga.js saga
     * CriteoMergeProductsSaga() method
     * yoda-microsite-gallery/src/reducers/ProductListReducer.js  reducer to merge products
     */
    // watchCriteoMergeProductsSage,
    /**
     * added for get store Details on button Click of BOPIS hence can be lazy loaded
     * yoda-microsite-gallery/src/components/FindAStore/FindAStore.jsx component
     * fetchGalleryStores action type
     * yoda-microsite-gallery/src/sagas/BOPISSaga.js to initiate saga
     */
    watchGalleryStoreRequest,
    /**
     * added for pre Prepopulate store which comes in BOPIS
     * yoda-microsite-gallery/src/components/StoresQuickInfo/StoresQuickInfo.jsx component
     * prePopulateStores action type
     * yoda-microsite-gallery/src/sagas/BOPISSaga.js to initiate saga
     */
    watchPrepopulateStores,
    /**
     * added for getting user location on toolTip can be lazyLoaded because
     * yoda-microsite-gallery/src/components/StoresQuickInfo/StoresQuickInfo.jsx component
     * prePopulateStores action type
     * yoda-microsite-gallery/src/sagas/BOPISSaga.js to initiate saga
     */
    watchcartItemUpdateSaga,
    /**
     * added for ATC actions for mini PDP
     */
    watchGetUserGeoLocation,
    watchGetUserNativeGeoLocation,

    /**
     * added for updating selected store location info on StoreQuickInfoSingleSelect component
     * yoda-microsite-gallery/src/components/StoresQuickInfo/StoreQuickInfoSingleSelect.jsx component
     * yoda-microsite-gallery/src/sagas/BOPISSaga.js to initiate saga
     */

    watchUpdateSelectedStoreLocationInfo,

    watchCouponListSaga,
    watchIrisRequest,

    /**
     * saved item
     * yoda-microsite-pdp/src/components/AddToList/AddToList.jsx
     * yoda-microsite-gallery/src/AmpComponents/AMPGalleryProductCard/AMPGalleryProductCard.jsx
     * yoda-microsite-gallery/src/components/GalleryProductCard/GalleryProductCard.jsx
     */
    ...siteComponentSagaList,
];

const injectedSagas = {};
for (const saga of gallerySagas) { // eslint-disable-line
    // 1. Check that every saga has a name
    if (!saga.sagaName) {
        throw new Error(
            `Every saga has to have sagaName property. For debug purposes: ${saga.toString()}`
        );
    }

    // 2. Check that we have only unique sagas (one could be from components, another the same one from shared)
    if (injectedSagas[saga.sagaName]) {
        throw new Error(
            `That saga ${saga.sagaName} was already registered. Only 1 instance for particular name is allowed`
        );
    }
    injectedSagas[saga.sagaName] = saga;
}

const galleryRootSaga = function* galleryRootSaga() {
    yield all(Object.values(injectedSagas).map((saga) => fork(saga)));
};

galleryRootSaga.injectedSagas = injectedSagas;

export default galleryRootSaga;
