import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bool, object, objectOf } from 'prop-types';
import MessageBox from 'yoda-core-components/lib/components/MessageBox';
import { isBrandPage } from '../../utils';

export class TimeoutError extends PureComponent {
    static defaultProps = {
        isTimeoutError: false,
        messagesTexts: {},
    };

    static propTypes = {
        isTimeoutError: bool,
        messagesTexts: objectOf(object),
    };

    componentDidUpdate() {
        const { isTimeoutError } = this.props;

        if (isTimeoutError && this.timeoutMessageContainer) {
            this.timeoutMessageContainer.scrollIntoView();
        }
    }

    render() {
        const {
            isTimeoutError,
            messagesTexts: { galleryPageLevelErrors: { timeoutError = '' } = {} } = {},
        } = this.props;
        return isBrandPage() ? (
            <div />
        ) : (
            <div
                ref={(timeoutMessageContainer) => {
                    this.timeoutMessageContainer = timeoutMessageContainer;
                }}
            >
                <MessageBox
                    message={timeoutError}
                    messageType="error"
                    showMessage={isTimeoutError}
                />
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    isTimeoutError: store.isTimeoutError,
    messagesTexts: store.context ? store.context.messagesTexts : {},
});

export default connect(mapStateToProps)(TimeoutError);
