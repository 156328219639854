/* eslint-disable import/no-named-as-default */
import LabelZone from '../../components/LabelledZone/IrisLabelledZoneLazy';
import BreadCrumbs from '../../components/GalleryBreadCrumbs/GalleryBreadCrumbs';
import VisNav from '../../components/VisNav/VisNav';
import SearchAltMessage from '../../components/SearchAltMessage/IrisSearchAltMessageLazy';
import SeoMetaData from '../../components/SeoMetaData/SeoMetaData';
import Seo from '../../components/Seo/IrisSeoLazy';
import InteractiveVizNav from '../../components/InteractiveVizNav/IrisInteractiveVizNavLazy';
import GalleryTitle from '../../components/GalleryTitle/GalleryTitle';

export default {
    gallerytitle: {
        component: GalleryTitle,
    },
    labeledzone: {
        component: LabelZone,
    },
    breadcrumbs: {
        component: BreadCrumbs,
    },
    interactivevisnav: {
        component: InteractiveVizNav,
    },
    visualnav: {
        component: VisNav,
    },
    dhtml: {
        component: SearchAltMessage,
    },
    dhtmlrte: {
        component: SearchAltMessage,
    },
    seotag: {
        // this is meta tag
        component: SeoMetaData,
    },
    seoblock: {
        component: Seo,
    },
};
