export const CLOSE_GALLERY_DETAILS_TIMEOUT_ERROR = 'CLOSE_GALLERY_DETAILS_TIMEOUT_ERROR';
export const GET_FILTERS_ERROR = 'GET_FILTERS_ERROR';
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS';
export const GET_GALLERY_CONTENT_BY_ID_ERROR = 'GET_GALLERY_CONTENT_BY_ID_ERROR';
export const GET_GALLERY_CONTENT_BY_ID_REQUEST = 'GET_GALLERY_CONTENT_BY_ID_REQUEST';
export const GET_GALLERY_CONTENT_BY_ID_SUCCESS = 'GET_GALLERY_CONTENT_BY_ID_SUCCESS';
export const GET_GALLERY_DETAILS_BY_ID_ERROR = 'GET_GALLERY_DETAILS_BY_ID_ERROR';
export const GET_GALLERY_DETAILS_BY_ID_REQUEST = 'GET_GALLERY_DETAILS_BY_ID_REQUEST';
export const GET_GALLERY_DETAILS_BY_ID_SUCCESS = 'GET_GALLERY_DETAILS_BY_ID_SUCCESS';
export const GET_GALLERY_DETAILS_TIMEOUT_ERROR = 'GET_GALLERY_DETAILS_TIMEOUT_ERROR';
export const GET_PRODUCT_LIST_ERROR = 'GET_PRODUCT_LIST_ERROR';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_STORE_NULL_RESULT_PAGE = 'GET_STORE_NULL_RESULT_PAGE';
export const INITIATE_PENDING_ACTIONS = 'INITIATE_PENDING_ACTIONS';
export const JS_CLEAR_ALT_MESSAGE = 'JS_CLEAR_ALT_MESSAGE';
export const LAST_GALLERY_ACTION_RESULT = 'LAST_GALLERY_ACTION_RESULT';
export const MERGE_PRODUCTS = 'MERGE_PRODUCTS';
export const PREV_NO_RESULTS = 'PREV_NO_RESULTS';
export const RESET_COMMON_DATA = 'RESET_COMMON_DATA';
export const SET_COMMON_DATA = 'SET_COMMON_DATA';
export const SET_FAILOVER_CANONICAL_INFO = 'SET_FAILOVER_CANONICAL_INFO';
export const SPONSORED_DISPLAY_PAGE = 'SPONSORED_DISPLAY_PAGE';
