import RERENDER_CERTONA from '../actionTypes/CertonaActionTypes';

const defaultState = {
    reRenderCertona: false,
};
export default function reRenderCertonaReducer(state = defaultState, action) {
    // NOSONAR
    switch (action.type) {
        case RERENDER_CERTONA: {
            return { ...state, reRenderCertona: action.payload.reRenderCertona };
        }

        default:
            return state;
    }
}
