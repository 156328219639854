import {
    BOPIS_GALLERY_CHANGE_ZIP,
    FETCH_GALLERY_STORES,
    FETCH_STORES_ERROR,
    GET_USER_GEO_LOCATION,
    PRE_POPULATE_GALLERY_STORES,
    UPDATE_SELECTED_STORE_LOCATION_INFO,
    ENABLE_PRE_POPULATE_GALLERY_STORES,
    GET_USER_NATIVE_GEO_LOCATION,
} from '../actionTypes/BOPISActionTypes';

export const fetchGalleryStores = (payload) => ({
    payload,
    type: FETCH_GALLERY_STORES,
});

export const prePopulateStores = (payload) => ({
    payload,
    type: PRE_POPULATE_GALLERY_STORES,
});

export const enablePrePopulateStores = (payload) => ({
    payload,
    type: ENABLE_PRE_POPULATE_GALLERY_STORES,
});

export const setBOPISErrorMessage = (payload) => ({
    errorMessage: payload.errorMessage,
    params: payload.params,
    reset: payload.reset,
    type: FETCH_STORES_ERROR,
});

export const bopisGalleryChangeZip = () => ({
    type: BOPIS_GALLERY_CHANGE_ZIP,
});

export const getUserGeoLocation = () => ({
    type: GET_USER_GEO_LOCATION,
});

export const getUserNativeGeoLocation = (payload) => ({
    payload,
    type: GET_USER_NATIVE_GEO_LOCATION,
});

export const updateSelectedStoreLocationInfo = (payload) => ({
    payload,
    type: UPDATE_SELECTED_STORE_LOCATION_INFO,
});
