import React from 'react';
import { array, bool, object, oneOfType, string } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import BreadCrumbsNav from 'yoda-site-components/lib/components/BreadCrumbNav/BreadCrumb';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './GalleryBreadCrumbs.css';
import getBreadCrumbInfo from '../../selectors/BreadCrumbs';
import {
    getBreadcrumbsTagBrPreview,
    selectEnableBrPreviewGallerySkeleton,
} from '../../selectors/BloomreachSelector';
import { selectHideBreadCrumbsWhenNoNavigation } from '../../selectors/fromState';
import selectNoSearchResults from '../../selectors/NoSearchResults';
import { hasBreadCrumbLinks } from '../../helpers/GalleryHelper';
import { getPageType } from '../../utils';

const cx = classNames.bind(styles);

const breadCrumbsClasses = `${dt([
    'align-middle',
    'inline-block',
    'text-left',
    'smOnly:overflow-auto',
    'smOnly:w-full',
])} ${cx('breadCrumbs')}`;

export const GalleryBreadCrumbs = ({
    breadCrumbInfo,
    hideBreadCrumbsWhenNoNavigation,
    deviceType,
    className,
    enableBrPreviewGallerySkeleton,
    bloomReachPreviewShowMockData,
    breadCrumbInfoBrPreview,
}) => {
    const renderBreadCrumbSkeleton =
        enableBrPreviewGallerySkeleton && bloomReachPreviewShowMockData;
    const breadCrumbsToEnable = renderBreadCrumbSkeleton ? breadCrumbInfoBrPreview : breadCrumbInfo;
    const nullBreadCrumbs = () => {
        if (renderBreadCrumbSkeleton && getPageType() === 'g') {
            return (
                <div
                    className={dt([
                        'sm:min-h-[20px]',
                        'bg-light-white',
                        'max-w-[165px]',
                        'w-full',
                        'border',
                        'border-solid',
                        'border-gray-15',
                        'mb-6',
                    ])}
                    data-automation-id="product-breadcrumbs"
                />
            );
        }
        return null;
    };

    return hasBreadCrumbLinks(breadCrumbsToEnable, deviceType, hideBreadCrumbsWhenNoNavigation) ? (
        <div
            className={`${dt(['w-full', 'sm:smOnly:overflow-auto smOnly:w-fullmin-h-[30px]'])} ${cx(
                'breadcrumb-container'
            )} ${className}`}
            data-automation-id="product-breadcrumbs"
        >
            <div className={breadCrumbsClasses}>
                <BreadCrumbsNav breadCrumbs={breadCrumbInfo} />
            </div>
        </div>
    ) : (
        nullBreadCrumbs()
    );
};

GalleryBreadCrumbs.propTypes = {
    breadCrumbInfo: oneOfType([array, object]),
    deviceType: object,
    hideBreadCrumbsWhenNoNavigation: bool,
    className: string,
    enableBrPreviewGallerySkeleton: bool,
    bloomReachPreviewShowMockData: bool,
    breadCrumbInfoBrPreview: oneOfType([array, object]),
};

GalleryBreadCrumbs.defaultProps = {
    breadCrumbInfo: [],
    deviceType: {},
    hideBreadCrumbsWhenNoNavigation: false,
    className: '',
    enableBrPreviewGallerySkeleton: false,
    breadCrumbInfoBrPreview: [],
    bloomReachPreviewShowMockData: false,
};

const mapStateToProps = (state, props) => ({
    breadCrumbInfo: state.context && !state.context.isNative ? getBreadCrumbInfo(state, props) : [],
    countOfSearchResults: state.countOfSearchResults,
    deviceType: state.context ? state.context.deviceType : {},
    noResults: selectNoSearchResults(state),
    hideBreadCrumbsWhenNoNavigation: selectHideBreadCrumbsWhenNoNavigation(state),
    enableBrPreviewGallerySkeleton: selectEnableBrPreviewGallerySkeleton(state),
    bloomReachPreviewShowMockData: state?.commonData?.showBRPreviewMock || false,
    breadCrumbInfoBrPreview:
        state.context && !state.context.isNative ? getBreadcrumbsTagBrPreview(state, props) : [],
});

export default connect(mapStateToProps)(GalleryBreadCrumbs);
