import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './ProductsLoader.css';

const cx = classNames.bind(styles);

const ProductSkeleton = ({ className }) => {
    const prodLoader = dt([
        'bg-light-white',
        'border',
        'border-solid',
        'border-gray-15',
        'overflow-hidden',
        'relative',
    ]);

    return <div className={cx(prodLoader, 'loading', className)} />;
};

ProductSkeleton.defaultProps = {
    className: '',
};

ProductSkeleton.propTypes = {
    className: string,
};

export default ProductSkeleton;
