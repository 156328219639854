const config = {
    new: {
        text: 'New',
        theme: 'red',
    },
    collection: {
        text: 'Collection',
        theme: 'mediumBlue',
    },
    fewleft: {
        text: 'Few Left',
        theme: 'black',
    },
    onlineonly: {
        text: 'Online Only',
        theme: 'neutralBlue',
    },
    preorder: {
        text: 'Pre-Order',
        theme: 'green',
    },
    bundle: {
        text: '',
        type: 'bundle',
    },
    default: {
        text: '',
        theme: 'black',
    },
};

export default config;
