import {
    DYNAMIC_GALLERIES_ITEM_CLICK,
    DYNAMIC_GALLERIES_LOAD_SUCCESS,
    DYNAMIC_GALLERIES_MODULE_CLICK,
    DYNAMIC_VISNAV_CLICK,
    GALLERY_VIDEO_PLAYER_ANALYTICS,
    ON_PAGE_READY,
    PRODUCT_FILTERS_APPLIED,
    PROMOTED_FILTERS_APPLIED,
    PROMOTED_FILTERS_RESET,
    SEARCH_RESULT_CLICK,
    TRIGGER_ANALYTICS_RESET,
    TRIGGER_STORE_SEARCH_EVENT,
    BOPIS_DEFAULT_STORE_CHANGE,
} from '../actionTypes/AnalyticsActionTypes';

export const dynamicGalleriesItemClick = (payload) => ({
    type: DYNAMIC_GALLERIES_ITEM_CLICK,
    payload,
});

export const dynamicGalleriesLoadSuccess = (payload) => ({
    type: DYNAMIC_GALLERIES_LOAD_SUCCESS,
    payload,
});

export const dynamicGalleriesModuleClick = (payload) => ({
    type: DYNAMIC_GALLERIES_MODULE_CLICK,
    payload,
});

export const searchResultClick = (payload) => ({
    type: SEARCH_RESULT_CLICK,
    payload,
});

export const promotedFilterChange = (payload) => ({
    type: PROMOTED_FILTERS_APPLIED,
    payload,
});

export const promotedFilterReset = (payload) => ({
    type: PROMOTED_FILTERS_RESET,
    payload,
});

export const productFilterApplied = (payload) => ({
    type: PRODUCT_FILTERS_APPLIED,
    payload,
});

export const onPageReady = (payload) => ({
    type: ON_PAGE_READY,
    payload,
});

export const analyticsReset = (payload) => ({
    type: TRIGGER_ANALYTICS_RESET,
    payload,
});

export const videoPlayerAnalytics = (payload) => ({
    type: GALLERY_VIDEO_PLAYER_ANALYTICS,
    payload,
});

export const triggerStoreSearchEvent = (searchText) => ({
    type: TRIGGER_STORE_SEARCH_EVENT,
    searchText,
});

export const bopisDefaultStoreChange = () => ({
    type: BOPIS_DEFAULT_STORE_CHANGE,
});

export const dynamicVisNavClicked = (payload) => ({
    type: DYNAMIC_VISNAV_CLICK,
    payload,
});
