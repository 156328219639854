/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import {
    arrayOf,
    array,
    bool,
    element,
    func,
    object,
    objectOf,
    oneOfType,
    shape,
    string,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import _get from 'lodash/get';
import Switch from 'yoda-core-components/lib/components/Switch/Switch';
import { setFilterSelection } from 'yoda-site-components/lib/actions/SelectionFilterAction';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './CustomFacet.css';
import {
    getRandomId,
    removeStoreIdsFromUrl,
    updateQueryStringParameter,
    updateUrl,
} from '../../../../utils';
import { filterCategoryPlaceHolder } from '../../../../configurations/config';
import { promotedFilterChange } from '../../../../actions/AnalyticsActions';
import { fetchGalleryStores } from '../../../../actions/BOPISActions';
import { BOPISErrors } from '../../../../common/constants';

const cx = classNames.bind(styles);

export const Facet = ({
    actions,
    buttonClasses,
    config,
    config: { facetId, facetValueList = [] },
    considerStore,
    disable,
    facetConfig: { apiUrl, displayName, facetName, isStoreSelected, name, selected },
    locationService: { inputZipCode },
    inSlider,
    labelContainerClass,
    onFacetChange,
    placeHolder,
    preferences,
    storesList,
    wrapperClass,
}) => {
    const selectedChxb = useRef();
    useEffect(() => {
        selectedChxb.current = selected;
    }, [selected]);

    const isFilterPanel = placeHolder === filterCategoryPlaceHolder.filterPanel;
    const automationId = isFilterPanel ? 'filter-checkbox' : 'promoted-checkbox';

    const checkboxConfig = {
        checked: selectedChxb.current,
        id: getRandomId(),
        value: updateQueryStringParameter(apiUrl, 'activeFacetId', facetId),
    };

    const hasProduct = () => {
        if (!considerStore || selectedChxb.current) return !!config.count; // selected

        const store = storesList.filter(
            ({ id }) => parseInt(id, 10) === parseInt(considerStore.id, 10)
        )[0];

        return !!(store ? store.productCount : config.count);
    };

    const facetChanged = (event) => {
        if (onFacetChange) {
            /**
             * The Switch component is wrapping the event object inside
             * of another event object and adding a value property, but
             * because both the onClick and onChange event handlers use
             * this event this check has to be in place to ensure the
             * actual event object is being acted on.
             * TODO: refactor Switch to not manipulate the event object
             */
            if (event.event) {
                event.event.preventDefault();
                onFacetChange();
            } else {
                event.preventDefault();
                onFacetChange(event);
            }
        } else {
            const bopisPreferences = _get(preferences, 'bopis', {});
            const bopisDefaultRadius = _get(bopisPreferences, 'radius', 75);
            const noResultsMsg = _get(bopisPreferences, 'noResults', BOPISErrors.noResults);
            let newUrl = checkboxConfig.value;
            const isPromotedFilterSelected = !isFilterPanel && !selectedChxb.current;
            const facetValue =
                (isStoreSelected && displayName.toLowerCase()) ||
                name ||
                (facetValueList.length && facetValueList[0].name);

            newUrl = removeStoreIdsFromUrl(newUrl, config);

            selectedChxb.current = newUrl.includes('storeIds');

            actions.promotedFilterChange({
                value: isPromotedFilterSelected ? config.facetName.toLowerCase() : '',
            });
            /* istanbul ignore next */
            updateUrl(newUrl, null, null, {
                action: selectedChxb.current ? 'remove' : 'add', // selected
                name: isStoreSelected && facetName ? facetName : config.facetName,
                value: facetValue,
                taxonomyKey: facetName === 'same day pickup' ? facetName : '',
            });

            if (!hasProduct() && considerStore && considerStore.zip) {
                actions.fetchGalleryStores({
                    formError: noResultsMsg,
                    miles: bopisDefaultRadius,
                    useActualLocation: true,
                    zipCode: inputZipCode,
                });
            }
        }
    };

    const facetConfigButtonStyles = dt(['flex', 'mx-2', 'text-left', 'smOnly:pl-3', 'smOnly:mx-0']);
    const facetWrapperStyles = dt(['lg:block', 'lg:mr-0', 'lg:w-full']);
    const facetConfig = {
        button: {
            className: cx(facetConfigButtonStyles, buttonClasses),
            onClick: (event) => facetChanged(event),
        },
        switch: {
            automationId,
            config: checkboxConfig,
            disabled: disable || !hasProduct(),
            enableFastClick: true,
            label: null,
            labelClass: `${dt(['font-open-sans-bold'])} ${cx('facet-label')}`,
            labelContainerClass,
            onChange: (event) => facetChanged(event),
        },
        wrapper: {
            className: `${dt(['flex', 'flex-0', 'whitespace-nowrap'])} ${cx({
                facetWrapperStyles,
                slider: inSlider,
                wrapperClass,
            })}`,
        },
    };

    return (
        <div {...facetConfig.wrapper}>
            <Switch {...facetConfig.switch} />
            <button {...facetConfig.button} type="button">
                {name}
            </button>
        </div>
    );
};

Facet.defaultProps = {
    buttonClasses: '',
    considerStore: { id: '', zip: '' },
    disable: false,
    inSlider: false,
    labelContainerClass: '',
    onFacetChange: null,
    storesList: [{}],
    wrapperClass: '',
};

Facet.propTypes = {
    actions: objectOf(func).isRequired,
    buttonClasses: string,
    config: oneOfType([object, func]).isRequired,
    facetConfig: shape({
        apiUrl: string,
        displayName: string,
        facetName: string,
        isStoreSelected: bool,
        name: oneOfType([element, string]),
        selected: bool,
    }).isRequired,
    considerStore: shape({ id: string, zip: string }),
    disable: bool,
    featureFlags: objectOf(bool).isRequired,
    preferences: oneOfType([array, object]).isRequired,
    inSlider: bool,
    labelContainerClass: string,
    locationService: oneOfType([object, string]).isRequired,
    onFacetChange: func,
    placeHolder: string.isRequired,
    storesList: arrayOf(object),
    wrapperClass: string,
};

const mapStateToProps = ({
    bopisInfo,
    locationServiceReducer: locationService = {},
    preferences = {},
}) => ({
    selectedStoresFilter: bopisInfo.selectedStores,
    sortedStoresList: bopisInfo.sortedStoresList,
    storeParameters: bopisInfo.params,
    locationService,
    preferences,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        { promotedFilterChange, setFilterSelection, fetchGalleryStores },
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Facet);
