export const staticSuffix = 'gallery'; // eslint-disable-line
export const micrositeName = 'yoda-microsite-gallery';
export const cachePolicy = {
    version: 1,
    enabled: true,
    minCache: 86400, // page cache for 4 hours
    routeCache: {
        // Not supported yet
        match: '/g',
        minCache: 0,
        enabled: false,
    },
};

export const app = {
    logTag: 'MICROSITE:GALLERY',
};
export const configServerSettingsFlag = true;
export const shallowFirstServerRendering = true;
export const hasAmpVersion = false;
export const isContextHeadersRequired = false;
