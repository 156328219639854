import React from 'react';
import Button from 'yoda-core-components/lib/components/Button';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { getURLParameterBykey } from '../../utils';

const brandConfig = {
    brandParamName: 'brand',
};

const brandName = getURLParameterBykey(brandConfig.brandParamName);

const config = {
    headerMessage: "Its Not You, it's Us",
    refreshButtonName: 'Refresh The Page',
    message: `Something went wrong loading products for ${brandName}. We hope you’ll give us another chance.`,
};

const refresh = () => {
    if (window && window.location) {
        return window.location.reload();
    }
    return null;
};
const NoBrandPageResults = () => {
    const mblStyles = dt([
        'mt-6',
        'text-center',
        'mx-auto',
        'sm:w-[332px]',
        'md:w-[352px]',
        'lg:w-[608px]',
    ]);
    return (
        <div className={mblStyles}>
            <div className={dt(['font-open-sans-semibold', 'mb-4', 'text-black', 'text-xlarge'])}>
                {config.headerMessage}
            </div>
            <div className={dt(['font-open-sans-regular', 'text-black', 'text-base'])}>
                {config.message}
            </div>
            <div className={dt(['m-10', 'mx-0', 'grid', 'justify-items-center'])}>
                <Button
                    automationId="no results refresh button"
                    onClick={refresh}
                    className={dt(['sm:w-full', 'md:md6', 'lg:w-[300px]'])}
                >
                    {config.refreshButtonName}
                </Button>
            </div>
        </div>
    );
};

export default NoBrandPageResults;
