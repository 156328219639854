import { UPDATE_VIEW_TYPE } from '../actionTypes/ProductListActionTypes';
import { defaultViewType } from '../configurations/config';

const defaultState = { viewType: defaultViewType, isFacetOpened: true };

export default function productListSettingsReducer(state = defaultState, action) {
    // NOSONAR
    switch (action.type) {
        case UPDATE_VIEW_TYPE:
            return {
                viewType: action.payload.viewType,
                isFacetOpened: state.isFacetOpened,
            };
        default:
            return state;
    }
}
