export const BOPIS_GALLERY_CHANGE_ZIP = 'BOPIS_GALLERY_CHANGE_ZIP';
export const DISABLE_SELECT_STORE = 'DISABLE_SELECT_STORE';
export const DISPLAY_STORES_LOADER = 'DISPLAY_STORES_LOADER';
export const FETCH_GALLERY_STORES = 'FETCH_GALLERY_STORES';
export const FETCH_MORE_GALLERY_STORES = 'FETCH_MORE_GALLERY_STORES';
export const FETCH_STORES_ERROR = 'FETCH_STORES_ERROR';
export const FETCH_STORES_ERROR_ZERO_PRODUCT = 'FETCH_STORES_ERROR_ZERO_PRODUCT';
export const GALLERY_STORES_SUCCESS = 'GALLERY_STORES_SUCCESS';
export const GET_AND_PRESELECT_GALLERY_STORES = 'GET_AND_PRESELECT_GALLERY_STORES';
export const GET_USER_GEO_LOCATION = 'GET_USER_GEO_LOCATION';
export const HIDE_STORES_LOADER = 'HIDE_STORES_LOADER';
export const PRE_POPULATE_GALLERY_STORES = 'PRE_POPULATE_GALLERY_STORES';
export const PRE_POPULATE_STORES = 'PRE_POPULATE_STORES';
export const RESET_GALLERY_STORES = 'RESET_GALLERY_STORES';
export const SET_CURRENT_STORES = 'SET_CURRENT_STORES';
export const UPDATE_SELECTED_STORE_LOCATION_INFO = 'UPDATE_SELECTED_STORE_LOCATION_INFO';
export const ENABLE_PRE_POPULATE_GALLERY_STORES = 'ENABLE_PRE_POPULATE_GALLERY_STORES';
export const GET_USER_NATIVE_GEO_LOCATION = 'GET_USER_NATIVE_GEO_LOCATION';
