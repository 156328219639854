import _size from 'lodash/size';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { getEcoRebates } from 'yoda-interfaces/lib/EcoRebates/EcoRebatesApi';
import RestClient from 'yoda-interfaces/lib/RestClient/RestClient';
import getPreviewDate from 'yoda-site-components/lib/selectors/PreviewDateSelector';
import DateFormatter from 'yoda-site-components/lib/dates/DateFormatter/DateFormatter';
import {
    ECOREBATES_GET_SUCCESS,
    ECOREBATES_GET_ERROR,
    ECOREBATES_GET_REQUEST,
} from '../actionTypes/EcoRebatesActionTypes';
import Application from '../helpers/Application';

const logger = Application.getLogger('saga');

const getProducts = (state) => state.products;

function* EcoRebatesSaga() {
    try {
        const products = yield select(getProducts);

        if (_size(products)) {
            const productSkus = [];
            products.map((product) => {
                if (product.ecoRebate && product.skuId) {
                    productSkus.push(product.skuId);
                }
                return false;
            });
            if (productSkus.length > 0) {
                const context = RestClient.getContext();
                const additionalAttr = {};
                /* istanbul ignore next */
                if (context && context.isPreview) {
                    const date = yield select(getPreviewDate);
                    additionalAttr.appendQueryParams =
                        date && typeof date === 'object' && date.format
                            ? {
                                  previewDate: DateFormatter.formatWithOptions(
                                      new Date(date.format('MM.DD.YYYY')),
                                      '-'
                                  ),
                              }
                            : { previewDate: date };
                }
                const rebatesInfo = yield call(getEcoRebates, {
                    itemsWithRebates: productSkus.join(),
                    uiContext: 'PLP',
                    ...additionalAttr,
                });
                yield put({ type: ECOREBATES_GET_SUCCESS, rebatesInfo: rebatesInfo.data });
            }
        }
    } catch (error) {
        logger.error({
            errorMessage: 'GalleryPage EcoRebates API call failed',
            exception: error,
        });
        yield put({ type: ECOREBATES_GET_ERROR, error });
    }
}

const watchGalleryEcoRebatesSaga = function* watchGalleryEcoRebatesSaga() {
    yield takeLatest(ECOREBATES_GET_REQUEST, EcoRebatesSaga);
};
watchGalleryEcoRebatesSaga.sagaName = 'watchGalleryEcoRebatesSaga';

export default watchGalleryEcoRebatesSaga;
