import * as AnalyticsActionTypes from '../actionTypes/AnalyticsActionTypes';
import * as GalleryActionTypes from '../actionTypes/GalleryActionTypes';
import * as IrisActionTypes from '../actionTypes/GalleryIrisActionTypes';

const defaultState = { content: false, details: false };
export default function analyticsReducer(state = defaultState, action) {
    // NOSONAR
    switch (action.type) {
        case GalleryActionTypes.GET_GALLERY_DETAILS_BY_ID_SUCCESS: {
            return { ...state, details: true };
        }
        case AnalyticsActionTypes.TRIGGER_ANALYTICS_RESET:
            return { content: false, details: false };
        case IrisActionTypes.GET_GALLERY_IRIS_CONTENT_BY_ID_SUCCESS: {
            return { ...state, content: true };
        }
        default:
            return state;
    }
}
