import {
    GET_GALLERY_IRIS_CONTENT_BY_ID_SUCCESS,
    GET_GALLERY_IRIS_GRID_CONTENT,
    GET_GALLERY_IRIS_SEO_CONTENT,
    IRIS_INITIAL_CALL_MODE,
    SET_IRIS_ALT_MESSAGE,
} from '../actionTypes/GalleryIrisActionTypes';

export const getGalleryGridContentWithIris = (payload) => ({
    type: GET_GALLERY_IRIS_GRID_CONTENT,
    payload,
});

export const getSeoContentWithIris = (payload) => ({
    type: GET_GALLERY_IRIS_SEO_CONTENT,
    payload,
});

export const getGalleryContentWithIris = (payload) => ({
    type: GET_GALLERY_IRIS_CONTENT_BY_ID_SUCCESS,
    payload,
});

export const setGalleryAltMessageContentWithIris = (payload) => ({
    type: SET_IRIS_ALT_MESSAGE,
    payload,
});

export const setInitialIrisRequestMode = (payload) => ({
    type: IRIS_INITIAL_CALL_MODE,
    payload,
});
