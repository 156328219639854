import { createSelector } from 'reselect';
import {
    selectH1Tag,
    selectSeoMetaDataFromIris,
    selectCategoryNameFromSolar,
    selectSeoTag,
} from './fromState';
import { getIrisFallBackStatus } from './IrisFailure';
import { transformSeoTagsInfoFromIris } from '../utils/irisDataProcesserFunction';

/*
this selector is to select value from coreZone in case when data is fetched from search API.
and when data is directly fected from iris.
*/

export const getH1Tag = createSelector(
    [getIrisFallBackStatus, selectCategoryNameFromSolar, selectH1Tag],
    (irisFallBackStatus, categoryName, irisH1Tag) => {
        if (irisFallBackStatus) {
            return categoryName;
        }
        return irisH1Tag;
    }
);

export const getSeoTag = createSelector(
    [selectSeoMetaDataFromIris, selectSeoTag],
    (seoMetaDatafromIris = {}, seoTag = {}) => {
        return { ...transformSeoTagsInfoFromIris(seoMetaDatafromIris), ...seoTag };
    }
);

export const getAutoCorrectedMessage = createSelector([getSeoTag], (seoMetaDatafromIris = {}) => {
    return seoMetaDatafromIris.autoCorrectedMessage || '';
});
