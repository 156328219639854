import { OPEN_SLIDER, CLOSE_SLIDER, TOGGLE_SLIDER } from '../actionTypes/SliderActionTypes';

export default function loaderReducer(state = false, action) {
    // NOSONAR
    switch (action.type) {
        case OPEN_SLIDER:
            return true;
        case CLOSE_SLIDER:
            return false;
        case TOGGLE_SLIDER:
            return !state;
        default:
            return state;
    }
}
