/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { bool, string } from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import { isControlsEnabled } from '../../utils';
import styles from './GalleryLoader.css';

const GalleryLoader = (props) => {
    const { enableLoader, loaderClass, showLoader } = props;
    const enableControls = isControlsEnabled();
    const displayLoader = showLoader || (!enableControls && enableLoader);
    const loaderConfig = {
        automationId: 'test-automation-loader-1',
        keepOverlay: true,
        loaderClass: [styles.loader, loaderClass].join(' '),
    };

    return <>{displayLoader ? <Loader {...loaderConfig} /> : null}</>;
};

GalleryLoader.defaultProps = {
    enableLoader: false,
    loaderClass: null,
    showLoader: false,
};

GalleryLoader.propTypes = {
    enableLoader: bool,
    loaderClass: string,
    showLoader: bool,
};

const mapStateToProps = (store) => ({
    enableLoader: store.enableLoader,
});

export default connect(mapStateToProps)(GalleryLoader);
