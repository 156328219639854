export const DYNAMIC_GALLERIES_ITEM_CLICK = 'DYNAMIC_GALLERIES_ITEM_CLICK';
export const DYNAMIC_GALLERIES_LOAD_SUCCESS = 'DYNAMIC_GALLERIES_LOAD_SUCCESS';
export const DYNAMIC_GALLERIES_MODULE_CLICK = 'DYNAMIC_GALLERIES_MODULE_CLICK';
export const DYNAMIC_VISNAV_CLICK = 'DYNAMICVISNAV_CLICK';
export const GALLERY_VIDEO_PLAYER_ANALYTICS = 'GALLERY_VIDEO_PLAYER_ANALYTICS';
export const GET_GALLERY_DETAILS_BY_ID_ERROR = 'GET_GALLERY_DETAILS_BY_ID_ERROR';
export const ON_COMPARE_PAGE_READY = 'ON_COMPARE_PAGE_READY';
export const ON_PAGE_READY = 'ON_PAGE_READY';
export const PRODUCT_FILTERS_APPLIED = 'PRODUCT_FILTERS_APPLIED';
export const PROMOTED_FILTERS_APPLIED = 'PROMOTED_FILTERS_APPLIED';
export const PROMOTED_FILTERS_RESET = 'PROMOTED_FILTERS_RESET';
export const SEARCH_RESULT_CLICK = 'SEARCH_RESULT_CLICK';
export const TRIGGER_ANALYTICS_RESET = 'TRIGGER_ANALYTICS_RESET';
export const TRIGGER_STORE_SEARCH_EVENT = 'TRIGGER_STORE_SEARCH_EVENT';
export const BOPIS_DEFAULT_STORE_CHANGE = 'BOPIS_DEFAULT_STORE_CHANGE';
