import _get from 'lodash/get';
import { GET_GALLERY_DETAILS_BY_ID_SUCCESS } from '../actionTypes/GalleryActionTypes';
import { getURLParameterBykey } from '../utils';

export default function filterReducer(state = 0, action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS: {
            let selectedFacets = _get(action.galleryDetails, 'facets.noOfSelectedFacets', 0);
            const requestUrl = _get(action.galleryDetails, 'requestUrl', '');
            const selectedStores = requestUrl && getURLParameterBykey('storeIds', requestUrl);
            if (selectedStores) {
                const selectedStoresLength = selectedStores.split('-').length;
                selectedFacets += selectedStoresLength;
            }
            return selectedFacets;
        }
        default:
            return state;
    }
}
