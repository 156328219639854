import {
    PROMOTED_FILTERS_APPLIED,
    PROMOTED_FILTERS_RESET,
} from '../actionTypes/AnalyticsActionTypes';

export default function PromotedFilterReducer(state = { value: '' }, action) {
    // NOSONAR
    switch (action.type) {
        case PROMOTED_FILTERS_APPLIED:
            return { ...action.payload };
        case PROMOTED_FILTERS_RESET:
            return { value: '' };
        default:
            return state;
    }
}
