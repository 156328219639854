/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { bool, string } from 'prop-types';

import ConnectedBOPISFacet from '../Facet/components/BOPISFacet/BOPISFacet';
import { filterCategoryPlaceHolder, facetIds } from '../../configurations/config';

export const StoreFilterCategory = (props) => {
    const { customWrapperClasses, hideCurbsidePickupLabel, sliderStatus, parentSection } = props;

    const storeAvailabilityFilter = useSelector((store) =>
        get(store, 'filters.storeAvailabilityFilter', [])
    );

    const renderStoresList = () => {
        const isStoreAvailable = !!(
            storeAvailabilityFilter.length &&
            storeAvailabilityFilter[0].facetValueList &&
            storeAvailabilityFilter[0].facetValueList.length
        );

        const facetValueList = isStoreAvailable ? storeAvailabilityFilter[0].facetValueList : [];
        /* istanbul ignore next */
        return facetValueList?.map((facetConfig) => {
            const facetProps = {
                config: storeAvailabilityFilter[0],
                facetConfig,
                hideCurbsidePickupLabel,
                labelContainerClass: null,
                placeHolder: filterCategoryPlaceHolder.filterPanel,
                sliderStatus,
                parentSection,
            };

            const isSameDayPickupAvailable =
                facetConfig.facetValId === facetIds.sameDayPickup && !facetConfig.greyOut;

            return (
                isSameDayPickupAvailable && (
                    <div
                        className={customWrapperClasses}
                        data-automation-id={`storeFilter-${parentSection}`}
                        key={facetConfig.facetValId}
                        id="storeGalleryBopisFilter"
                    >
                        <ConnectedBOPISFacet {...facetProps} />
                    </div>
                )
            );
        });
    };

    return renderStoresList();
};

StoreFilterCategory.defaultProps = {
    customWrapperClasses: '',
    inSlider: false,
    sliderStatus: false,
    parentSection: '',
};

StoreFilterCategory.propTypes = {
    customWrapperClasses: string,
    inSlider: bool,
    sliderStatus: bool,
    parentSection: bool,
};

export default StoreFilterCategory;
