import RegionActionTypes from 'yoda-site-components/lib/actionTypes/RegionActionTypes';
import { GET_GALLERY_DETAILS_BY_ID_SUCCESS } from '../actionTypes/GalleryActionTypes';

export default function sortByReducer(state = {}, action) {
    // NOSONAR
    switch (action.type) {
        case RegionActionTypes.JS_SET_REGION: {
            let { sortOptions } = state;
            /* eslint-disable radix */
            if (parseInt(action.payload, 0) === -1) {
                return state;
            }
            if (state.originalSortOptions) {
                if (
                    state.originalSortOptions &&
                    state.originalSortOptions[`r${action.payload}_sortOptions`]
                ) {
                    sortOptions = state.originalSortOptions[`r${action.payload}_sortOptions`];
                } else {
                    sortOptions = state.originalSortOptions;
                }
            }

            return { regionZone: action.payload, sortOptions };
        }

        case GET_GALLERY_DETAILS_BY_ID_SUCCESS: {
            let sortOptions = [];
            try {
                if (state.regionZone) {
                    if (action.galleryDetails) {
                        if (action.galleryDetails[`r${state.regionZone}_sortOptions`]) {
                            sortOptions = action.galleryDetails[`r${state.regionZone}_sortOptions`];
                        } else {
                            sortOptions = action.galleryDetails.sortOptions;
                        }
                    }
                } else {
                    sortOptions = action.galleryDetails.sortOptions;
                }
            } catch (e) {
                /* istanbul ignore next */
                sortOptions = action.galleryDetails.sortOptions;
            }

            return {
                originalSortOptions: action.galleryDetails ? action.galleryDetails.sortOptions : [],
                sortOptions,
            };
        }

        default:
            return state;
    }
}
