import React, { Component } from 'react';
import { arrayOf, bool, object, shape, string } from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { selectDeviceType } from 'yoda-site-components/lib/selectors/ContextSelector';
import { getScene7ImageURL, getScene7desktopImageURL, parseRequestUrl } from '../../utils';
import getBreadCrumbInfo from '../../selectors/BreadCrumbs';
import { getRequest } from '../../selectors/ContextSelector';

/* istanbul ignore next */
const getSeoProductList = (product, productSchema, isDesktop, { protocol }) => {
    if (product.ppId) {
        const { averageRating, brand, currentMin, name, ppId: productID, reviewCount } = product;

        const imgId = product && product.imagesInfo && product.imagesInfo.thumbnailImageId;

        productSchema.push({
            '@context': 'http://schema.org/',
            '@type': 'Product',
            aggregateRating: averageRating
                ? {
                      '@type': 'AggregateRating',
                      ratingValue: averageRating,
                      reviewCount,
                  }
                : null,
            brand: {
                '@type': 'Thing',
                name: brand,
            },
            image:
                protocol + (isDesktop ? getScene7desktopImageURL(imgId) : getScene7ImageURL(imgId)),
            name,
            offers: currentMin
                ? {
                      '@type': 'offer',
                      availability: 'http://schema.org/InStock',
                      price: currentMin,
                      priceCurrency: 'USD',
                      seller: {
                          '@type': 'Organization',
                          name: 'JCPenney',
                      },
                  }
                : {},
            productID,
        });
    }
};

const getProductSchema = (products, isDesktop, requestData) => {
    if (products.length > 0) {
        const productSchema = [];

        products.forEach((product) =>
            getSeoProductList(product, productSchema, isDesktop, requestData)
        );

        /* eslint-disable react/no-danger */
        return (
            <script
                dangerouslySetInnerHTML={{ __html: JSON.stringify(productSchema) }}
                type="application/ld+json"
            />
        );
    }

    return null;
};

const getBreadCrumbSchema = (breadCrumbInfo, requestData) => {
    if (breadCrumbInfo.length > 0) {
        const breadCrumbSchema = {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: breadCrumbInfo.map(({ breadCrumbLabel: name, pageUrl }, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                item: {
                    '@id': requestData.origin + pageUrl,
                    name,
                },
            })),
        };

        /* eslint-disable react/no-danger */
        return (
            <script
                dangerouslySetInnerHTML={{ __html: JSON.stringify(breadCrumbSchema) }}
                type="application/ld+json"
            />
        );
    }

    return null;
};

/* istanbul ignore next */
export class SeoSchema extends Component {
    static defaultProps = {
        breadCrumbInfo: [],
        deviceType: {},
        location: {},
        products: [],
        request: {},
    };

    static propTypes = {
        breadCrumbInfo: arrayOf(object),
        deviceType: shape({
            isDesktop: bool,
            isMobile: bool,
            isTablet: bool,
        }),
        location: shape({
            pathname: string,
            search: string,
        }),
        products: arrayOf(object),
        request: shape({
            hostname: string,
            isSecure: bool,
        }),
    };

    shouldComponentUpdate(newProps) {
        const { products } = this.props;

        return products !== newProps.products;
    }

    render() {
        const {
            breadCrumbInfo,
            deviceType: { isDesktop },
            location,
            products,
            request,
        } = this.props;

        const requestData = parseRequestUrl(request, location);
        const productSchema = getProductSchema(products, isDesktop, requestData);
        const breadcrumbSchema = getBreadCrumbSchema(breadCrumbInfo, requestData);

        return (
            <div className="seo-schema">
                {productSchema}
                {breadcrumbSchema}
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    breadCrumbInfo: getBreadCrumbInfo(store),
    deviceType: selectDeviceType(store),
    products: store.products,
    request: getRequest(store),
});

export default withRouter(connect(mapStateToProps)(SeoSchema));
