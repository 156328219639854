import REFRESH_CONTENT from '../actionTypes/RefreshContentActionTypes';

export default function refreshPageContent(state = false, action) {
    // NOSONAR
    switch (action.type) {
        case REFRESH_CONTENT:
            return action.refreshContent;
        default:
            return state;
    }
}
