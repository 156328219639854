import _isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { array, arrayOf, bool, object, oneOfType, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getURLParameterBykey } from '../../utils';
import {
    analyticsReset,
    onPageReady,
    productFilterApplied,
    promotedFilterReset,
} from '../../actions/AnalyticsActions';

export const StoreAnalytics = () => {
    const dispatch = useDispatch();

    const analytics = useSelector((state) => get(state, 'analyticsActions', false || {}));
    const productList = useSelector((state) => get(state, 'productList', [] || {}));
    const requestUrl = useSelector((state) => get(state, 'commonData.requestUrl', ''));
    const selectedStoresFilter = useSelector((state) => get(state, 'bopisInfo.selectedStores', []));

    const getAppliedStores = (url) => {
        const curStores = getURLParameterBykey('storeIds', url);

        return curStores === '' ? 0 : curStores.split('-').length;
    };

    const triggerAnalytics = () => {
        const appliedStores = getAppliedStores(requestUrl);
        const productListLoaded = !_isEmpty(productList) && productList.productListLoaded;

        if (!__SERVER__ && (analytics.content || analytics.details) && productListLoaded) {
            const isStoreSelected =
                appliedStores !== 0 && selectedStoresFilter.length === appliedStores;
            const isAppliedAndSelectedStoresEmpty = selectedStoresFilter.length === 0;
            /* istanbul ignore next */
            if (isStoreSelected || isAppliedAndSelectedStoresEmpty) {
                dispatch(onPageReady());
                dispatch(productFilterApplied());
                dispatch(promotedFilterReset());
                dispatch(analyticsReset());
            }
        }
    };

    useEffect(() => {
        triggerAnalytics();
    }, []);

    useEffect(() => {
        triggerAnalytics();
    }, [selectedStoresFilter, analytics, productList]);

    return null;
};

export default StoreAnalytics;

StoreAnalytics.defaultProps = {
    analytics: false || {},
    productList: [] || {},
    requestUrl: '',
    selectedStoresFilter: [],
};

StoreAnalytics.propTypes = {
    analytics: oneOfType([bool, object]),
    productList: oneOfType([array, object]),
    requestUrl: string,
    selectedStoresFilter: arrayOf(object),
};
