/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import isEmpty from 'lodash/isEmpty';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import List from 'yoda-core-components/lib/components/List';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import MessageBox from 'yoda-core-components/lib/components/MessageBox';
import RadioButton from 'yoda-core-components/lib/components/RadioButton/radio'; // Latest path Updated
import { validateZipCode } from 'yoda-core-components/lib/helpers/Utils/Utils';
import makeQuery from 'yoda-core-components/lib/navigation/query/makeQuery';
import { isMobileWidth } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import { setFilterSelection } from 'yoda-site-components/lib/actions/SelectionFilterAction';
import { SessionStorage } from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import isBloomReachAudience, {
    enableBloomReachPage,
} from 'yoda-site-components/lib/helpers/BloomReach/BloomReach';
import FindAStore from '../FindAStore/FindAStore';
import { promotedFilterChange, bopisDefaultStoreChange } from '../../actions/AnalyticsActions';
import {
    prePopulateStores,
    setBOPISErrorMessage,
    updateSelectedStoreLocationInfo,
} from '../../actions/BOPISActions';
import updateMultiSelectFacetInfo from '../../actions/MultiSelectFilterActions';
import { setFailoverCanonicalInfo } from '../../actions/GalleryAction';
import { filterCategoryPlaceHolder } from '../../configurations/config';
import { getMultiSelectedFacetsList, encodedUrl } from '../../helpers/FacetHelper';
import {
    updateStoresToUrl,
    getURLParameterBykey,
    getPageType,
    formatLocationServiceData,
    removeQueryStringParameter,
    updateUrl,
} from '../../utils';
import * as styles from './StoresQuickInfo.css';

const cx = classNames.bind(styles);
/* istanbul ignore next */
export const StoresQuickInfo = (props) => {
    const {
        facetConfig,
        inSlider,
        placeHolder,
        renderComponent,
        storesOnChange,
        ...additionalProps
    } = props;

    const dispatch = useDispatch();

    const {
        bopisInfo: {
            displayLoader = true,
            enableBOPISZeroProduct = false,
            errorMessage = '',
            lastRequestedParams = {},
            params,
            params: { zipCode = '' } = {},
            prePopulateStores: enablePrePopulateStores = false,
            selectedStores = [],
            solrZipcode = '',
            sortedStoresList = [],
            storesList = [],
        } = {},
        context: {
            featureFlags: {
                enableLocationService = false,
                enableMultiSelectFilters = false,
                enableCanonicalFallback = true,
                enableBloomReach = false,
                enableBloomReachV3PLP = true,
                enableBloomReachNative = false,
            } = {},
            requestUrl = '',
            preferences: {
                plpBloomreachInclusionList = [],
                bopis: { noResults = '', radius = 0, storesResultsZeroProducts = '' } = {},
            } = {},
            isNative = false,
        } = {},
        filters: { filterList = [] } = {},
        locationServiceReducer: nearestStoreByGeoLocation = {},
        multiSelectFilter: { multiSelectedFacetsList = {}, multiFacetUrl = '' } = {},
        noProducts: { storeNullResultsMessage = '' } = {},
        selectedStore: { storeDetails = {} } = {},
    } = useSelector((store) => store);
    const isNativeBloomReach = enableBloomReachNative ? false : isNative;
    const isbloomReachAudience =
        isBloomReachAudience(enableBloomReach, isNativeBloomReach) && getPageType() === 's';
    const isBloomReachV3Page = enableBloomReachPage(
        enableBloomReachV3PLP,
        requestUrl,
        plpBloomreachInclusionList
    );
    const isBloomReachPage = isbloomReachAudience || isBloomReachV3Page;
    const hasProduct =
        storesList.filter(({ productCount = 0, selected = false }) => productCount > 0 && !selected)
            .length > 0;
    const SelectedStoreMore75Radius =
        !isEmpty(selectedStores) &&
        selectedStores[0].distance &&
        parseInt(selectedStores[0].distance, 10) > radius;
    const errorOutside75 = !hasProduct && SelectedStoreMore75Radius;
    const isValidZipcode = zipCode && validateZipCode(zipCode);
    const isInitialMount = useRef(true);
    const [isIE11, setIsIE11] = useState(false);
    const isMobileView = isMobileWidth();

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;

            const storeData = enableLocationService
                ? formatLocationServiceData(nearestStoreByGeoLocation)
                : storeDetails;

            const preSelectedStores = [...getURLParameterBykey('storeIds').split('-')];

            // Header/Akamai store has higher priority than URL store
            const keyStore =
                (!isEmpty(storeData) && storeData) ||
                (preSelectedStores.length && preSelectedStores[0]);

            enablePrePopulateStores && keyStore && dispatch(prePopulateStores(keyStore));

            setIsIE11(!!window.MSInputMethodContext && !!document.documentMode);
        }
    }, []);

    const noResultsForZipcode = () => {
        if (!storesList.length && isValidZipcode && !displayLoader) {
            dispatch(
                setBOPISErrorMessage({
                    errorMessage: noResults,
                    params,
                })
            );
        }
    };

    useEffect(() => {
        if (storesList.length) {
            const allStoresOutsideRadius = storesList.every(
                ({ distance = 0 } = {}) => parseInt(distance, 10) > radius
            );

            if (allStoresOutsideRadius) {
                dispatch(
                    setBOPISErrorMessage({
                        errorMessage: noResults,
                        params,
                        reset: selectedStores.length === 0,
                    })
                );
            } else if (
                isValidZipcode &&
                !(isbloomReachAudience || isBloomReachV3Page) &&
                errorOutside75
            ) {
                dispatch(
                    setBOPISErrorMessage({
                        errorMessage: storesResultsZeroProducts,
                        params,
                    })
                );
            }
        }
        noResultsForZipcode();
    }, [storesList]);

    const hideCreateErrorBox = () => {
        dispatch(
            setBOPISErrorMessage({
                errorMessage: '',
            })
        );
    };

    const onChange = ({ target: { checked: isChecked = false } = {} }, { id, name }) => {
        const stores = sortedStoresList.map((store) => ({
            ...store,
            selected: false,
        }));

        facetConfig.action = isChecked;
        facetConfig.storeId = id;
        facetConfig.value = name;

        dispatch(
            promotedFilterChange({
                value:
                    placeHolder === filterCategoryPlaceHolder.promotedFilters
                        ? facetConfig.name.toLowerCase()
                        : '',
            })
        );

        dispatch(setFilterSelection({ isBottomToTopFilterSelection: true }));

        dispatch(updateSelectedStoreLocationInfo({ selectedStoreId: id, solrZipcode }));

        enableCanonicalFallback &&
            dispatch(
                setFailoverCanonicalInfo({
                    isUpdateCanonicalUrl: true,
                })
            );
        if (enableMultiSelectFilters) {
            const selectedFacetsList = isChecked
                ? getMultiSelectedFacetsList(
                      {},
                      facetConfig,
                      filterList,
                      isChecked,
                      multiSelectedFacetsList
                  )
                : multiSelectedFacetsList;

            const updatedUrl = updateStoresToUrl(
                facetConfig,
                stores,
                true,
                enableMultiSelectFilters,
                multiFacetUrl
            );

            const { storeIds } = makeQuery(updatedUrl);

            const updatedMultiFacetUrl =
                isChecked || (!isChecked && storeIds)
                    ? encodedUrl(updatedUrl, facetConfig, selectedFacetsList)
                    : removeQueryStringParameter(updatedUrl, 'store_availability');

            dispatch(
                updateMultiSelectFacetInfo({
                    enableProductsLoader: true,
                    enableFacetLoader: true,
                    multiSelectedFacetsList: selectedFacetsList,
                    multiFacetUrl: updatedMultiFacetUrl,
                    scrollToTitle: false,
                })
            );

            updateUrl(updatedMultiFacetUrl, null, null, {
                name: facetConfig.name,
                value: facetConfig.value,
                action: facetConfig.action ? 'add' : 'remove',
            });
        } else
            updateStoresToUrl(facetConfig, stores, true, enableMultiSelectFilters, multiFacetUrl);

        storesOnChange && storesOnChange(false, isChecked);
        const keyValue = SessionStorage.getData('setBopisDefaultStore', true);
        if (isEmpty(keyValue)) {
            dispatch(bopisDefaultStoreChange());
            SessionStorage.setData('setBopisDefaultStore', true, true);
        }
    };

    const storeViewRenderer = (store, index) => {
        const useNotOldCommonBopisServiceContainerStyles = dt(['justify-between']);
        const useNotCommonServiceDistanceStyles = dt(['flex-auto', 'pl-1']);
        const InputLabel = () => (
            <div className={`${dt(['flex'])} ${cx(useNotOldCommonBopisServiceContainerStyles)}`}>
                <span
                    data-automation-id="at-storedetailscard-title"
                    className={dt(['flex', 'flex-wrap'])}
                >
                    {store.name}{' '}
                    {!!(
                        (lastRequestedParams.userLatitude || lastRequestedParams.location) &&
                        store.distance
                    ) && (
                        <span
                            className={`${dt(['whitespace-nowrap'])} ${cx(
                                useNotCommonServiceDistanceStyles
                            )}`}
                            data-automation-id="at-storedetailscard-distance"
                        >
                            {`(${store.distance} mi.)`}
                        </span>
                    )}
                </span>
                <span className={dt(['text-gray-400'])}>
                    {!isBloomReachPage && `(${store.productCount})`}
                </span>
            </div>
        );

        return (
            <li
                className={dt([
                    isIE11 && index < storesList.length - 1 && '-mb-1',
                    'smOnly:py-3',
                    'md:py-1',
                ])}
                key={`${store.name.replace(/\s+/gim, '')}-${store.id}`}
            >
                <RadioButton
                    className={dt(['w-full', 'smOnly:pr-2'])}
                    labelclassName={dt(['w-full', 'inline-block'])}
                    defaultChecked={store.selected}
                    disabled={!isBloomReachPage && !store.productCount}
                    id={`storeSelectOption${store.id}${placeHolder}`}
                    label={<InputLabel />}
                    name={`${store.name.replace(/\s+/gim, '')}-${store.id}${
                        inSlider ? 'Slider' : 'NotSlider'
                    }`}
                    onChange={(event) => {
                        onChange(event, store);
                    }}
                    value={store.id}
                />

                <MessageBox
                    className={dt(['my-2', 'normal-case'])}
                    message={storeNullResultsMessage}
                    messageType="error"
                    showMessage={
                        !!(
                            storeNullResultsMessage &&
                            selectedStores.find((selectedStore) => selectedStore.id === store.id)
                        )
                    }
                />
            </li>
        );
    };
    const isApplyScrollstyles = isBloomReachPage ? true : enableBOPISZeroProduct;
    return (
        renderComponent && (
            <div
                {...{
                    className: `${dt([
                        'bg-white',
                        'capitalize',
                        'relative',
                        !inSlider && 'py-6',
                        inSlider && 'w-full',
                    ])} ${cx(additionalProps.className)}`,
                    'data-component-name': 'StoresQuickInfo',
                }}
            >
                {displayLoader && (
                    <Loader
                        automationId="test-automation-loader-1"
                        loaderClass={dt(['absolute', 'sm:!z-10'])}
                        keepOverlay
                    />
                )}
                <div className={dt(['md:px-6', !inSlider && 'smOnly:px-4'])}>
                    <FindAStore
                        inSlider={inSlider}
                        error={
                            errorMessage &&
                            dt(['!border-2', '!border-solid', '!border-error-border'])
                        }
                        errorMessage={errorMessage}
                        hideCreateErrorBox={hideCreateErrorBox}
                        isValidZipcode={isValidZipcode}
                        isMobileView={isMobileView}
                    />
                    {!isMobileView && (
                        <MessageBox
                            className={dt([
                                'font-sans',
                                'font-normal',
                                'my-4',
                                'normal-case',
                                'smOnly:p-3',
                                inSlider && 'smOnly:mx-6',
                            ])}
                            message={errorMessage}
                            messageType="error"
                            showMessage={!!errorMessage}
                            showCloseButton
                            closeHandler={hideCreateErrorBox}
                        />
                    )}
                    {isMobileView && zipCode?.length > 0 && !isValidZipcode && (
                        <div
                            className={dt([
                                'flex',
                                'normal-case',
                                'text-small',
                                'text-sm',
                                'text-error-border',
                                inSlider ? 'smOnly:px-9' : 'smOnly:px-4',
                            ])}
                        >
                            Enter a valid ZIP code.
                        </div>
                    )}
                </div>
                {storesList && storesList.length > 0 && (
                    <div
                        className={`${dt([
                            'overflow-y-auto',
                            ['px-6'],
                            !inSlider && 'smOnly:px-4',
                            'smOnly:pt-4',
                            inSlider && 'smOnly:mr-2',
                            inSlider && 'smOnly:pr-4',
                        ])}`}
                    >
                        <List
                            automationId="at-change-stores-list"
                            itemRenderer={storeViewRenderer}
                            listClassName={`${cx({
                                [isIE11 ? styles.storesIE11 : styles.stores]: isApplyScrollstyles,
                            })} ${dt([
                                'flex',
                                'flex-col',
                                'md:gap-y-1',
                                'smOnly:min-h-[276px]',
                                'smOnly:gap-y-2',
                            ])}`}
                        >
                            {storesList}
                        </List>
                    </div>
                )}
            </div>
        )
    );
};

StoresQuickInfo.propTypes = {
    facetConfig: shape({ id: string, name: string }).isRequired,
    inSlider: bool,
    placeHolder: string,
    renderComponent: bool,
    storesOnChange: func,
};

StoresQuickInfo.defaultProps = {
    inSlider: false,
    placeHolder: '',
    renderComponent: true,
    storesOnChange: () => {},
};

export default StoresQuickInfo;
