import _get from 'lodash/get';
import _has from 'lodash/has';
import { LAST_GALLERY_ACTION_RESULT, PREV_NO_RESULTS } from '../actionTypes/GalleryActionTypes';
import { removeQueryStringParameter } from '../utils';

export default function lastGalleryAction(state = {}, action) {
    // NOSONAR
    switch (action.type) {
        case LAST_GALLERY_ACTION_RESULT: {
            const lastAction = state;
            const hasFacets = _get(action.galleryDetails, 'facets.facetList', []);
            if (hasFacets.length) {
                const requestUrl = _get(action.galleryDetails, 'requestUrl');
                const url = requestUrl.split('/api/') && requestUrl.split('/api/')[1];
                lastAction.url = removeQueryStringParameter(url, 'responseType');
            }
            return lastAction;
        }

        case PREV_NO_RESULTS: {
            const resultsState = {};
            resultsState.currentHasResults = _get(
                action.galleryDetails,
                'organicZoneInfo.products',
                []
            ).length;
            resultsState.prevNoResults = _has(state, 'currentHasResults')
                ? !state.currentHasResults
                : !resultsState.currentHasResults;
            return { ...state, ...resultsState };
        }
        default:
            return state;
    }
}
