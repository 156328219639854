import { GET_GALLERY_DETAILS_BY_ID_SUCCESS } from '../actionTypes/GalleryActionTypes';

export default function statusCheckReducer(state = null, action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS:
            return action.galleryStatus || null;
        default:
            return state;
    }
}
