import SiteComponentReducers from 'yoda-site-components/lib/reducers';
import CouponListReducer from 'yoda-site-components/lib/reducers/CouponListReducer';
import CouponDrawerReducer from 'yoda-site-components/lib/reducers/CouponDrawerReducer';
import LoadingReducer from 'yoda-site-components/lib/reducers/LoadingReducer';
import MessageContentReducer from 'yoda-checkout-components/lib/reducers/MessageContentReducer';
import WebvitalsReducer from 'yoda-site-components/lib/reducers/WebvitalsReducer';
import AnalyticsReducer from './AnalyticsReducer';
import BOPISReducer from './BOPISReducer';
// import CriteoZoneReducer from './CriteoZoneReducer';
import PromotedFilterReducer from './PromotedFilterReducer';
import SeoTagsReducer from './SeoTagsReducer';
import SliderReducer from './SliderReducer';
import clearAllFacetsReducer from './ClearAllFacetsReducer';
import commonReducer from './CommonReducer';
import countOfSearchResultsReducer from './CountOfSearchResultsReducer';
import countOfSelectedFiltersReducer from './CountOfSelectedFiltersReducer';
import dynamicVisNavReducer from './DynamicVisNavReducer';
import multiSelectFilterReducer from './MultiSelectFilterReducer';
import filterReducer from './FilterReducer';
import failOverLayoutReducer from './FailOverLayoutReducer';
import irisGalleryContentReducer from './IrisGalleryContentReducer';
import lastGalleryActionReducer from './LastGalleryAction';
import loadPaginationReducer from './LoadPaginationReducer';
import loaderReducer from './LoaderReducer';
import noProductsReducer from './NoProductsReducer';
import noResponseReducer from './NoResponseReducer';
import partialMatchInfoReducer from './PartialMatchInfoReducer';
import productListReducer from './ProductListReducer';
import productListSettingsReducer from './ProductListSettingsReducer';
import productsReducer from './ProductsReducer';
import reRenderCertonaReducer from './ReRenderCertonaReducer';
import refreshContentReducer from './RefreshContentReducer';
import relatedSearchReducer from './RelatedSearchReducer';
import selectedFiltersReducer from './SelectedFiltersReducer';
import sortByReducer from './SortByReducer';
import statusReducer from './statusReducer';
// import suggestedFiltersReducer from './SuggestedFiltersReducer';
import rerenderReducer from './rerenderReducer';
import sponsoredDisplayPage from './sponsoredDisplayPageReducer';

const rootReducer = {
    couponList: CouponListReducer,
    couponDrawer: CouponDrawerReducer,
    /**
     * yoda-microsite-gallery/src/components/StoreAnalytics/StoreAnalytics.jsx
     */
    analyticsActions: AnalyticsReducer,
    /**
     * pagination details
     * yoda-microsite-gallery/src/components/Pagination/LoadPagination.jsx
     */
    bopisInfo: BOPISReducer,
    /**
     * yoda-microsite-gallery/src/components/SelectedFacets/SelectedFacets.jsx
     */
    clearAllFacets: clearAllFacetsReducer,
    /**
     * this is a common reducer for common activity example is API result is loaded or not
     * yoda-microsite-gallery/src/components/NoSearchResults/NoSearchResults.jsx
     */
    commonData: commonReducer,
    /**
     * get the details of searchResult
     * yoda-microsite-gallery/src/AmpComponents/AMPAnalytics/AMPAnalytics.jsx
     * yoda-microsite-gallery/src/components/FilterHeader/FilterHeader.jsx
     * yoda-microsite-gallery/src/components/FilterPanel/FilterPanel.jsx
     */
    countOfSearchResults: countOfSearchResultsReducer,
    /**
     * get count of stores selected
     * yoda-microsite-gallery/src/components/FilterHeader/FilterHeader.jsx
     */
    countOfSelectedFilters: countOfSelectedFiltersReducer,
    /**
     * yoda-microsite-gallery/src/components/CriteoZone/GalleryCriteoZone.js
     */
    /* criteoProducts: CriteoZoneReducer,
    /**
     * yoda-microsite-gallery/src/components/GalleryLoader/GalleryLoader.jsx
     */
    enableLoader: loaderReducer,

    /**
     * yoda-microsite-gallery/src/components/DynamicVisNav/DynamicVisNav.jsx
     */
    dynamicVisNavReducer,

    /**
     * yoda-microsite-gallery/src/components/FilterPanel/components/GalleryAccordionSection/GalleryAccordionSection.jsx
     * yoda-microsite-gallery/src/components/FilterPanel/FilterPanel.jsx
     */
    filters: filterReducer,

    /* yoda-microsite-gallery/src/components/FailOverLayout/FailOverLayout.jsx */
    failOverLayoutInfo: failOverLayoutReducer,
    /**
     * yoda-microsite-gallery/src/containers/Pages/Iris/IrisWrapper.jsx
     */
    irisContent: irisGalleryContentReducer,
    /**
     * yoda-microsite-gallery/src/components/TimeoutError/TimeoutError.jsx
     */
    isTimeoutError: noResponseReducer,
    /**
     * yoda-microsite-gallery/src/components/GallerySlider/GallerySlider.jsx
     */
    lastGalleryAction: lastGalleryActionReducer,

    /**
     * yoda-microsite-gallery/src/components/GalleryGrid/GalleryGrid.jsx
     * yoda-microsite-gallery/src/components/Facet/components/FacetLabel/FacetLabel.jsx
     * yoda-microsite-gallery/src/components/Facet/Facet.jsx
     * yoda-microsite-gallery/src/components/FilterChips.jsx
     * yoda-microsite-gallery/src/components/FilterPanel.jsx
     * yoda-microsite-gallery/src/components/FilterCategory.jsx
     * yoda-microsite-gallery/src/components/GallerySlider/components/Header.jsx
     * yoda-microsite-gallery/src/components/GallerySlider/components/Footer.jsx
     * yoda-microsite-gallery/src/components/StoresQuickInfo/StoreQuickInfo.jsx
     * yoda-microsite-gallery/src/components/StoresQuickInfo/StoreQuickInfoSingleSelect.jsx
     * yoda-microsite-gallery/src/components/SelectedFacets/SelectedFacets.jsx
     */
    multiSelectFilter: multiSelectFilterReducer,

    /**
     * yoda-microsite-gallery/src/components/NoResultsCard/NoResultsCard.jsx
     */
    noProducts: noProductsReducer,
    /**
     * yoda-microsite-gallery/src/components/Pagination/LoadPagination.jsx
     */
    pagination: loadPaginationReducer,
    /**
     * yoda-microsite-gallery/src/components/GalleryTitle/GalleryTitle.jsx
     */
    partialMatchInfo: partialMatchInfoReducer,
    /**
     * yoda-microsite-gallery/src/components/Product/ProductList.jsx
     */
    productList: productListReducer,
    /**
     * yoda-microsite-gallery/src/components/Product/ProductList.jsx
     */
    products: productsReducer,
    /**
     * state of panel (i.e. is filter panel open or not)
     * yoda-microsite-gallery/src/components/Product/ProductListRenderer.jsx
     */
    productSettings: productListSettingsReducer,
    /**
     * yoda-microsite-gallery/src/analytics/analytics.helper.js
     * yoda-microsite-gallery/src/middlewares/AnalyticsMiddleware.js
     */
    promotedFilterSelected: PromotedFilterReducer,
    /**
     * yoda-microsite-gallery/src/containers/Pages/Iris/IrisWrapper.jsx
     * SearchWrapper
     * GalleryWrapper
     */
    refreshContent: refreshContentReducer,
    /**
     * yoda-microsite-gallery/src/components/RelatedSearch/RelatedSearch.jsx
     */
    relatedSearchReducer,
    /**
     * yoda-microsite-gallery/src/components/CriteoZone/GalleryCriteoZone.js
     */
    renderCertona: reRenderCertonaReducer,
    /**
     * yoda-microsite-gallery/src/components/SelectedFacets/SelectedFacets.jsx
     */
    selectedFilters: selectedFiltersReducer,
    /**
     * yoda-microsite-gallery/src/components/Seo/Seo.jsx
     */
    seoTags: SeoTagsReducer,
    /**
     * yoda-microsite-gallery/src/components/FilterContent/FilterContent.jsx
     * yoda-microsite-gallery/src/components/GallerySlider/GallerySlider.jsx
     */
    sliderStatus: SliderReducer,
    /**
     * this reducer is getting the sort options (i.e maximum price)
     * yoda-microsite-gallery/src/components/SortBy/SortBy.jsx
     */
    sortOptions: sortByReducer,
    /**
     * added to check for status ex: 403
     * yoda-microsite-gallery/src/selectors/fromState/index.js
     * yoda-microsite-gallery/src/components/GalleryGrid/GalleryGrid.jsx
     */
    statusCheck: statusReducer,
    /**
     * yoda-microsite-gallery/src/components/suggestedFilters/suggestedFilters.jsx
     */
    // suggestedFilters: suggestedFiltersReducer,
    messageContentData: MessageContentReducer,
    rerender: rerenderReducer,
    /* yoda-site-components/lib/iris/wrappers/ImageBanner/ImageBanner.jsx
     * yoda-site-components/lib/components/CMImageBanner/ImageBanner.jsx
     */
    isLoading: LoadingReducer,
    sponsoredDisplayPage,
    webvitals: WebvitalsReducer,
    ...SiteComponentReducers,
};
export default rootReducer;
