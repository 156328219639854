/* eslint-disable no-case-declarations */
/* eslint-disable no-lone-blocks */
import { GET_STORE_BY_STORE_ID_SUCCESS } from 'yoda-site-components/lib/actionTypes/OrderActionTypes';
import {
    DISABLE_SELECT_STORE,
    DISPLAY_STORES_LOADER,
    FETCH_STORES_ERROR,
    FETCH_STORES_ERROR_ZERO_PRODUCT,
    GALLERY_STORES_SUCCESS,
    HIDE_STORES_LOADER,
    PRE_POPULATE_STORES,
    SET_CURRENT_STORES,
    UPDATE_SELECTED_STORE_LOCATION_INFO,
    ENABLE_PRE_POPULATE_GALLERY_STORES,
} from '../actionTypes/BOPISActionTypes';

/* istanbul ignore next */
function getSelectedStores(stores) {
    const selected = [];
    const unselected = [];
    stores.forEach((store) => {
        if (store.selected) {
            selected.push(store);
        } else {
            unselected.push(store);
        }
    });
    return {
        selected,
        allStores: selected.concat(unselected),
    };
}

const defaultState = {
    currentStore: {
        storeDetails: {},
        isChecked: false,
    },
    displayLoader: false,
    errorMessage: '',
    headerStore: null,
    lastRequestedParams: null,
    params: {},
    selectedStores: [],
    sortedStoresList: [],
    storesList: [], // Includes both selected stores and current store list
    prePopulateStores: true,
    selectedStoreId: '',
    isBRAudience: false,
};

const filterZeroProductStores = (stores, defaultRadius = 75, isBRAudience = false) =>
    !isBRAudience
        ? stores.filter(
              (store) => store.productCount === 0 && parseInt(store.distance, 10) < defaultRadius
          )
        : stores.filter((store) => parseInt(store.distance, 10) < defaultRadius);

const filterNonRequiredStores = (stores, defaultRadius = 75, isBRAudience = false) =>
    !isBRAudience
        ? stores.filter(
              (store) =>
                  (store.productCount > 0 && parseInt(store.distance, 10) < defaultRadius) ||
                  (store.selected && store.productCount > 0)
          )
        : stores.filter((store) => parseInt(store.distance, 10) < defaultRadius || store.selected);

export default function BOPISReducer(state = defaultState, action) {
    // NOSOANR
    switch (action.type) {
        case GET_STORE_BY_STORE_ID_SUCCESS: {
            return { ...state, headerStore: action.selectedStore };
        }
        case GALLERY_STORES_SUCCESS: {
            const { enableBOPISZeroProduct, solrZipcode, isBRAudience } = action;
            const rearrangeStores = getSelectedStores(action.stores);
            const filterStoresList = filterNonRequiredStores(
                rearrangeStores.allStores,
                action.lastRequestedParams.radius,
                isBRAudience
            );
            const storeListZeroProduct = filterZeroProductStores(
                rearrangeStores.allStores,
                action.lastRequestedParams.radius,
                isBRAudience
            );
            const is2orMoreStoresExistsWithZeroProducts = storeListZeroProduct.length > 2;
            const storesList = enableBOPISZeroProduct
                ? [...filterStoresList, ...storeListZeroProduct]
                : filterStoresList;
            const sortedStores = filterNonRequiredStores(
                action.stores,
                action.lastRequestedParams.radius,
                isBRAudience
            );
            const sortedStoresList = enableBOPISZeroProduct
                ? [...sortedStores, ...storeListZeroProduct]
                : sortedStores;
            return {
                ...state,
                disableSelectStore: false,
                displayLoader: false,
                enableBOPISZeroProduct,
                solrZipcode,
                errorMessage: '',
                is2orMoreStoresExistsWithZeroProducts,
                isBRAudience,
                lastRequestedParams: action.lastRequestedParams,
                params: action.params,
                selectedStores: rearrangeStores.selected,
                sortedStoresList,
                storesList,
            };
        }
        case DISPLAY_STORES_LOADER: {
            return { ...state, displayLoader: true };
        }
        case HIDE_STORES_LOADER: {
            return { ...state, displayLoader: false };
        }
        case FETCH_STORES_ERROR: {
            return {
                ...state,
                displayLoader: false,
                errorMessage: action.errorMessage,
                params: action.params || {},
                storesList: action.reset ? [] : state.storesList,
            };
        }
        case FETCH_STORES_ERROR_ZERO_PRODUCT:
            const { enableBOPISZeroProduct } = action;
            const rearrangeStores = getSelectedStores(action.stores);
            const storesList = filterZeroProductStores(rearrangeStores.allStores);
            const sortedStoresList = filterNonRequiredStores(action.stores);
            {
                return {
                    ...state,
                    displayLoader: false,
                    enableBOPISZeroProduct,
                    errorMessage: action.errorMessage,
                    lastRequestedParams: action.lastRequestedParams,
                    params: action.params || {},
                    sortedStoresList,
                    storesList,
                };
            }

        case SET_CURRENT_STORES: {
            return { ...state, storesList: action.storesList };
        }
        case PRE_POPULATE_STORES: {
            return { ...state, prePopulateStores: false };
        }

        case ENABLE_PRE_POPULATE_GALLERY_STORES: {
            return { ...state, prePopulateStores: true };
        }
        case DISABLE_SELECT_STORE: {
            return { ...state, disableSelectStore: action.disableSelectStore };
        }
        case UPDATE_SELECTED_STORE_LOCATION_INFO: {
            return { ...state, selectedStoreId: '' };
        }
        default:
            return state;
    }
}
