/**
 * List of constants required for ensighten tagging, we will be using on page level components to update jcpDl object
 */
const constants = {
    PAGE_g: 'category',
    PAGE_s: 'search',
    PAGE_ns: 'null search',
    PAGE_ng: 'null gallery',
    VERSION_g: 'yoda category 1',
    VERSION_s: 'yoda search 1',
    VERSION_ns: 'yoda null search 1',
    VERSION_ng: 'yoda null gallery 1',
    PAGE_c: 'product compare',
    VERSION_c: 'yoda compare',
};

export default constants;
