import _get from 'lodash/get';
import { GET_GALLERY_DETAILS_BY_ID_SUCCESS } from '../actionTypes/GalleryActionTypes';

export default function dynamicVisNavData(state = null, action) {
    switch (action.type) {
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS:
            return _get(action.galleryDetails, 'dynamicFiltersList') || [];
        default:
            return state;
    }
}
