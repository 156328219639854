import {
    IRIS_LOAD_ERROR_PAGE,
    IRIS_PAGE_LOAD_SUCCESS,
} from 'yoda-site-components/lib/actionTypes/IrisActionTypes';
import { IRIS_INITIAL_CALL_MODE } from '../actionTypes/GalleryIrisActionTypes';
import { fail, success } from '../containers/Pages/Iris/constants';
import { getValidMarketingTiles } from '../utils/irisDataProcesserFunction';
import { isFirstPageWithoutPrev } from '../utils';

const defultState = {
    contentCallCompleted: false,
    irisCallStatus: false,
    coreZone: {},
    isInitialCallClient: false,
};
export default function irisGalleryContentReducer(state = defultState, action) {
    // NOSONAR
    switch (action.type) {
        case IRIS_PAGE_LOAD_SUCCESS: {
            let marketingTilesInitialCount;
            const { seoMetaData: { content: { canonicalUrl = '' } = {} } = {} } =
                action.irisData || {};
            const firstPage = isFirstPageWithoutPrev(canonicalUrl);
            let coreZone = { ...state.coreZone };
            if (firstPage) {
                marketingTilesInitialCount =
                    getValidMarketingTiles(action.irisData).length <= 2
                        ? getValidMarketingTiles(action.irisData).length
                        : 2;
                //! __SERVER__ && SessionStorage.setData('mktTiles', marketingTilesInitialCount);
                coreZone = { ...state.coreZone, marketingTilesInitialCount };
            }
            return {
                ...state,
                contentCallCompleted: true,
                irisCallStatus: success,
                coreZone,
            };
        }
        /* istanbul ignore next */
        case IRIS_LOAD_ERROR_PAGE: {
            return {
                ...state,
                contentCallCompleted: true,
                irisCallStatus: fail,
            };
        }

        case IRIS_INITIAL_CALL_MODE: {
            return {
                ...state,
                isInitialCallClient: true,
            };
        }

        default:
            return state;
    }
}
