/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import VisualNav from 'yoda-core-components/lib/components/VisualNav/VisualNav';
import classNames from 'classnames/bind';
import get from 'lodash/get';
import { checkUrlExtension, replaceSubdomain } from 'yoda-core-components/lib/helpers/Utils/Utils';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import RevieveModal from 'yoda-site-components/lib/components/Revieve/RevieveModal';
import * as styles from './VisNav.css';
import getVisNav from '../../selectors/Visnav';
import { selectCountOfSearchResults } from '../../selectors/fromState';
import { getPageType } from '../../utils';

const cx = classNames.bind(styles);

export const VisNav = (props) => {
    const isSuggestedFiltersPriority = useSelector((store) =>
        get(store, 'context.featureFlags.isSuggestedFiltersPriority', false)
    );
    const countOfSearchResults = useSelector((store) => selectCountOfSearchResults(store));

    const { datasource = [], navTitle } = useSelector((store) => getVisNav(store, props));
    const preferences = useSelector((store) => get(store, 'context.preferences', {}));
    const requestUrl = useSelector((store) => get(store, 'context.requestUrl', ''));
    const hasResults = datasource.length;
    const isGalleryPage = getPageType(requestUrl) === 'g';
    const isSearchPage = requestUrl === '/s';
    // show visnav for gallery page even if product count is null https://jira.jcpenney.com/browse/YODATOF-2358
    const resultWithSearchPage = isSearchPage ? countOfSearchResults : isGalleryPage;
    const shouldDisplayInSearch = isSearchPage && !isSuggestedFiltersPriority;
    const deptTitleClasses = `${dt(['capitalize', 'pt-2', 'text-black', 'text-small'])} ${cx(
        'department-title'
    )}`;
    const galleryVisNavStyles = dt(['border-none', 'pb-1']);
    const galleryTheme = {
        deptTitle: deptTitleClasses,
        imgBlock: `${dt(['w-full', 'sm:!pb-[100%]', 'relative', 'sm:!min-h-0'])} ${cx(
            'image-block'
        )}`,
        visualNavComponent: cx('visualNavComponent', galleryVisNavStyles),
    };
    const themeConfig = {
        gridTilesList: `${dt(['lg:mx-2'])} ${cx('grid-tiles-list')}`,
        visNavDeptTitle: deptTitleClasses,
        visNavGridTilesClass: `${dt([
            'flex-no-wrap',
            'inline-flex',
            'items-baseline',
            'min-w-full',
        ])} ${cx('grid-tiles')}`,
        visNavGridTilesList: true,
    };

    const formattedDataSource = datasource.map((item) => {
        const itemReturn = item;
        itemReturn.ext = checkUrlExtension(item.image);
        if (item.ext === 'mp4') {
            itemReturn.image = replaceSubdomain(item.image, preferences);
        }
        return itemReturn;
    });

    const visualNavConfig = {
        visualNav: {
            automationId: 'product-visual-navigation',
            datasource: formattedDataSource,
            enableCarouselSlider: true,
            galleryTheme,
            navTitle,
            themeConfig,
            visualNavBodyClass: dt(['text-center']),
            vizNavTheme: 'styleB',
            revieveModal: RevieveModal,
        },
        wrapper: {
            className: `${dt(['overflow-hidden'])} ${cx('visnav-custom-wrapper')}`,
            'data-whoami': 'visNav-coreZones',
        },
    };

    return hasResults && resultWithSearchPage && (isGalleryPage || shouldDisplayInSearch) ? (
        <div {...visualNavConfig.wrapper}>
            <VisualNav {...visualNavConfig.visualNav} />
        </div>
    ) : null;
};

export default VisNav;
