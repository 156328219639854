export const defaultPreferences = {
    renderingStrategy: {
        gallery: {
            offset: {
                mobile: 300,
                desktop: 500,
                tablet: 400,
            },
            height: {
                mobile: 300,
                desktop: 500,
                tablet: 400,
            },
        },
    },
};
