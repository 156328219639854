/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames/bind';
import Icon from 'yoda-core-components/lib/components/Icon';
import Close from 'yoda-core-components/lib/assets/svg/CloseIcon_2022.svg';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { updateUrl } from '../../../utils';
import updateMultiSelectFacetInfo from '../../../actions/MultiSelectFilterActions';

import * as styles from '../GallerySlider.css';

const cx = classNames.bind(styles);

const Header = ({ className, hasFilterContent, toggleSlider, url }) => {
    const {
        context: { featureFlags: { enableMultiSelectFilters = false } = {} } = {},
        multiSelectFilter: { multiFacetUrl = '' } = {},
    } = useSelector((store) => store);
    const dispatch = useDispatch();
    /* istanbul ignore next */
    const headerConfig = {
        closeButton: {
            onClick: () => {
                if (hasFilterContent) {
                    if (enableMultiSelectFilters && multiFacetUrl) {
                        dispatch(
                            updateMultiSelectFacetInfo({
                                enableProductsLoader: true,
                                enableFacetLoader: false,
                                scrollToTitle: true,
                            })
                        );
                        multiFacetUrl && updateUrl(multiFacetUrl, null, null, null);
                    }
                    toggleSlider();
                } else {
                    if (url) updateUrl(url);
                    toggleSlider();
                }
            },
            style: {
                position: 'absolute',
                right: 0,
                transform: 'translateX(-50%)',
                paddingLeft: '0.5rem',
            },
        },
        closeButtonIcon: {
            automationId: 'product-grid-icon',
            height: '32',
            iconName: 'close',
            iconComponent: <Close />,
            width: '32',
        },
        title: {
            className: dt([
                'flex-1',
                'font-open-sans-semibold',
                'pointer-events-none',
                'text-center',
                'text-black',
                'text-xLg',
            ]),
        },
        wrapper: {
            className: `${dt([
                'bg-white',
                'fixed',
                'flex',
                'flex-row',
                'h-12',
                'items-center',
                'justify-center',
                'px-4',
                'py-2',
                'shadow-elevation2',
                'lg:text-medium',
                'sm:text-lg',
                'w-full',
                'smOnly:pt-3',
                'smOnly:pb-3',
            ])} ${cx(className)}`,
        },
    };

    return (
        <div {...headerConfig.wrapper}>
            <div {...headerConfig.title}>Filter</div>
            <button {...headerConfig.closeButton} type="button">
                <Icon {...headerConfig.closeButtonIcon}>
                    {headerConfig.closeButtonIcon.iconComponent}
                </Icon>
            </button>
        </div>
    );
};

Header.defaultProps = {
    url: '',
};

Header.propTypes = {
    className: string.isRequired,
    hasFilterContent: bool.isRequired,
    toggleSlider: func.isRequired,
    url: string,
};

export default Header;
