import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { selectEnableTextTransform } from '../../selectors/fromState';
import { getAutoCorrectedMessage, getH1Tag } from '../../selectors/SeoTags';
import {
    getPageURLFromAPIURL,
    getSearchTermFromUrl,
    updateQueryStringParameter,
} from '../../utils';
import * as styles from './GalleryTitle.css';
import {
    selectEnableBrPreviewGallerySkeleton,
    getH1TagBrPreview,
} from '../../selectors/BloomreachSelector';

const cx = classNames.bind(styles);

const defaultProps = {
    pageType: '',
};

const propTypes = {
    pageType: PropTypes.string,
};

const GalleryTitle = ({ pageType }) => {
    const enableKeywordRedirectMessage = useSelector((state) =>
        get(state, 'context.featureFlags.enableKeywordRedirectMessage', false)
    );
    const enableTextTransform = useSelector((state) => selectEnableTextTransform(state));
    const h1Tag = useSelector((state) => getH1Tag(state));
    const keywordRedirectMessage = useSelector((state) =>
        get(state, 'commonData.keywordRedirectMessage', '')
    );
    const seoTags = useSelector((state) => get(state, 'seoTags', {}));
    const autoCorrectedMessage = useSelector((state) => getAutoCorrectedMessage(state));
    const countOfSearchResults = useSelector((state) => get(state, 'countOfSearchResults', 0));
    const partialMatchInfo = useSelector((state) => state.partialMatchInfo);
    const showSearchResultsCountSubtitle = useSelector((state) =>
        get(state, 'context.featureFlags.showSearchResultsCountSubtitle', false)
    );
    const enableBrPreviewGallerySkeleton = useSelector((state) =>
        selectEnableBrPreviewGallerySkeleton(state)
    );
    const bloomReachPreviewShowMockData = useSelector((state) =>
        get(state?.commonData, 'showBRPreviewMock', false)
    );
    const H1TagBrPreview = useSelector((state) => getH1TagBrPreview(state));

    const renderKeywordRedirectMessage = (message) => {
        const messageWrapper = dt(['flex', 'flex-row', 'flex-wrap']);
        const messageClass = dt(['font-open-sans', 'text-center', 'text-normal', 'w-full']);
        const headingClass = dt([
            'font-open-sans-bold',
            'text-center',
            'text-normal',
            'w-full',
            'lg:text-xlarge',
            'mt-1',
            'mb-4',
            'capitalize',
        ]);
        const [messageTop, messageSearchTerm, messageBottom] = message?.split('"');
        return (
            <div className={messageWrapper}>
                <p className={messageClass}>{messageTop}</p>
                <p className={headingClass}>{messageSearchTerm && `"${messageSearchTerm}"`}</p>
                <p className={messageClass}>{messageBottom}</p>
            </div>
        );
    };

    const renderPartialMatchResult = (pageTitle, subTitleClasses, titleClasses) => {
        const { fullMatchTerms } = partialMatchInfo || {};
        const matchTermsLength = fullMatchTerms?.length;
        const textDecoration =
            matchTermsLength === 1
                ? dt(['no-underline', 'pointer-events-none'])
                : dt(['hover:underline', 'active:underline', 'focused:underline', 'underline']);
        const partialMatchTermContainerClasses = `${dt([
            'capitalize',
            'mb-4',
            'mt-1',
            'text-center',
            'w-full',
            'flex',
            'flex-wrap',
            'justify-center',
            'gap-1',
        ])} ${cx('partialMatchTermContainer')}`;

        return (
            <>
                <h2 className={subTitleClasses} data-automation-id="subheaderTag">
                    {`Here are ${countOfSearchResults} results for`}
                </h2>
                <div className={partialMatchTermContainerClasses}>
                    {fullMatchTerms.map((term, index) => {
                        const partialMatchUrl = getPageURLFromAPIURL(term.fullMatchUrl);
                        const newSearchUrl = updateQueryStringParameter(
                            partialMatchUrl,
                            'searchType',
                            'partialFullMatchTerm'
                        );

                        return (
                            <div className={dt(['truncate'])}>
                                <a
                                    className={`${textDecoration} ${titleClasses} ${dt([
                                        'sm:text-normal',
                                        'lg:text-xlarge',
                                    ])}`}
                                    data-automation-id="headerTag"
                                    href={newSearchUrl}
                                    key={term.fullMatchTerm}
                                >
                                    {term.fullMatchTerm}
                                </a>
                                {index !== matchTermsLength - 1 && (
                                    <span className={dt(['font-bold'])}>, </span>
                                )}
                            </div>
                        );
                    })}
                </div>
                <h2 className={subTitleClasses} data-automation-id="subheaderTag">
                    {`We found 0 results for ${pageTitle}`}
                </h2>
            </>
        );
    };

    const renderFullMatchResult = (pageTitle, subTitleClasses, titleClasses) => (
        <>
            <h1
                className={`${titleClasses} ${dt(['sm:text-xlarge', 'lg:text-2xlarge'])}`}
                data-automation-id="headerTag"
            >
                {`\x22${autoCorrectedMessage || pageTitle}\x22`}
            </h1>
            {autoCorrectedMessage && (
                <h2 className={subTitleClasses} data-automation-id="subheaderTag">
                    {`You searched for \x22${pageTitle}\x22`}
                </h2>
            )}
            {showSearchResultsCountSubtitle && (
                <h2 className={subTitleClasses} data-automation-id="subheaderTag">
                    {`${countOfSearchResults} Results`}
                </h2>
            )}
        </>
    );

    const renderSearchPageTitle = (pageTitle, titleClasses) => {
        const isPartialMatch = !isEmpty(partialMatchInfo);
        const subTitleClasses = dt(['font-open-sans', 'text-center', 'text-base', 'w-full']);
        return isPartialMatch
            ? renderPartialMatchResult(pageTitle, subTitleClasses, titleClasses)
            : renderFullMatchResult(pageTitle, subTitleClasses, titleClasses);
    };

    const renderGalleryPageTitle = (pageTitle, titleClasses) => (
        <h1
            className={`${titleClasses} ${dt(['sm:text-xlarge', 'lg:text-2xlarge'])}`}
            data-automation-id="headerTag"
        >
            {pageTitle}
        </h1>
    );

    const getPageTitle = () => {
        const { h1Data = '' } = seoTags || {};
        const renderTitleSkeleton = enableBrPreviewGallerySkeleton && bloomReachPreviewShowMockData;
        const isSearchPage = pageType === 'Search';
        let headerText = h1Tag || h1Data;
        headerText = renderTitleSkeleton ? H1TagBrPreview : headerText;
        headerText = enableTextTransform ? headerText.toLowerCase() : headerText;
        headerText = isSearchPage ? '' : headerText;

        const pageTitle =
            isSearchPage && !renderTitleSkeleton
                ? getSearchTermFromUrl().toLowerCase()
                : headerText;
        const textTransform =
            isSearchPage || (!isSearchPage && enableTextTransform)
                ? dt(['capitalize'])
                : dt(['normal-case']);
        const titleClasses = `${dt(['font-open-sans-bold', 'text-center', 'text-black', 'w-full'])}
            ${cx('gallery-title', textTransform)}`;

        if (!pageTitle && renderTitleSkeleton) {
            return (
                <div
                    className={dt([
                        'sm:min-h-[36px]',
                        'bg-light-white',
                        'max-w-[500px]',
                        'm-auto',
                        'w-full',
                        'border',
                        'border-solid',
                        'border-gray-15',
                    ])}
                    data-automation-id="plp-skeleton-title"
                    id="gallery-title"
                />
            );
        }

        if (!pageTitle) {
            return null;
        }

        return (
            <div className={dt(['text-black', 'sm:min-h-[36px]'])}>
                {enableKeywordRedirectMessage && keywordRedirectMessage.length > 0
                    ? renderKeywordRedirectMessage(keywordRedirectMessage)
                    : null}
                {!keywordRedirectMessage || !enableKeywordRedirectMessage ? (
                    <>
                        <div className={dt(['flex', 'flex-row', 'flex-wrap'])} id="gallery-title">
                            {(isSearchPage && renderSearchPageTitle(pageTitle, titleClasses)) ||
                                renderGalleryPageTitle(pageTitle, titleClasses)}
                        </div>
                    </>
                ) : null}
            </div>
        );
    };

    return getPageTitle();
};

GalleryTitle.defaultProps = defaultProps;

GalleryTitle.propTypes = propTypes;

export default GalleryTitle;
