import { createSelector } from 'reselect';
import get from 'lodash/get';
import { transformVisNavDataFromIris } from '../utils/irisDataProcesserFunction';
import { selectdisableProductCountGallery, selectdisableProductCountSearch } from './FeatureFlag';

/*
this selector is to select value of visNav and transform it
*/

export const getVisNavData = (state, props) => get(props, 'items', {});

const getVisNav = createSelector(
    [getVisNavData, selectdisableProductCountGallery, selectdisableProductCountSearch],
    (irisVisNavInfo, disableProductCountGallery, disableProductCountSearch) =>
        irisVisNavInfo
            ? transformVisNavDataFromIris(
                  irisVisNavInfo,
                  disableProductCountGallery,
                  disableProductCountSearch,
                  irisVisNavInfo.showProductCount
              )
            : { datasource: [] }
);

export default getVisNav;
