import React, { useEffect, useState, memo } from 'react';
import { PropTypes, bool } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ErrorPage from 'yoda-site-components/lib/components/ErrorPage/ErrorPage';
import { CanonincalFallback } from 'yoda-site-components/lib/components/SeoMetaTags/CanonicalFallback';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { constructSEODefaultCanonicalUrl } from 'yoda-site-components/lib/helpers/Utils/seoHelper';
import { setFailoverCanonicalInfo } from '../../actions/GalleryAction';
import selectSolarCallStatus from '../../selectors/SolarCallStatus';
import BreadCrumbs from '../GalleryBreadCrumbs/IrisBreadCrumbsLazy';
import GalleryTitleComponent from '../GalleryTitle/GalleryTitle';
import { selectGallery } from '../../selectors/fromState';
import GalleryGridComponent from '../GalleryGrid/GalleryGrid';
import NoBrandPageResults from '../NoBrandResults/NoBrandResults';
import { isBrandPage } from '../../utils';

const defaultProps = {
    enableCanonicalFallback: true,
    location: {},
    pageType: '',
    hostname: '',
    seoConfig: {},
    isFailOverLayout: true,
};

const propTypes = {
    enableCanonicalFallback: bool,
    location: PropTypes.oneOfType([PropTypes.object]),
    pageType: PropTypes.string,
    seoConfig: PropTypes.oneOfType([PropTypes.object]),
    hostname: PropTypes.string,
    isFailOverLayout: bool,
};

const FailOverLayout = ({
    enableCanonicalFallback,
    location,
    pageType,
    seoConfig,
    hostname,
    isFailOverLayout,
}) => {
    const dispatch = useDispatch();
    const solarCallStatusSuccess = useSelector((state) => selectSolarCallStatus(state));
    const isGalleryCallCompleted = useSelector((state) => selectGallery(state));
    const [updatedParams, setUpdatedParams] = useState('');

    useEffect(() => {
        if (!solarCallStatusSuccess && isGalleryCallCompleted) {
            if (isBrandPage()) {
                return;
            }
            return;
        }

        if (enableCanonicalFallback) {
            dispatch(
                setFailoverCanonicalInfo({
                    isUpdateCanonicalUrl: false,
                })
            );
            const { excludeQueryParams = [], includeQueryParams = [] } = seoConfig;
            const params = constructSEODefaultCanonicalUrl(
                location?.search,
                excludeQueryParams,
                includeQueryParams,
                false
            );
            setUpdatedParams(params);
        }
    }, [enableCanonicalFallback, location, pageType, seoConfig, hostname, isFailOverLayout]);

    if (!solarCallStatusSuccess && isGalleryCallCompleted) {
        if (isBrandPage()) {
            return <NoBrandPageResults />;
        }
        return <ErrorPage />;
    }

    return (
        <div
            className={dt([
                'bg-white',
                'font-open-sans',
                'mx-auto',
                'my-0',
                'w-full',
                'overflow-hidden',
                'pt-6',
                'px-6',
            ])}
        >
            {enableCanonicalFallback && (
                <CanonincalFallback
                    urlLocation={location?.pathname}
                    urlParams={updatedParams}
                    hostname={hostname}
                />
            )}
            <BreadCrumbs className={dt(['pb-6'])} />
            <div className={dt(['pb-6'])}>
                <GalleryTitleComponent pageType={pageType} />
            </div>
            <GalleryGridComponent isFromFailOverLayOut={isFailOverLayout} />
        </div>
    );
};

FailOverLayout.defaultProps = defaultProps;

FailOverLayout.propTypes = propTypes;

export default memo(FailOverLayout);
