import Icon from 'yoda-core-components/lib/components/Icon';
import Cross from 'yoda-core-components/lib/assets/svg/Cross.svg';
import YdtSearch from 'yoda-core-components/lib/assets/svg/YdtSearch.svg';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import Input from 'yoda-core-components/lib/components/Input/Input';
import classNames from 'classnames/bind';
import React from 'react';
import { func, string } from 'prop-types';
import * as styles from './StaticSearch.css';

const cx = classNames.bind(styles);

export default class StaticSearch extends React.Component {
    static propTypes = {
        id: string.isRequired,
        onSearchChange: func.isRequired,
        placeHolder: string.isRequired,
        theme: string,
    };

    static defaultProps = {
        theme: '',
    };

    constructor() {
        super();
        this.state = {
            searchKey: '',
        };
    }

    clearSearchText = () => {
        this.searchBox.focus();
        this.clearSearchField();
    };

    clearSearchField = () => {
        const { onSearchChange } = this.props;

        this.setState({ searchKey: '' });
        onSearchChange('');
    };

    changeSearch = (val) => {
        const { onSearchChange } = this.props;

        this.setState({ searchKey: val });
        onSearchChange(val);
    };

    render() {
        const { id, placeHolder, theme } = this.props;
        const { searchKey } = this.state;
        const textBoxThemeClasses = `${dt([
            'font-sans',
            'h-5',
            'text-sm',
            'sm:w-[90%]',
            !searchKey.length && 'w-full',
        ])} ${cx({ crossIconDisplayed: searchKey.length })}`;

        const wrapperClasses = `${dt([
            'border',
            'border-gray-15',
            'border-solid',
            'flex',
            'mx-0',
            'mb-4',
            'py-0',
            'px-4',
            'relative',
            'rounded-full',
        ])} ${theme}`;

        return (
            <div
                className={wrapperClasses}
                ref={(node) => {
                    this.rootNode = node;
                }}
            >
                <Input
                    automationId="static-search"
                    id={id}
                    onChange={this.changeSearch}
                    placeholder={placeHolder}
                    refCallBack={(input) => {
                        this.searchBox = input;
                    }}
                    theme={textBoxThemeClasses}
                    type="text"
                    value={searchKey}
                />
                {searchKey.length > 0 ? (
                    <button type="button" className="inline-block" onClick={this.clearSearchText}>
                        <Icon>
                            <Cross />
                        </Icon>
                    </button>
                ) : (
                    <span className={dt(['inline-block'])}>
                        <Icon height="40" width="25">
                            <YdtSearch />
                        </Icon>
                    </span>
                )}
            </div>
        );
    }
}
